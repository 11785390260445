import {processLoadingEnd, processLoadingStart, processModalShow} from "./processActions";

import urls from "../connect/urls";
import {signOutAction} from "./authActions";
import {
  USERS_CLOSE_EDIT_USER_MODAL, USERS_CLOSE_INFO_USER_MODAL,
  USERS_CLOSE_NEW_USER_MODAL, USERS_GET_ALL_12_WEEK_USERS,
  USERS_GET_USER_INFO_DATA, USERS_GIVEN_PAGE,
  USERS_NEXT_PAGE, USERS_OPEN_EDIT_USER_MODAL,
  USERS_OPEN_NEW_USER_MODAL,
  USERS_PREVIOUS_PAGE
} from "./types";


// Get all users data
export const getUsersData = () => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    const response = await ibospirit.post(`${urls.get12WUsers}?page=${getState().twelveWeekUsers.current_page}`, {},
      {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch({type: USERS_GET_ALL_12_WEEK_USERS, payload: response.data});
  }catch (e) {

    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}


// Get real data by user id
export const getUserRealInfo = (user_id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    const response = await ibospirit.post(urls.getUserRealInfo, {user_id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })
    console.log(response.data);
    dispatch({type: USERS_GET_USER_INFO_DATA, payload: response.data});
  }catch (e) {

    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}

// Close info modal
export const closeUserInfoUserModal = () => ({ type: USERS_CLOSE_INFO_USER_MODAL });



// Generate new membership for a user
export const toggleUserActiveness = (user_id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deActivateUser, {user_id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getUsersData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}

// Generate new membership for a user
export const deleteUser = (user_id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deleteUser, {user_id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getUsersData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}


// Go to next page
export const getUsersNextPage = () => ({type: USERS_NEXT_PAGE});
// Go to prev page
export const getUsersPreviousPage = () => ({type: USERS_PREVIOUS_PAGE});
// Go to given page
export const getUsersWithGivenPage = (page) => ({type: USERS_GIVEN_PAGE, payload: page});



// New user modal manipulation
export const openNewUserModal = () => ({type: USERS_OPEN_NEW_USER_MODAL});
export const closeNewUserModal = () => ({type: USERS_CLOSE_NEW_USER_MODAL });

// Create new user
export const createUser = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.createUser, data, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(closeNewUserModal());
    dispatch(getUsersData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}


// New user modal manipulation
export const openEditUserModal = (user_id) => ({ type: USERS_OPEN_EDIT_USER_MODAL, payload: user_id });
export const closeEditUserModal = () => ({ type: USERS_CLOSE_EDIT_USER_MODAL });

// Create new user
export const editUser = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.editUser, data, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(closeEditUserModal());
    dispatch(getUsersData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}

// Generate new membership for a user
export const generateMembership = (user_id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.generateMembership, {user_id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getUsersData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}

// Generate new membership for a user
export const toggleMembership = (subscription_id, user_id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.toggleMembership, {subscription_id, user_id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getUsersData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}
