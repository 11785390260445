import React from "react";
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCheckDouble, faCircle} from "@fortawesome/free-solid-svg-icons";
import history from "../../history/history";

const QuestionContainer = ({data}) => {
  const is_workout = data.w_exercise_id != null;
  return (
    <div className="col-12 col-md-4">
      <StyledQuestion onClick={() => history.push(`/admin/questions/${is_workout ? 1 : 0}/${data.user_id}/${is_workout ? data.w_exercise_id : data.p_exercise_id}`)}>
        <div className="q-title">{
          is_workout ? data.workout_exercise.title : data.program_exercise.title
        }</div>
        <div className="q-body">
          <span>{data.is_msg_from_admin ? "You" : data.user_data.user_info_data.name} : </span>
          {data.question}
        </div>
        <div className="q-info">
          <FontAwesomeIcon icon={data.status ? faCheckDouble : ( data.is_msg_from_admin ? faCheck : faCircle )} />
          <span className="time">{data.created_at}</span>
        </div>
      </StyledQuestion>
    </div>
  );
}

const StyledQuestion = styled.div`
    border-radius: 5px;
    border: 1px solid #777;
    padding: 10px;
    margin-bottom: 30px;
    background: #404040;
    box-shadow: 0 0 10px #444;
    cursor: pointer;
    
    .q-title{
      font-family: MontserratBold, sans-serif;
    }
    .q-body{
      font-family: MontserratItalic, sans-serif;
      font-size: 15px;
      margin: 10px;
      
      span{
        color: #EC243C;
      }
    }
    .q-info{
      text-align: right;
      font-size: 12px;
      
      .time{
        padding-left: 10px;
      }
    }
`;

export default QuestionContainer;
