import React from "react";

const TwelveWeekUserTableItem = (props) => {
  const {ind, data}
    = props;
  return (
    <tr>
      <td>{ind}</td>
      <td>{data.name}</td>
      <td>{data.age}</td>
      <td>{data.gender == 1 ? 'Male' : "Female"}</td>
      <td>{data.country}</td>
      <td>{data.email}</td>
      <td>{data.whatsapp}</td>
      <td>{data.note}</td>
      <td>{new Date(data.created_at).toLocaleDateString()}</td>
    </tr>
  );
}

export default TwelveWeekUserTableItem;
