import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {API_URL} from "../constants";
import urls from "../connect/urls";

const pcWorkoutApi = createApi({
  reducerPath: 'pcWorkoutApi',
  baseQuery: fetchBaseQuery({
    baseUrl: API_URL,
    prepareHeaders: (headers, {getState}) => {
      const token = getState().auth.token;

      headers.set('Accept', 'application/json');

      if (token)
        headers.set('Authorization', `Bearer ${token}`);
    }
  }),

  endpoints: (builder) => ({

    getWorkoutPrograms: builder.mutation({
      query: (client_id) => ({
        url: urls.pcWorkoutProgram.getAll,
        method: 'POST',
        body: {client_id},
      })
    }),

    getWorkoutProgram: builder.mutation({
      query: (id) => ({
        url: urls.pcWorkoutProgram.get,
        method: 'POST',
        body: {id: id},
      }),
    }),

    deleteWorkoutProgram: builder.mutation({
      query: (id) => ({
        url: urls.pcWorkoutProgram.delete,
        method: 'POST',
        body: {program_id: id},
      })
    }),

    deActivateWorkoutProgram: builder.mutation({
      query: (id) => ({
        url: urls.pcWorkoutProgram.deActivate,
        method: 'POST',
        body: {program_id: id},
      })
    }),

    isNutritionalWorkoutProgram: builder.mutation({
      query: (id) => ({
        url: urls.pcWorkoutProgram.isNutritional,
        method: 'POST',
        body: {program_id: id},
      })
    }),

    createWorkoutProgram: builder.mutation({
      query: (data) => ({
        url: urls.pcWorkoutProgram.create,
        method: 'POST',
        body: data,
      })
    }),

    editWorkoutProgram: builder.mutation({
      query: (data) => ({
        url: urls.pcWorkoutProgram.edit,
        method: 'POST',
        body: data,
      })
    }),


    // program weeks
    createWorkoutProgramWeek: builder.mutation({
      query: (data) => ({
        url: urls.pcWorkoutProgram.week.create,
        method: 'POST',
        body: data,
      })
    }),

    editWorkoutProgramWeek: builder.mutation({
      query: (data) => ({
        url: urls.pcWorkoutProgram.week.edit,
        method: 'POST',
        body: data,
      })
    }),

    deleteWorkoutProgramWeek: builder.mutation({
      query: (id) => ({
        url: urls.pcWorkoutProgram.week.delete,
        method: 'POST',
        body: {week_id: id},
      })
    }),

    deActivateWorkoutProgramWeek: builder.mutation({
      query: (id) => ({
        url: urls.pcWorkoutProgram.week.deActivate,
        method: 'POST',
        body: {week_id: id},
      })
    }),

    // program days
    createWorkoutProgramDay: builder.mutation({
      query: (data) => ({
        url: urls.pcWorkoutProgram.day.create,
        method: 'POST',
        body: data
      })
    }),

    editWorkoutProgramDay: builder.mutation({
      query: (data) => ({
        url: urls.pcWorkoutProgram.day.edit,
        method: 'POST',
        body: data
      })
    }),

    deleteWorkoutProgramDay: builder.mutation({
      query: (id) => ({
        url: urls.pcWorkoutProgram.day.delete,
        method: 'POST',
        body: {day_id: id},
      })
    }),

    deActivateWorkoutProgramDay: builder.mutation({
      query: (id) => ({
        url: urls.pcWorkoutProgram.day.deActivate,
        method: 'POST',
        body: {day_id: id},
      })
    }),

    createWorkoutProgramExercise: builder.mutation({
      query: (data) => ({
        url: urls.pcWorkoutProgram.exercise.create,
        method: 'POST',
        body: data
      })
    }),

    editWorkoutProgramExercise: builder.mutation({
      query: (data) => ({
        url: urls.pcWorkoutProgram.exercise.edit,
        method: 'POST',
        body: data
      })
    }),

    deleteWorkoutProgramExercise: builder.mutation({
      query: (id) => ({
        url: urls.pcWorkoutProgram.exercise.delete,
        method: 'POST',
        body: {exercise_id: id},
      })
    }),

    searchVideo: builder.mutation({
      query: (keyword) => ({
        url: urls.pcWorkoutProgram.exercise.search,
        method: 'POST',
        body: {keyword},
      })
    }),

    copyTo: builder.mutation({
      query: (data) => ({
        url: urls.pcWorkoutProgram.copyTo,
        method: 'POST',
        body: data,
      })
    }),
  }),
});

export const {
  useGetWorkoutProgramsMutation,
  useGetWorkoutProgramMutation,
  useDeleteWorkoutProgramMutation,
  useDeActivateWorkoutProgramMutation,
  useCreateWorkoutProgramMutation,
  useEditWorkoutProgramMutation,
  useCreateWorkoutProgramWeekMutation,
  useEditWorkoutProgramWeekMutation,
  useDeleteWorkoutProgramWeekMutation,
  useCreateWorkoutProgramDayMutation,
  useEditWorkoutProgramDayMutation,
  useDeleteWorkoutProgramDayMutation,
  useCreateWorkoutProgramExerciseMutation,
  useEditWorkoutProgramExerciseMutation,
  useDeleteWorkoutProgramExerciseMutation,
  useSearchVideoMutation,
  useCopyToMutation,
  useIsNutritionalWorkoutProgramMutation,
  useDeActivateWorkoutProgramWeekMutation,
  useDeActivateWorkoutProgramDayMutation,
} = pcWorkoutApi;

export default pcWorkoutApi;