import React from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {websiteName} from "../constants";
import {
  deleteNutritionCategoryAction,
  deleteNutritionUnitTypeAction, getNutritionCategoriesAction,
  getNutritionUnitTypesAction,
  openEditNutritionCategoryAction,
  openEditNutritionUnitTypeAction,
  openNewNutritionCategoryAction,
  openNewNutritionUnitTypeAction
} from "../actions/nutritionActions";
import NewNutritionUnitTypeModal from "../components/Nutrition/NewNutritionUnitTypeModal";
import EditNutritionUnitTypeModal from "../components/Nutrition/EditNutritionUnitTypeModal";
import ConfirmModal from "../components/ConfirmModal";
import NewNutritionCategoryModal from "../components/Nutrition/NewNutritionCategoryModal";
import EditNutritionCategoryModal from "../components/Nutrition/EditNutritionCategoryModal";
import history from "../history/history";


const Nutrition = () => {
  const nutrition = useSelector(state => state.nutrition);
  const dispatch = useDispatch();

  const [isDeleteModalUnitType, setIsDeleteModalUnitType] = React.useState({state: false, id: null});
  const [isDeleteModalCategory, setIsDeleteModalCategory] = React.useState({state: false, id: null});

  React.useEffect(() => {
    document.title = 'Nutrition' + websiteName;
    dispatch(getNutritionUnitTypesAction());
    dispatch(getNutritionCategoriesAction());
  }, [])

  return <StyledNutrition>
    {/* New unit type modal start */}
    {(nutrition.is_new_unit_type_modal_open) ? <NewNutritionUnitTypeModal /> : null}
    {/* New unit type modal end   */}

    {/* Edit unit type modal start */}
    {(nutrition.is_edit_unit_type_modal_open.state) ? <EditNutritionUnitTypeModal /> : null}
    {/* Edit unit type modal end   */}

    {/* Unit type delete modal start */}
    {
      (isDeleteModalUnitType.state)
        ? <ConfirmModal
          body={'Are you really sure you want to delete this unit type?'}
          onCancel={ () => { setIsDeleteModalUnitType({state: false, id: null}) } }
          onSuccess={ () => { dispatch(deleteNutritionUnitTypeAction(isDeleteModalUnitType.id)); setIsDeleteModalUnitType({state: false, id: null}) } }
        /> : null
    }
    {/* Unit type delete modal end */}


    {/* New category modal start */}
    {(nutrition.is_new_category_modal_open) ? <NewNutritionCategoryModal /> : null}
    {/* New category modal end   */}

    {/* Edit category modal start */}
    {(nutrition.is_edit_category_modal_open.state) ? <EditNutritionCategoryModal /> : null}
    {/* Edit category modal end   */}

    {/* Category delete modal start */}
    {
      (isDeleteModalCategory.state)
        ? <ConfirmModal
          body={'Are you really sure you want to delete this category?'}
          onCancel={ () => { setIsDeleteModalCategory({state: false, id: null}) } }
          onSuccess={ () => { dispatch(deleteNutritionCategoryAction(isDeleteModalCategory.id)); setIsDeleteModalCategory({state: false, id: null}) } }
        /> : null
    }
    {/* Category delete modal end */}

    <h4>Nutrition</h4>
    <div className="container-fluid p-0">
      <div className="row">
        <div className="col-12 col-md-6">
          <h5>
            Unit types {''}
            <button className="btn btn-primary btn-sm px-1 py-0"
                    onClick={() => dispatch(openNewNutritionUnitTypeAction())}>
              <FontAwesomeIcon icon={faPlus}/>
            </button>
          </h5>
          <div className="table-responsive">
            <table className="table table-sm table-dark">
              <thead>
              <tr className="text-center small">
                <th>#</th>
                <th>Unit name</th>
                <th>{' '}</th>
              </tr>
              </thead>
              <tbody>
              {
                nutrition.unit_types.length
                ? nutrition.unit_types.map((eut, i) => {
                  return <tr className="text-center" key={"ddd"+eut.id}>
                    <td>{i+1}</td>
                    <td>{eut.title}</td>
                    <td>
                      <button className="btn btn-outline-primary btn-sm px-1 py-0 ml-1"
                              onClick={() => dispatch(openEditNutritionUnitTypeAction(eut.id))}
                      ><FontAwesomeIcon fixedWidth icon={faEdit} />
                      </button>
                      <button className="btn btn-outline-danger btn-sm px-1 py-0 ml-1"
                              onClick={() => setIsDeleteModalUnitType({id: eut.id, state: true})}
                      ><FontAwesomeIcon fixedWidth icon={faTimes} />
                      </button>
                    </td>
                  </tr>
                  })
                  : <tr className="text-center"><td colSpan={3}>Not found</td></tr>
              }
              </tbody>
            </table>
          </div>
        </div>

        <div className="col-12 col-md-6">
          <h5>
            Categories {''}
            <button className="btn btn-primary btn-sm px-1 py-0"
                    onClick={() => dispatch(openNewNutritionCategoryAction())}>
              <FontAwesomeIcon icon={faPlus}/>
            </button>
          </h5>
          <div className="table-responsive">
            <table className="table table-sm table-dark">
              <thead>
              <tr className="text-center small">
                <th>#</th>
                <th>Category name</th>
                <th>Products</th>
                <th>{' '}</th>
              </tr>
              </thead>
              <tbody>
              {
                nutrition.categories.length
                  ? nutrition.categories.map((ec, i) => {
                    return <tr className="text-center" key={"ccc"+ec.id}>
                      <td>{i+1}</td>
                      <td><a href="#" onClick={e => {e.preventDefault(); history.push(`/admin/nutrition/category/${ec.id}`) } }>{ec.title}</a></td>
                      <td>{ec.items_data_count}</td>
                      <td>
                        <button className="btn btn-outline-primary btn-sm px-1 py-0 ml-1"
                                onClick={() => dispatch(openEditNutritionCategoryAction(ec.id))}
                        ><FontAwesomeIcon fixedWidth icon={faEdit} />
                        </button>
                        <button className="btn btn-outline-danger btn-sm px-1 py-0 ml-1"
                                onClick={() => setIsDeleteModalCategory({id: ec.id, state: true})}
                        ><FontAwesomeIcon fixedWidth icon={faTimes} />
                        </button>
                      </td>
                    </tr>
                  })
                  : <tr className="text-center"><td colSpan={4}>Not found</td></tr>
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </StyledNutrition>;
};


const StyledNutrition = styled.div`
  min-height: ${window.innerHeight - 155}px;
  
  .unit-types-container{
    border-radius: 5px;
    border: 1px solid #777;
    padding: 10px;
    margin-bottom: 30px;
    background: #404040;
    box-shadow: 0 0 10px #444;
  }  
`;

export default Nutrition;
