import React from "react";

const WebHookTableItem = ({ind, data}) => {
  return (
    <tr>
      <td>{ind}</td>
      <td>{data.webhook_id}</td>
      <td>{data.event_type}</td>
      <td>{data.subscription_id}</td>
      <td>{data.plan_id}</td>
      <td>{data.status}</td>
      <td>{data.status_update_time}</td>
      <td>{data.created_at}</td>
    </tr>
  );
}

export default WebHookTableItem;
