import React, {useState} from "react";
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from "react-redux";
import {closeEditProgramModal, editProgram} from "../../actions/programsActions";
import MediumModal from "../MediumModal";
import ImageUploader from "react-images-upload";

const EditProgramModal = () => {
  const dispatch = useDispatch();
  const program_data = useSelector(state => state.programs.is_edit_program_modal_open.data)

  const [data, setData] = useState({
    id: null,
    title: '',
    description: '',
    is_repeatable: 0,
    icon: null
  });

  React.useEffect(() => {
    setData({
      ...data,
      id: program_data.id,
      title: program_data.title,
      description: program_data.description,
      is_repeatable: program_data.is_repeatable
    });
  }, []);

  // Set image on drop or change
  const onDrop = (icon) => setData({...data, icon: icon[0]});

  return (
    ReactDOM.createPortal(
      <MediumModal onSuccess={() => dispatch(editProgram(data))}
                   onClose={() => dispatch(closeEditProgramModal())}
                   title={'Edit program'} onSuccessText={'Edit'}>

        {/* Title */}
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="new_program_title">Title <span className="text-danger">*</span> :</label>
            <input type="text" className="form-control" placeholder={'Program title'} id="new_program_title"
                   value={data.title} onChange={e => setData({...data, title: e.target.value})}/>
          </div>
        </div>

        {/* Description */}
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="new_program_description">Description <span className="text-danger">*</span> :</label>
            <textarea id="new_program_description" rows="5" className="form-control"
                      onChange={e => setData({...data, description: e.target.value})} value={data.description}/>
          </div>
        </div>

        {/* Is repeatable */}
        <div className="col-12">
          <div className="form-group">
            <div className="form-check">
              <input className="form-check-input"
                     onChange={e => setData({...data, is_repeatable: e.target.checked})}
                     type="checkbox" checked={data.is_repeatable} id="edit_program_is_repeatable" />
              <label className="form-check-label" htmlFor="edit_program_is_repeatable">Is Repeatable?</label>
            </div>
          </div>
        </div>

        {/* Image */}
        <div className="col-12">
          <ImageUploader
            singleImage={true}
            withPreview={true}
            onChange={onDrop}
            imgExtension={[".jpg", ".gif", ".png", ".gif"]}
            buttonText={"Choose icon"}
          />
        </div>

      </MediumModal>,
      document.getElementById('modal')
    )
  );
}

export default EditProgramModal;
