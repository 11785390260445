import {
  PROCESS_LOADING_END,
  PROCESS_LOADING_START,
  PROCESS_MODAL_CLOSE,
  PROCESS_MODAL_SHOW,
  PROCESS_MODAL_SHOW_WITH_TITLE
} from "./types";


// Global process loading start trigger
export const processLoadingStart = () => ({type: PROCESS_LOADING_START});


// Global process loading end trigger
export const processLoadingEnd = () => ({type: PROCESS_LOADING_END});


// Global modal set error message and show modal
export const processModalShow = (errorMessage) => ({type: PROCESS_MODAL_SHOW, payload: errorMessage});


// Global modal set error message, title and show modal
export const processModalShowWithTitle = (body, title) => ({ type: PROCESS_MODAL_SHOW_WITH_TITLE, payload: {body, title} });


// Global modal clear error and close modal
export const processModalClose = () => ({type: PROCESS_MODAL_CLOSE});
