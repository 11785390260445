import {useSelector} from "react-redux";
import React from "react";
import ClientImageContainer from "./ClientImageContainer";
import ClientImageDropbox from "./ClientImageDropbox";

const ClientImage = () => {
  const images = useSelector(s => s.clients.current_client.personal_client_images);

  return <>
    <h5>Images</h5>
    <div className="d-flex" style={{overflowX: 'scroll', overflowY: 'none'}}>
      {
        (!images.length)
          ? null
          : images.map(each => <ClientImageContainer key={each.id} data={each} />)
      }
    </div>
    <hr/>
    <ClientImageDropbox />
  </>;
}

export default ClientImage;
