import React, {useState} from "react";
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from "react-redux";
import {closeEditNutritionUnitTypeAction, editNutritionUnitTypeAction} from "../../actions/nutritionActions";
import MediumModal from "../MediumModal";

const EditNutritionUnitTypeModal = () => {
  const dispatch = useDispatch();
  const edit_unit_type = useSelector(state => state.nutrition.is_edit_unit_type_modal_open);
  const [title, setTitle] = useState('');

  React.useEffect(() => {
    if (edit_unit_type.data == null)
      dispatch(closeEditNutritionUnitTypeAction());

    setTitle(edit_unit_type.data.title);
  }, []);

  return (
    ReactDOM.createPortal(
      <MediumModal onSuccess={() => dispatch(editNutritionUnitTypeAction({id: edit_unit_type.data.id, title}))}
                   onClose={() => dispatch(closeEditNutritionUnitTypeAction())}
                   title={'Edit unit type'} onSuccessText={'Edit'}>

        {/* Title */}
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="edit_unit_title">Title <span className="text-danger">*</span> :</label>
            <input type="text" className="form-control" placeholder={'Unit title'} id="edit_unit_title"
                   value={title} onChange={e => setTitle(e.target.value)}/>
          </div>
        </div>
      </MediumModal>,
      document.getElementById('modal')
    )
  );
}

export default EditNutritionUnitTypeModal;
