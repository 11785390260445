import React from "react";
import {ListGroup} from "react-bootstrap";
import {getClient} from "../../actions/clientsActions";
import {useDispatch} from "react-redux";
import {faAngleUp, faEye, faEyeSlash, faSave} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleDown} from "@fortawesome/free-solid-svg-icons/faAngleDown";

const ClientList = ({clients, is_current=false, is_personal=false, showInactive=false, title}) => {
  const dispatch = useDispatch();
  const [isShown, setIsShown] = React.useState(true);
  return <>
    <hr/>
    <h6 onClick={() => setIsShown(v => !v)}>{title + ' '} <FontAwesomeIcon icon={ (!isShown) ? faAngleDown : faAngleUp } /></h6>
    <ListGroup className={ !isShown ? 'd-none' : null} variant={"flush"}>

      {
        (!showInactive)
          ? (
            (is_current)
              ? clients.map(each => (each.is_current && each.is_active) ? <ListGroup.Item key={each.id} action onClick={() => dispatch(getClient(each.id))} variant={"dark"}>{each.name}</ListGroup.Item> : null)
              : (
                (is_personal)
                  ? (
                    clients.length
                      ? clients.map(each => (each.is_personal && each.is_active) ? <ListGroup.Item key={each.id} action onClick={() => dispatch(getClient(each.id))} variant={"dark"}>{each.name}</ListGroup.Item> : null )
                      : <ListGroup.Item action onClick={() => alert('Not found')} variant={"dark"}>Not found</ListGroup.Item>
                  ) : (
                    clients.length
                      ? clients.map(each => (!each.is_personal && each.is_active) ? <ListGroup.Item key={each.id} action onClick={() => dispatch(getClient(each.id))} variant={"dark"}>{each.name}</ListGroup.Item> : null )
                      : <ListGroup.Item action onClick={() => alert('Not found')} variant={"dark"}>Not found</ListGroup.Item>
                  )
              )
          )
          : (
            clients.length
              ? clients.map(each => (!each.is_active) ? <ListGroup.Item key={each.id} action onClick={() => dispatch(getClient(each.id))} variant={"dark"}>{each.name}</ListGroup.Item> : null )
              : <ListGroup.Item action onClick={() => alert('Not found')} variant={"dark"}>Not found</ListGroup.Item>
          )
      }
    </ListGroup>
  </>;
}

export default ClientList;
