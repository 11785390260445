import React from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt, faPlus} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {websiteName} from "../constants";

import {Button, Container, Row, Col} from 'react-bootstrap';
import NewClientModal from "../components/NewClients/NewClientModal";
import {getClients, openNewClientModal, openTodayClientTimetableModal} from "../actions/clientsActions";
import ClientMainData from "../components/NewClients/ClientMainData";
import DeleteClientModal from "../components/NewClients/DeleteClientModal";
import ClientTimetable from "../components/NewClients/Timetable/ClientTimetable";
import DeleteTimetableModal from "../components/NewClients/Timetable/DeleteTimetableModal";
import ClientStat from "../components/NewClients/Stat/ClientStat";
import DeleteStatModal from "../components/NewClients/Stat/DeleteStatModal";
import ClientImage from "../components/NewClients/ClientImage/ClientImage";
import DeleteClientImageModal from "../components/NewClients/ClientImage/DeleteClientImageModal";
import CurrentTimetableModal from "../components/NewClients/Timetable/CurrentTimetableModal";
import ClientList from "../components/NewClients/ClientList";
import PCWorkoutProgram from "../components/PersonalClients/PCWorkoutProgram";


const PC = () => {
  const dispatch = useDispatch();
  const clients = useSelector(state => state.clients.clients);

  React.useEffect(() => {
    document.title = 'Personal clients' + websiteName;
    dispatch(getClients());
  }, []);


  return <StyledPrograms>

    <CurrentTimetableModal />

    <NewClientModal />

    <DeleteClientModal />

    <DeleteTimetableModal />
    <DeleteStatModal />

    <DeleteClientImageModal />

    <Container fluid className="p-0">
      <Row>
        <Col sm={3} className="border-right border-light">
          <h5>
            Clients {" "}
            <Button onClick={() => dispatch(openNewClientModal())} size={"sm"} className="py-0" variant={"primary"}><FontAwesomeIcon icon={faPlus}/></Button>
            {" "}
            <Button onClick={() => dispatch(openTodayClientTimetableModal())} size={"sm"} className="py-0" variant={"danger"}><FontAwesomeIcon icon={faCalendarAlt}/></Button>
          </h5>

          <ClientList title={'Current clients'} is_current clients={clients} />

          <ClientList title={'Personal clients'} is_personal clients={clients} />

          <ClientList title={'Online clients'} clients={clients} />

          <ClientList title={'Inactive clients'} clients={clients} showInactive={true} />

        </Col>
        <Col className="personal-datum">

          <ClientMainData />
          <hr/>
          <Container fluid className="px-0">
            <ClientTimetable />
          </Container>

          <hr/>

          <Container fluid className="px-0">
            <ClientStat />
          </Container>

          <hr/>

          <Container fluid className="px-0">
            <ClientImage />
          </Container>

          <hr/>

          <Container fluid className="px-0">
            <PCWorkoutProgram />
          </Container>

        </Col>
      </Row>
    </Container>

  </StyledPrograms>;
};


const StyledPrograms = styled.div`
  min-height: ${window.innerHeight - 155}px;
  
  .card-body .btn{
    padding: 0 3px;
    margin: 0 3px;
  }
  .border-right{
    max-height: ${window.innerHeight - 150}px;
    overflow-y: scroll;
  }  
  .personal-datum{
    max-height: ${window.innerHeight - 150}px;
    overflow-y: scroll;
  }  
  
  .timetable{
    max-height: 200px;
    overflow-y: scroll;
    position: relative;
    
    td{
      vertical-align: middle;
    }
  }
  
  .card{
    font-size: 14px
  }
`;





export default PC;
