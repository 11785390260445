import React from 'react';
import {faTimes} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const SideMenu = (props) => {
  return (
    <>
      <div className="Side-menu-background" onClick={ () => props.closeMenu() } />
      <div className="Side-menu" onClick={ () => props.closeMenu() }>
        <div className="Side-menu__times" onClick={ () => props.closeMenu() }>
          <FontAwesomeIcon icon={faTimes} />
        </div>

        <h3>Menu</h3>

        { props.children }

      </div>
    </>
  );
};

export default SideMenu;
