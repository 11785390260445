import React from 'react';
import styled from "styled-components";
import {websiteName} from "../constants";


const E404 = () => {

  // Setting up titles and checking if already signed in
  React.useEffect(() => {
    // Setting title
    window.document.title = "Page not found - 404 " + websiteName;

  }, []);

  return <StyledE404>
    <h1>404</h1>
    <h2>Page not found</h2>
  </StyledE404>;
};

const StyledE404 = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  flex-direction: column;
  height: ${window.innerHeight}px;

  h1{
    text-transform: uppercase;
    font: 50px MontserratBold, sans-serif;
    text-align: center;
    margin-bottom: 10px;
  }
  h2{
    text-transform: uppercase;
    font: 30px MontserratRegular, sans-serif;
    text-align: center;
  }
`;

export default E404;
