import React from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {websiteName} from "../constants";
import {getWebHooksData, getWebHooksNextPage, getWebHooksPreviousPage} from "../actions/webHooksActions";
import WebHookTableItem from "../components/WebHooks/WebHookTableItem";


const WebHooks = () => {
  const webHooks = useSelector(state => state.webHooks);
  const dispatch = useDispatch();

  React.useEffect(() => {
    document.title = 'Web hook events' + websiteName;
    dispatch(getWebHooksData());
  }, [webHooks.current_page])


  return <StyledWebHooks>

    <h4>Web hooks events</h4>

    <div className="container-fluid p-0">
      <div className="table-responsive">
        <table className="table table-bordered table-sm table-hover table-dark">
          <thead key={'thead'}>
          <tr className="text-center">
            <th>#</th>
            <td>Web hook ID</td>
            <td>Event</td>
            <td>Subscription ID</td>
            <td>Plan ID</td>
            <td>Status</td>
            <td>Status updated</td>
            <td>Triggered</td>
          </tr>
          </thead>

          <tbody key={'tbody'}>
          {
            (webHooks.data.length > 0)
              ? webHooks.data.map((each, index) => <WebHookTableItem key={each.id} ind={index + 1} data={each}/>)
              : <tr>
                <td colSpan={8}>No web hooks found</td>
              </tr>
          }
          </tbody>

          <tfoot key={'tfoot'}>
          <tr>
            <td colSpan="8">
              <div className="row">
                <div className="col d-flex align-items-center">
                  Overall web hook events: {webHooks.total}
                </div>
                <div className="col text-right">
                  <button className="btn btn-outline-light btn-sm py-0" onClick={() => dispatch(getWebHooksPreviousPage())}>
                    <FontAwesomeIcon icon={faAngleLeft}/>
                  </button>
                  <span className="d-inline-block px-3">{webHooks.current_page}</span>
                  <button className="btn btn-outline-light btn-sm py-0" onClick={() => dispatch(getWebHooksNextPage())}>
                    <FontAwesomeIcon icon={faAngleRight}/>
                  </button>
                </div>
              </div>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </StyledWebHooks>;
};


const StyledWebHooks = styled.div`
  min-height: ${window.innerHeight - 155}px;
  
  table{
    font-size: 14px;
    thead, tfoot{
      font-size: 11px;
      font-family: MontserratBold, sans-serif;
      td, th {
        padding: 10px;
      }
      
    }
    
    tbody{
      td{
        text-align: center;
      }
    }
  }  
`;

export default WebHooks;
