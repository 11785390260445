import React from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from "react-redux";
import {processModalClose} from "../actions/processActions";

const Modal = () => {
  const process = useSelector(state => state.process);
  const dispatch = useDispatch();

  return (
    (process.modalIsShown)
      ?
      ReactDOM.createPortal(
        <>
          <StyledModalBackground/>
          <StyledModal>
            <div className="m-container">
              <div className="m-title">{ process.modalTitle }</div>

              <div className="m-body">{
                process.modalError.split('\n').map((t, i) => <p key={i}>{ t }</p>)
              }</div>

              <button className={`btn ${process.modalTitle.toLowerCase() === 'success' ? 'btn-outline-success' : 'btn-outline-danger' } btn-block`}
                      onClick={e => {
                        e.stopPropagation();
                        dispatch(processModalClose())
                      }}>Ok
              </button>
            </div>
          </StyledModal>
        </>,
        document.getElementById('error')
      )
      : null
  );
}

const StyledModalBackground = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 1100; 
`;

const StyledModal = styled.div`
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  
  .m-container{
    width: 300px;
    background: #303030;
    padding: 20px 15px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px #444;
    
    .m-title{
      font: 20px MontserratBold, sans-serif;
      text-transform:uppercase;
      text-align: center;
      margin-bottom: 15px;
    }
    
    .m-body{
      font-size: 14px;
      margin-bottom: 20px;
      
      p {margin-bottom: 5px}
    }
    
    
    button{
    
    }
  }
`;

export default Modal;
