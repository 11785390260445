import {useDispatch, useSelector} from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import {Button, Modal} from "react-bootstrap";
import {closeDeleteTimetableModal, deleteTimetableClient} from "../../../actions/clientsActions";

const DeleteTimetableModal = () => {
  const dispatch = useDispatch();
  const delete_timetable = useSelector(state => state.clients.current_client.delete_timetable);

  return ReactDOM.createPortal(<React.Fragment>
    <Modal show={delete_timetable.is_open} size={"sm"} onHide={() => dispatch(closeDeleteTimetableModal())}>
      <Modal.Header closeButton className="bg-dark">
        <Modal.Title>Delete timetable</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark">
        <h5>Dou you really want to delete?</h5>
      </Modal.Body>
      <Modal.Footer className="bg-dark">
        <Button variant="danger" onClick={() => dispatch(deleteTimetableClient(delete_timetable.id))}>Delete</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>, document.getElementById('modal'));
}

export default DeleteTimetableModal;
