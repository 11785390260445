import React from 'react';
import {Link} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {signOutAction} from "../../actions/authActions";
import history from "../../history/history";


const MenuContent = () => {
  const authAdminToken = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const signOutHandler = (e) => {
    e.preventDefault();
    dispatch(signOutAction());
    history.push('/signIn');
  }

  return <>
    <div className="main-menu">
      <Link to={'/'}>Home</Link>
      {
        authAdminToken.token
          ? <>
            <Link to={'/admin/users'}>users</Link>
            <Link to={'/admin/workouts'}>workouts</Link>
            <Link to={'/admin/programs'}>programs</Link>
            <Link to={'/admin/questions'}>questions</Link>
            <Link to={'/admin/nutrition'}>Nutrition</Link>
            <Link to={'/admin/faqs'}>faqs</Link>
            <Link to={'/admin/webHooks'}>WHE</Link>
            <Link to={'/admin/PC'}>PC</Link>
            <Link to={'/admin/TwelveWeekProgram'}>12-W</Link>
            <Link to={'/admin/VimeoVideos'}>Vimeo videos</Link>
          </>
          : null
      }
    </div>

    <div className="register-menu">
      {
        authAdminToken.token
          ? (
            <a href={'#userSignout'} onClick={ signOutHandler }>Sign out</a>
          )
          : null
      }
    </div>
  </>
};

export default MenuContent;
