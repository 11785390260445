import {
  VIMEO_VIDEOS_FETCH_RESPONSE_VIDEO,
  VIMEO_VIDEOS_GET_VIDEOS,
  VIMEO_VIDEOS_DELETE_VIDEO,
  VIMEO_VIDEOS_EDIT_VIDEO,
} from "./types";
import {processLoadingEnd, processLoadingStart, processModalShow} from "./processActions";
import urls from "../connect/urls";
import {signOutAction} from "./authActions";



export const getVimeoVideos = (page=1) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    const response = await ibospirit.post(urls.getVimeoVideos+"?page="+page, {}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    if(response.data)
      dispatch({type: VIMEO_VIDEOS_GET_VIDEOS, payload: response.data});

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
            // alert(errorText);
          }
          break;

        // If token expired
        case 401:
          // alert(e.response.data);
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          // alert('Too many request try again later');
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
      // alert('Network error. Check internet connection');
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}


export const createVimeoVideo = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    let current_page = getState().vimeoVideos.current_page;
    const response = await ibospirit.post(urls.createVimeoVideo+"?page="+current_page, data,
      {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    dispatch({type: VIMEO_VIDEOS_GET_VIDEOS, payload: response.data});

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
            // alert(errorText);
          }
          break;

        // If token expired
        case 401:
          // alert(e.response.data);
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          // alert('Too many request try again later');
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
      // alert('Network error. Check internet connection');
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}


export const editVimeoVideo = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    let current_page = getState().vimeoVideos.current_page;
    const response = await ibospirit.post(urls.editVimeoVideo+"?page="+current_page, data,
      {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    dispatch({type: VIMEO_VIDEOS_GET_VIDEOS, payload: response.data});

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
            // alert(errorText);
          }
          break;

        // If token expired
        case 401:
          // alert(e.response.data);
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          // alert('Too many request try again later');
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
      // alert('Network error. Check internet connection');
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}


export const deleteVimeoVideo = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    let current_page = getState().vimeoVideos.current_page;
    const response = await ibospirit.post(urls.deleteVimeoVideo+"?page="+current_page, data,
      {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });


    dispatch({type: VIMEO_VIDEOS_GET_VIDEOS, payload: response.data});

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
            // alert(errorText);
          }
          break;

        // If token expired
        case 401:
          // alert(e.response.data);
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          // alert('Too many request try again later');
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
      // alert('Network error. Check internet connection');
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}
