import React from 'react';
import styled from "styled-components";
import {useDispatch, useSelector} from "react-redux";
import {websiteName} from "../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Container, Row, Col, FormControl, Table, Pagination} from 'react-bootstrap';
import {createVimeoVideo, deleteVimeoVideo, editVimeoVideo, getVimeoVideos} from "../actions/vimeoVideosActions";
import {faEdit, faPlus, faSave, faTimes, faTrash} from "@fortawesome/free-solid-svg-icons";


export default function VimeoVideos() {
  const dispatch = useDispatch();
  const vimeo = useSelector(state => state.vimeoVideos);


  const [data, setData] = React.useState({
    title: '',
    url: '',
    description: '',
  });

  const [editData, setEditData] = React.useState({
    id: null,
    title: '',
    url: '',
    description: '',
  });

  React.useEffect(() => {
    document.title = 'Vimeo videos' + websiteName;
    dispatch(getVimeoVideos());
  }, []);

  React.useEffect(() => {
    setData({title: '', url: '', description: ''});
    setEditData({id: '', title: '', url: '', description: ''});
  }, [vimeo]);


  return <StyledPrograms>
    <Container fluid className="p-0">
      <h5>Vimeo videos</h5>
      <hr/>
      <Row>
        <Col sm={3}>
          <FormControl placeholder="Video title"
                       type="text"
                       size="sm"
                       value={data.title}
                       onChange={(e) => {
                         setData({...data, title: e.target.value});
                       }}
          />
        </Col>

        <Col sm={4}>
          <FormControl placeholder="Video url"
                       type="text"
                       size="sm"
                       value={data.url}
                       onChange={(e) => {
                         setData({...data, url: e.target.value});
                       }}
          />
        </Col>

        <Col sm={4}>
          <FormControl placeholder="Video description"
                       type="text"
                       size="sm"
                       value={data.description}
                       onChange={(e) => {
                         setData({...data, description: e.target.value});
                       }}
          />
        </Col>
        <Col sm={1}>
          <Button size={'sm'} variant="primary" onClick={() => dispatch(createVimeoVideo(data))}>
            <FontAwesomeIcon icon={faPlus}/>
          </Button>
        </Col>
      </Row>
      <hr/>
      <h6>Video list</h6>

      <Table variant="dark" size={"sm"} bordered responsive className="small">
        <thead>
        <tr>
          <th>#</th>
          <th>Title</th>
          <th>Url</th>
          <th>Description</th>
          <th>{" "}</th>
        </tr>
        </thead>
        <tbody>
        {
          vimeo.videos.length === 0 && <tr>
            <td colSpan={5} style={{textAlign: 'center'}}>No videos</td>
          </tr>
        }
        {vimeo.videos.map((video, index) => {
          return (!editData.id || editData.id != video.id)
            ? <tr key={index}>
              <td>{index + 1}</td>
              <td>{video.title}</td>
              <td>{video.url}</td>
              <td>{video.description}</td>
              <td>
                <Button variant="danger" size="sm" style={{marginRight: '5px'}}
                        onClick={() => window.confirm('Are you sure to delete?') && dispatch(deleteVimeoVideo({id: video.id}))}
                        className="py-0">
                  <FontAwesomeIcon icon={faTrash}/>
                </Button>
                <Button variant="primary" size="sm" className="py-0"
                        onClick={() => setEditData({id: video.id, url: video.url, description: video.description, title: video.title})}
                >
                  <FontAwesomeIcon icon={faEdit}/>
                </Button>
              </td>
            </tr>
            : <tr key={index}>
              <td>{""}</td>
              <td>
                <FormControl placeholder="Video title"
                             type="text"
                             size="sm"
                             value={editData.title}
                             onChange={(e) => {
                               setEditData({...editData, title: e.target.value});
                             }}
                />
              </td>
              <td>
                <FormControl placeholder="Video url"
                             type="text"
                             size="sm"
                             value={editData.url}
                             onChange={(e) => {
                               setEditData({...editData, url: e.target.value});
                             }}
                />
              </td>
              <td>
                <FormControl placeholder="Video description"
                             type="text"
                             size="sm"
                             value={editData.description}
                             onChange={(e) => {
                               setEditData({...editData, description: e.target.value});
                             }}
                />
              </td>
              <td>
                <Button variant="danger" size="sm" style={{marginRight: '5px'}}
                        onClick={() => setEditData({id: null, title: '', url: '', description: ''})} className="py-0">
                  <FontAwesomeIcon icon={faTimes}/>
                </Button>
                <Button variant="primary" size="sm" className="py-0"
                        onClick={() => dispatch(editVimeoVideo(editData))}
                >
                  <FontAwesomeIcon icon={faSave}/>
                </Button>
              </td>
            </tr>
        })}
        </tbody>
        <tfoot>
        <tr>
          <td colSpan={5} style={{textAlign: 'center'}}>
            <Pagination>
              {
                vimeo.links.map((link, index) => {
                  return <Pagination.Item key={index} active={link.active}
                                          onClick={() => {
                                            if (!link.active && link.url)
                                              dispatch(getVimeoVideos(link.url.split("=")[1]));
                                          }}
                  ><span dangerouslySetInnerHTML={{__html: link.label}} /></Pagination.Item>
                })
              }
            </Pagination>
          </td>
        </tr>
        </tfoot>
      </Table>

    </Container>

  </StyledPrograms>;
}


  const StyledPrograms = styled.div`
    min-height: ${window.innerHeight - 155}px;

    .border-right {
      max-height: ${window.innerHeight - 150}px;
      overflow-y: scroll;
    }

    .personal-datum {
      max-height: ${window.innerHeight - 150}px;
      overflow-y: scroll;
    }

    .timetable {
      max-height: 200px;
      overflow-y: scroll;
      position: relative;

      td {
        vertical-align: middle;
      }
    }
  `;
