import React from 'react';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faEdit, faEye, faEyeSlash,
  faMinus,
  faPlus,
  faSave, faSearch,
  faTimes,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import {
  useCreateWorkoutProgramExerciseMutation, useDeleteWorkoutProgramDayMutation,
  useEditWorkoutProgramDayMutation,
  useSearchVideoMutation,
  useDeActivateWorkoutProgramDayMutation
} from "../../../actions/pcWorkoutApi";
import PCWorkoutExercise from "./PCWorkoutExercise";


export default function PCWorkoutDay({week_id, reloadData, day}) {

  const [editWorkoutDay, ewdResult] = useEditWorkoutProgramDayMutation();
  const [isEdit, setIsEdit] = React.useState(false);
  const [editQueue, setEditQueue] = React.useState(day.queue);
  const [editTitle, setEditTitle] = React.useState(day.title);
  const [editDescription, setEditDescription] = React.useState(day.description);
  const [deleteWorkoutDay, dwdResult] = useDeleteWorkoutProgramDayMutation();
  const [deActivateWorkoutDay, dadResult] = useDeActivateWorkoutProgramDayMutation();

  const [createWorkoutExercise, cweResult] = useCreateWorkoutProgramExerciseMutation();
  const [searchVideos, svResult] = useSearchVideoMutation();
  const [foundVideos, setFoundVideos] = React.useState([]);

  const [isNewExercise, setIsNewExercise] = React.useState(false);
  const [newExerciseQueue, setNewExerciseQueue] = React.useState(0);
  const [newExerciseTitle, setNewExerciseTitle] = React.useState('');
  const [newExerciseDescription, setNewExerciseDescription] = React.useState('');
  const [newExerciseReps, setNewExerciseReps] = React.useState('');
  const [newExerciseVideoUrl, setNewExerciseVideoUrl] = React.useState('');


  // deActivate workout day effect
  React.useEffect(() => {
    if (!dadResult.isLoading){
      dadResult.isSuccess && reloadData();
      dadResult.isError && console.log(dadResult.error.data);
    }
  }, [dadResult.isLoading]);

  // Edit workout day effect
  React.useEffect(() => {
    if (!ewdResult.isLoading){
      ewdResult.isSuccess && reloadData();
      ewdResult.isError && console.log(ewdResult.error.data);
    }
  }, [ewdResult.isLoading]);

  // handle edit
  const handleEdit = () => {
    if(editTitle || editDescription) {
      editWorkoutDay({day_id: day.id, queue: editQueue, title: editTitle, description: editDescription})
      setIsEdit(false);
    }
  }

  // handle cancel edit
  const handleCancelEdit = () => {
    setIsEdit(false);
    setEditTitle(day.title);
    setEditDescription(day.description);
  }

  // handle delete
  React.useEffect(() => {
    if (!dwdResult.isLoading){
      dwdResult.isSuccess && reloadData();
      dwdResult.isError && console.log(dwdResult.error.data);
    }
  }, [dwdResult.isLoading]);



  // Create new workout program exercise effect
  React.useEffect(() => {
    if (!cweResult.isLoading){
      if(cweResult.isSuccess) {
        setIsNewExercise(false);
        setNewExerciseTitle('');
        setNewExerciseDescription('');
        setNewExerciseReps('');
        setNewExerciseVideoUrl('');
        setFoundVideos([]);
        reloadData();
      }

      cweResult.isError && console.log(cweResult.error.data);
    }
  }, [cweResult.isLoading]);

  const handleCreateNewExercise = () => {
    if (newExerciseTitle && newExerciseDescription){
      createWorkoutExercise({day_id: day.id, title: newExerciseTitle, queue: newExerciseQueue, description: newExerciseDescription, reps: newExerciseReps, video_url: newExerciseVideoUrl});
    }
  }


  // Search video effect
  React.useEffect(() => {
    if (!svResult.isLoading){
      svResult.isSuccess && setFoundVideos(svResult.data);
      svResult.isError && console.log(svResult.error.data);
    }
  }, [svResult.isLoading]);


  // handle search video
  const handleSearchVideo = () => {
    if (newExerciseVideoUrl.length >= 2){
      setFoundVideos([]);
      searchVideos(newExerciseVideoUrl);
    }
  }


  return (
    <div className="day-container border-bottom border-dark">
      <div className="d-flex flex-row justify-content-between align-items-center">
        {
          (isEdit)
            ? <>
              <div className="flex-grow-1 mr-1">
                <input type="text" value={editQueue} onChange={(e) => setEditQueue(e.target.value)} className="form-control form-control-sm d-flex mb-1" placeholder={'Day queue'}/>
                <input type="text" value={editTitle} onChange={(e) => setEditTitle(e.target.value)} className="form-control form-control-sm d-flex mb-1" placeholder={'Day title'}/>
                <input type="text" value={editDescription} onChange={(e) => setEditDescription(e.target.value)} className="form-control form-control-sm d-flex" placeholder={'Day description'}/>
              </div>
              <div>
                <Button onClick={handleEdit} className="btn p-0 px-1 mr-1 btn-primary btn-sm"><FontAwesomeIcon icon={faSave}/></Button>
                <Button onClick={handleCancelEdit} className="btn p-0 px-1 btn-danger btn-sm"><FontAwesomeIcon icon={faTimes}/></Button>
              </div>
            </>
            : <>
              <div>
                <p className={"m-0"}><span className="badge badge-danger">{day.queue}</span> {day.title ? day.title : ""}</p>
                <span className={"small"}>{(day.description ? day.description : "").toString().substring(0, 40) + " ..."}</span>
              </div>
              <div>
                <Button onClick={() => setIsNewExercise(!isNewExercise)} className="btn p-0 px-1 btn-warning btn-sm"><FontAwesomeIcon icon={isNewExercise ? faMinus : faPlus} /></Button>
                <Button onClick={() => deActivateWorkoutDay(day.id)} className={`btn p-0 px-1 ${day.is_active ? "btn-success" : "btn-secondary"} btn-sm`}><FontAwesomeIcon icon={day.is_active ? faEye : faEyeSlash} /></Button>
                <Button onClick={() => setIsEdit(true)} className="btn p-0 px-1 btn-primary btn-sm"><FontAwesomeIcon icon={faEdit} /></Button>
                <Button onClick={() => window.confirm('Are you sure you wish to delete this day?') && deleteWorkoutDay(day.id)} className="btn p-0 px-1 btn-danger btn-sm"><FontAwesomeIcon icon={faTrash} /></Button>
              </div>
            </>
        }
      </div>

      {
        (isNewExercise)
          ? <div className="mt-2">
            <input type="text" value={newExerciseQueue} onChange={(e) => setNewExerciseQueue(e.target.value)} className="form-control form-control-sm mb-1" placeholder={'New exercise queue'}/>
            <input type="text" value={newExerciseTitle} onChange={(e) => setNewExerciseTitle(e.target.value)} className="form-control form-control-sm mb-1" placeholder={'New exercise title'}/>
            <input type="text" value={newExerciseDescription} onChange={(e) => setNewExerciseDescription(e.target.value)} className="form-control form-control-sm mb-1" placeholder={'New exercise description'}/>
            <input type="text" value={newExerciseReps} onChange={(e) => setNewExerciseReps(e.target.value)} className="form-control form-control-sm mb-1" placeholder={'New exercise reps'}/>

            <div className="input-group">
              <input type="text" value={newExerciseVideoUrl} onChange={(e) => setNewExerciseVideoUrl(e.target.value)} className="form-control form-control-sm" placeholder={'New exercise video url'}/>
              <div className="input-group-append">
                <Button onClick={handleSearchVideo} className="btn p-0 px-1 btn-dark btn-sm"><FontAwesomeIcon icon={faSearch}/></Button>
              </div>
            </div>

            <div className="p-1">
              {
                (foundVideos.length == 0)
                  ? <p className="p-0 m-0 small">No videos found</p>
                  : foundVideos.map((video, index) => <a href={"#"} onClick={(e) => {
                    e.preventDefault(); e.stopPropagation();
                    setNewExerciseVideoUrl(video.url);
                  }} className="d-block" key={video.id}>{(index+1) + ". "+video.title}</a>)
              }
            </div>

            <div className="d-flex justify-content-between mb-3">
              <Button onClick={handleCreateNewExercise} className="btn btn-primary px-2 btn-sm"><FontAwesomeIcon icon={faSave}/></Button>
              <Button onClick={() => setIsNewExercise(false)} className="btn  btn-danger px-2 btn-sm"><FontAwesomeIcon icon={faTimes}/></Button>
            </div>

          </div>
          : null
      }
      {
        (day.workout_program_exercises.length > 0) && <div className="exercises bg-success p-1 pl-3">
          {
            day.workout_program_exercises.map((exercise) => <PCWorkoutExercise key={exercise.id} reloadData={reloadData} exercise={exercise} /> )
          }
        </div>
      }

    </div>
  );
}