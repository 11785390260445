import React from 'react';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faSave, faSearch,
  faTimes,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import {
  useDeleteWorkoutProgramExerciseMutation,
  useEditWorkoutProgramExerciseMutation, useSearchVideoMutation
} from "../../../actions/pcWorkoutApi";


export default function PCWorkoutExercise({exercise, reloadData}) {

  const [editExercise, eeResults] = useEditWorkoutProgramExerciseMutation();
  const [isEdit, setIsEdit] = React.useState(false);
  const [editQueue, setEditQueue] = React.useState(exercise.queue);
  const [editTitle, setEditTitle] = React.useState(exercise.title);
  const [editDescription, setEditDescription] = React.useState(exercise.description);
  const [editReps, setEditReps] = React.useState(exercise.reps);
  const [editVideoUrl, setEditVideoUrl] = React.useState(exercise.video_data.url);

  // Edit exercise effect
  React.useEffect(() => {
    if (!eeResults.isLoading){
      eeResults.isSuccess && reloadData();
      eeResults.isError && console.log(eeResults.error.data);
    }
  }, [eeResults.isLoading]);

  const handleEdit = () => {
    if(editTitle || editDescription || editVideoUrl) {
      editExercise({exercise_id: exercise.id, title: editTitle, queue: editQueue, description: editDescription, reps: editReps, video_url: editVideoUrl})
      setIsEdit(false);
    }
  }


  // handle delete exercise
  const [deleteExercise, deResults] = useDeleteWorkoutProgramExerciseMutation();

  // Delete exercise effect
  React.useEffect(() => {
    if (!deResults.isLoading){
      deResults.isSuccess && reloadData();
      deResults.isError && console.log(deResults.error.data);
    }
  }, [deResults.isLoading]);



  const [searchVideos, svResult] = useSearchVideoMutation();
  const [foundVideos, setFoundVideos] = React.useState([]);

  // Search video effect
  React.useEffect(() => {
    if (!svResult.isLoading){
      svResult.isSuccess && setFoundVideos(svResult.data);
      svResult.isError && console.log(svResult.error.data);
    }
  }, [svResult.isLoading]);


  // handle search video
  const handleSearchVideo = () => {
    if (editVideoUrl.length >= 2){
      setFoundVideos([]);
      searchVideos(editVideoUrl);
    }
  }

  return (<>
    {
      (isEdit)
        ? <div className="mt-2">
          <input type="text" value={editQueue} onChange={(e) => setEditQueue(e.target.value)} className="form-control form-control-sm mb-1" placeholder={'New exercise queue'}/>
          <input type="text" value={editTitle} onChange={(e) => setEditTitle(e.target.value)} className="form-control form-control-sm mb-1" placeholder={'New exercise title'}/>
          <input type="text" value={editDescription} onChange={(e) => setEditDescription(e.target.value)} className="form-control form-control-sm mb-1" placeholder={'New exercise description'}/>
          <input type="text" value={editReps} onChange={(e) => setEditReps(e.target.value)} className="form-control form-control-sm mb-1" placeholder={'New exercise reps'}/>

          <div className="input-group">
            <input type="text" value={editVideoUrl} onChange={(e) => setEditVideoUrl(e.target.value)} className="form-control form-control-sm" placeholder={'New exercise video url'}/>
            <div className="input-group-append">
              <Button onClick={handleSearchVideo} className="btn p-0 px-1 btn-dark btn-sm"><FontAwesomeIcon icon={faSearch}/></Button>
            </div>
          </div>

          <div className="p-1">
            {
              (foundVideos.length == 0)
                ? <p className="p-0 m-0 small">No videos found</p>
                : foundVideos.map((video, index) => <a href={"#"} onClick={(e) => {
                  e.preventDefault(); e.stopPropagation();
                  setEditVideoUrl(video.url);
                }} className="d-block" key={video.id}>{(index+1) + ". "+video.title}</a>)
            }
          </div>

          <div className="d-flex justify-content-between mb-3">
            <Button onClick={handleEdit} className="btn btn-primary px-2 btn-sm"><FontAwesomeIcon icon={faSave}/></Button>
            <Button onClick={() => setIsEdit(false)} className="btn  btn-danger px-2 btn-sm"><FontAwesomeIcon icon={faTimes}/></Button>
          </div>

        </div>
        : <div className="d-flex flex-row justify-content-between align-items-center border-bottom pb-1">
          <div>
            <p className={"m-0"}><span className="badge badge-danger">{exercise.queue}</span> {(exercise.title ? exercise.title : "").toString().substring(0, 30) + " ..."}</p>
            {/*<span className={"small"}>{(exercise.description ? exercise.description : "").toString().substring(0, 40) + " ..."}</span><br />*/}
            {/*<span className={"small"}>{(exercise.reps ? exercise.reps : "").toString().substring(0, 40) + " ..."}</span><br/>*/}
            {/*<span className={"small"}>{(exercise.video_data.url ? exercise.video_data.url : "").toString().substring(0, 40) + " ..."}</span>*/}
          </div>
          <div>
            <Button onClick={() => setIsEdit(!isEdit)} className="btn p-0 px-1 btn-primary btn-sm"><FontAwesomeIcon icon={faEdit} /></Button>
            <Button onClick={() => window.confirm("Are you sure you want to delete this exercise?") && deleteExercise(exercise.id)} className="btn p-0 px-1 btn-danger btn-sm"><FontAwesomeIcon icon={faTrash} /></Button>
          </div>
        </div>
    }
  </>);
}