import {useSelector} from "react-redux";
import React from "react";
import {Table} from "react-bootstrap";
import NewColClientTimetable from "./NewColClientTimetable";
import ClientTimetableRow from "./ClientTimetableRow";

const ClientTimetable = () => {
  const current_client = useSelector(state => state.clients.current_client);

  return <>
    <h5>Timetable</h5>
    <div className="timetable text-center">
      <Table variant="dark" size={"sm"} bordered responsive className="small">
        <thead>
        <tr>
          <th>Date</th>
          <th>Workout</th>
          <th>Notes</th>
          <th>Done?</th>
          <th>Paid?</th>
          <th>{' '}</th>
        </tr>
        </thead>
        <tbody>

        <NewColClientTimetable />

        {
          (!current_client.personal_client_timetable.length)
            ? null
            : current_client.personal_client_timetable.map(each => <ClientTimetableRow key={each.id} data={each}/>)
        }

        </tbody>
      </Table>
    </div>
  </>;
}

export default ClientTimetable;
