import React from "react";
import {useDispatch, useSelector} from "react-redux";
import PCWorkoutContainer from "./PCWorkout/PCWorkoutContainer";
import {Button} from "react-bootstrap";
import {useCreateWorkoutProgramMutation, useGetWorkoutProgramsMutation} from "../../actions/pcWorkoutApi";
import {processLoadingEnd, processLoadingStart} from "../../actions/processActions";

export default function PCWorkoutProgram() {

  const dispatch = useDispatch();

  const current_client = useSelector(s => s.clients.current_client);

  const [getWorkouts, gwResults] = useGetWorkoutProgramsMutation();
  const [workoutPrograms, setWorkoutPrograms] = React.useState([]);
  const [createWorkout, createWorkoutResult] = useCreateWorkoutProgramMutation();

  const [newProgramTitle, setNewProgramTitle] = React.useState('');
  const [newProgramDescription, setNewProgramDescription] = React.useState('');
  const [isNewProgram, setIsNewProgram] = React.useState(false);

  React.useEffect(() => {
    if (current_client.id)
      getWorkouts(current_client.id);
    else
      gwResults.reset();
  }, [current_client]);


  React.useEffect(() => {
    if (!gwResults.isLoading && gwResults.isSuccess)
      setWorkoutPrograms(gwResults.data);

    if (!gwResults.isLoading && gwResults.isError)
      setWorkoutPrograms([]);

    if (gwResults.isLoading){
      dispatch(processLoadingStart());
    }else
      dispatch(processLoadingEnd());


  }, [gwResults.isLoading]);


  React.useEffect(() => {
    if (!createWorkoutResult.isLoading && createWorkoutResult.isSuccess){
      setWorkoutPrograms([createWorkoutResult.data, ...workoutPrograms]);
      setIsNewProgram(false);
      setNewProgramTitle('');
      setNewProgramDescription('');
    }

    if (!createWorkoutResult.isLoading && createWorkoutResult.isError)
      console.log(createWorkoutResult.error.data);

    if (createWorkoutResult.isLoading)
      dispatch(processLoadingStart());
    else
      dispatch(processLoadingEnd());

  }, [createWorkoutResult.isLoading]);



  return (
    <div>
      <div className="d-flex align-items-center mb-3">
        <h5>Workout programs</h5>
        <Button onClick={() => setIsNewProgram(!isNewProgram)} variant={'primary'} className="ml-3">Add new program</Button>
      </div>

      {
        (isNewProgram && current_client.id != '') && <div className="d-flex flex-row justify-content-between align-items-center my-3">
          <input type="text" value={newProgramTitle} onChange={(e) => setNewProgramTitle(e.target.value)} className="form-control d-flex mr-1" placeholder={'Workout program title'}/>
          <input type="text" value={newProgramDescription} onChange={(e) => setNewProgramDescription(e.target.value)} className="form-control d-flex" placeholder={'Workout program description'}/>
          <div className="d-flex">
            <Button onClick={() => createWorkout({
              client_id: current_client.id,
              title: newProgramTitle,
              description: newProgramDescription
            })} variant={'primary'} className="ml-3">Add</Button>
            <Button variant={'danger'} onClick={ () => setIsNewProgram(false)} className="ml-3">Cancel</Button>
          </div>
        </div>
      }

      <div className={"row"}>
        {
          workoutPrograms.length > 0
            ? workoutPrograms.map((program, key) => (
              <PCWorkoutContainer
                key={"pcworkoutp"+key}
                id={program.id}
                title={program.title}
                description={program.description}
              />
            )) : null
        }
      </div>
    </div>
  );
}