import React from 'react';
import styled from 'styled-components';
import {faEyeSlash, faEye} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import ibospirit from "../connect/ibospirit";
import urls from "../connect/urls";
import {useDispatch, useSelector} from "react-redux";
import {signInAction} from "../actions/authActions";
import history from "../history/history";
import {websiteName} from "../constants";
import {processLoadingEnd, processLoadingStart, processModalShow} from "../actions/processActions";

const SignIn = () => {
  const dispatch = useDispatch();
  const emailRef = React.useRef();
  const isLoading = useSelector(state => state.process.isLoading);

  // Setting up titles and checking if already signed in
  React.useEffect(() => {
    // Setting title
    window.document.title = "Sign In" + websiteName;
    emailRef.current.focus();

  }, []);

  // Preparing states to send
  const [email, setEmail] = React.useState({value: '', error: ''});
  const [password, setPassword] = React.useState({value: '', error: ''});
  const [isPassShown, setIsPassShown] = React.useState(false);

  // Sign in button action
  const signInHandler = async (e) => {
    try {
      e.preventDefault();
      if (isLoading) return 0;
      dispatch(processLoadingStart());

      const response = await ibospirit.post(urls.signInUrl,
        {email: email.value, password: password.value});

      dispatch(signInAction(response.data.token));
      history.push('/admin/workouts');

    } catch (e) {
      try{
        const code = parseInt(e.response.status);
        const data = e.response.data;

        switch (code) {
          case 400:
            if ('email' in data) setEmail({...email, error: data.email})
            if ('password' in data) setPassword({...password, error: data.password})
            break;
          case 401:
            dispatch(processModalShow(e.response.data.toString()))
            alert(e.response.data.toString());
            break;
          default:
            dispatch(processModalShow())
        }
      }catch(e){ dispatch(processModalShow('Check internet connection')) }

    } finally {
      dispatch(processLoadingEnd());
    }
  }

  return (
    <StyledSignIn>

      <form className="form-container" onSubmit={signInHandler}>
        <h3>sign in</h3>

        {/* Email input */}
        <div className="form-group position-relative">
          <label htmlFor="email">Email: </label>
          <input ref={emailRef} type="email" id="email" placeholder="example@mail.com"
                 value={email.value} onChange={e => setEmail({error: '', value: e.target.value})}
                 className={`form-control ${email.error ? "is-invalid" : ""}`}/>
          <div className="invalid-tooltip">{email.error}</div>
        </div>

        {/* Password input */}
        <div className="form-group position-relative">
          <label htmlFor="password">Password: </label>
          <div className="input-group">
            <input id="password" placeholder="* * * * * * * *" type={isPassShown ? "text" : "password"}
                   value={password.value} onChange={e => setPassword({error: '', value: e.target.value})}
                   className={`form-control ${password.error ? "is-invalid" : ""}`}
            />
            <div className="input-group-prepend">
              <button className="btn btn-dark" type="button" onClick={e => {
                e.preventDefault();
                setIsPassShown(!isPassShown);
              }}>
                {
                  isPassShown
                    ? <FontAwesomeIcon fixedWidth icon={faEyeSlash}/>
                    : <FontAwesomeIcon fixedWidth icon={faEye}/>
                }
              </button>
            </div>
            <div className="invalid-tooltip">{password.error}</div>
          </div>
        </div>

        {/* Sign in button */}
        <button className="btn btn-danger btn-block" type="submit">
          Sign in
        </button>
      </form>
    </StyledSignIn>
  );
};

const StyledSignIn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  height: ${window.innerHeight}px;
   
  .form-container{
    background: #666; 
    padding: 40px 20px;
    max-width: 300px;
    border-radius: 10px;
    margin: 40px 0;
       
    h3{
      text-transform: uppercase;
      font: 27px MontserratBold, sans-serif;
      text-align: center;
      margin-bottom: 20px;
    }
    
    button.btn-danger{
      margin-top: 40px;
    }
    
    .additional-links{
      text-align: center;
      margin-top: 40px;    
    }
  }
`;

export default SignIn;
