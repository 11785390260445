import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDollarSign, faEdit, faExchangeAlt, faInfo, faTimes} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const UserTableItem = (props) => {
  const {ind, data, onDelete, onEdit, onStatusToggle, onShowInfo, onGenerateMembership, onMembershipToggle}
    = props;
  return (
    <tr>
      <td>{ind}</td>
      <td>{data.user_info_data.name}</td>
      <td>{data.email}</td>
      <td>{data.created_at}</td>
      <td>
        {
          (data.user_payment != null)
            ? <div key={data.user_payment.id} className="pb-1">
              {data.user_payment.info}{' '}
              <button className="btn btn-sm btn-outline-light p-0 px-1"
                      onClick={() => onMembershipToggle(data.user_payment.id, data.id)}>
                <FontAwesomeIcon fixedWidth icon={faExchangeAlt}/>
              </button>
            </div>
            : 'No membership found'
        }</td>
      <td>
        {(data.is_active === 1) ? "ACTIVE" : "INACTIVE"} {' '}
        <button className="btn btn-sm btn-outline-light py-0 px-1" onClick={() => onStatusToggle(data.id)}>
          <FontAwesomeIcon fixedWidth icon={faExchangeAlt}/>
        </button>
      </td>
      <td>
        {
          (data.subscription_data.length) ? null
            :
            <button className="btn btn-sm btn-outline-success py-0 px-1" onClick={() => onGenerateMembership(data.id)}>
              <FontAwesomeIcon fixedWidth icon={faDollarSign}/>
            </button>

        }
        <button className="btn btn-sm btn-outline-light py-0 px-1 ml-1" onClick={() => onShowInfo(data.id)}>
          <FontAwesomeIcon fixedWidth icon={faInfo}/>
        </button>
        <button className="btn btn-sm btn-outline-primary py-0 px-1 ml-1" onClick={() => onEdit(data.id)}>
          <FontAwesomeIcon fixedWidth icon={faEdit}/>
        </button>
        <button className="btn btn-sm btn-outline-danger py-0 px-1 mx-1" onClick={() => onDelete(data.id)}>
          <FontAwesomeIcon fixedWidth icon={faTimes}/>
        </button>
      </td>
    </tr>
  );
}

export default UserTableItem;
