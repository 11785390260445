import {
  PROGRAMS_GET_ALL_PROGRAMS,
  PROGRAMS_GET_PROGRAM_DATA,
  PROGRAMS_OPEN_NEW_PROGRAM_MODAL,
  PROGRAMS_CLOSE_NEW_PROGRAM_MODAL,
  PROGRAMS_OPEN_EDIT_PROGRAM_MODAL,
  PROGRAMS_CLOSE_EDIT_PROGRAM_MODAL,
  PROGRAMS_OPEN_NEW_PROGRAM_HIERARCHY_MODAL,
  PROGRAMS_CLOSE_NEW_PROGRAM_HIERARCHY_MODAL,
  PROGRAMS_OPEN_EDIT_PROGRAM_HIERARCHY_MODAL,
  PROGRAMS_CLOSE_EDIT_PROGRAM_HIERARCHY_MODAL,
  PROGRAMS_OPEN_NEW_PROGRAM_EXERCISE_MODAL,
  PROGRAMS_CLOSE_NEW_PROGRAM_EXERCISE_MODAL,
  PROGRAMS_OPEN_EDIT_PROGRAM_EXERCISE_MODAL,
  PROGRAMS_CLOSE_EDIT_PROGRAM_EXERCISE_MODAL,
} from "../actions/programsActions";


const defaultState = {
  programs: [],
  current_program_data: {program_id: null, data: []},

  is_new_program_modal_open: false,
  is_edit_program_modal_open: {state: false, data: null},

  is_new_program_hierarchy_modal_open: {state: false, program_id: null, parent_hierarchy_id: null},
  is_edit_program_hierarchy_modal_open: {state: false, data: null},

  is_new_program_exercise_modal_open: {state: false, program_id: null, hierarchy_id: null},
  is_edit_program_exercise_modal_open: {state: false, data: null},
}


const programsReducer = (state = defaultState, action) => {
  switch (action.type) {

    case PROGRAMS_GET_ALL_PROGRAMS :
      return {...state, programs: action.payload};

    case PROGRAMS_GET_PROGRAM_DATA :
      return {...state, current_program_data: {...action.payload}};


    case PROGRAMS_OPEN_NEW_PROGRAM_MODAL :
      return {...state, is_new_program_modal_open: true};

    case PROGRAMS_CLOSE_NEW_PROGRAM_MODAL :
      return {...state, is_new_program_modal_open: false};


    case PROGRAMS_OPEN_EDIT_PROGRAM_MODAL :
      let found_program_to_edit = state.programs.filter(ep => parseInt(ep.id) === parseInt(action.payload));

      return (found_program_to_edit.length)
        ? {...state, is_edit_program_modal_open: {state: true, data: found_program_to_edit[0]}}
        : state;

    case PROGRAMS_CLOSE_EDIT_PROGRAM_MODAL :
      return {...state, is_edit_program_modal_open: {state: false, data: null}};


    case PROGRAMS_OPEN_NEW_PROGRAM_HIERARCHY_MODAL :
      return {
        ...state, is_new_program_hierarchy_modal_open: {
          state: true, program_id: action.payload.program_id,
          parent_hierarchy_id: action.payload.parent_hierarchy_id
        }
      };

    case PROGRAMS_CLOSE_NEW_PROGRAM_HIERARCHY_MODAL :
      return {
        ...state,
        is_new_program_hierarchy_modal_open: {
          ...state.is_new_program_hierarchy_modal_open, state: false
        }
      };


    case PROGRAMS_OPEN_EDIT_PROGRAM_HIERARCHY_MODAL :
      return {...state, is_edit_program_hierarchy_modal_open: {state: true, data: action.payload}};

    case PROGRAMS_CLOSE_EDIT_PROGRAM_HIERARCHY_MODAL :
      return {...state, is_edit_program_hierarchy_modal_open: {state: false, data: null}};


    case PROGRAMS_OPEN_NEW_PROGRAM_EXERCISE_MODAL :
      return {
        ...state, is_new_program_exercise_modal_open: {
          state: true, program_id: action.payload.program_id,
          hierarchy_id: action.payload.hierarchy_id
        }
      };

    case PROGRAMS_CLOSE_NEW_PROGRAM_EXERCISE_MODAL :
      return {...state, is_new_program_exercise_modal_open: {state: false, program_id: null, hierarchy_id: null}};


    case PROGRAMS_OPEN_EDIT_PROGRAM_EXERCISE_MODAL :
      return { ...state, is_edit_program_exercise_modal_open: { state: true, data: action.payload} };

    case PROGRAMS_CLOSE_EDIT_PROGRAM_EXERCISE_MODAL :
      return {...state, is_edit_program_exercise_modal_open: {state: false, data: null}};

    default :
      return state;
  }
}

export default programsReducer;
