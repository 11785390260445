import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {Button, FormControl} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTimes} from "@fortawesome/free-solid-svg-icons";
import Cleave from 'cleave.js/react';
import moment from 'moment';
import {clearEditingStat, editStatToClient} from "../../../actions/clientsActions";

const EditColClientStat = () => {
  const dispatch = useDispatch();
  const editing_stat = useSelector(state => state.clients.current_client.editing_stat);

  const [currData, setCurrData] = React.useState({
    id: '',
    weight: '',
    height: '',
    muscle_mass: '',
    body_fat: '',
    bmi: '',
    visceral_fat: '',
    age: '',
    date: ''
  });

  React.useEffect(() => {
    setCurrData(state => ({
      ...state,
      id: editing_stat.id,
      weight: editing_stat.weight,
      height: editing_stat.height,
      muscle_mass: editing_stat.muscle_mass,
      body_fat: editing_stat.body_fat,
      bmi: editing_stat.bmi,
      visceral_fat: editing_stat.visceral_fat,
      age: editing_stat.age,
      date: moment(editing_stat.date).format('DD/MM/YYYY'),
    }));
  }, [])


  const onSubmit = () => currData.id && dispatch(editStatToClient(currData));
  const onClear = () => currData.id && dispatch(clearEditingStat());

  return <tr>
    <td>
      <Cleave className="form-control form-control-sm"
              options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
              placeholder="01/12/2020"
              value={currData.date}
              onChange={(e) => { setCurrData({...currData, date: e.target.value}); }}
      />
    </td>
    <td>
      <FormControl placeholder="Muscle mass" type="number" size="sm"
                   value={currData.muscle_mass} onChange={(e) => { setCurrData({...currData, muscle_mass: e.target.value}); }}/>
    </td>
    <td>
      <FormControl placeholder="Body fat" type="number" size="sm"
                   value={currData.body_fat} onChange={(e) => { setCurrData({...currData, body_fat: e.target.value}); }}/>
    </td>
    <td>
      <FormControl placeholder="BMI" type="number" size="sm"
                   value={currData.bmi} onChange={(e) => { setCurrData({...currData, bmi: e.target.value}); }}/>
    </td>
    <td>
      <FormControl placeholder="Visceral fat" type="number" size="sm"
                   value={currData.visceral_fat} onChange={(e) => { setCurrData({...currData, visceral_fat: e.target.value}); }}/>
    </td>
    <td>
      <FormControl placeholder="Weight (kg)" type="number" size="sm"
                   value={currData.weight} onChange={(e) => { setCurrData({...currData, weight: e.target.value}); }}/>
    </td>
    <td>
      <FormControl placeholder="Height (sm)" type="number" size="sm"
                   value={currData.height} onChange={(e) => { setCurrData({...currData, height: e.target.value}); }}/>
    </td>
    <td>
      <FormControl placeholder="Age" type="number" size="sm"
                   value={currData.age} onChange={(e) => { setCurrData({...currData, age: e.target.value}); }}/>
    </td>
    <td>
      <Button onClick={onClear} variant="secondary" size={"sm"} className="py-0">
        <FontAwesomeIcon icon={faTimes}/>
      </Button> {" "}
      <Button onClick={onSubmit} variant="info" size={"sm"} className="py-0">
        <FontAwesomeIcon icon={faSave}/>
      </Button>
    </td>
  </tr>
}

export default EditColClientStat;
