import React, {useState} from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import {useDispatch} from "react-redux";
import {closeNewWorkoutModal, createWorkout} from "../../actions/workoutsActions";
import ImageUploader from "react-images-upload";

const NewWorkoutModal = () => {
  const dispatch = useDispatch();

  const [data, setData] = useState({
    title: '',
    description: '',
    icon: null
  });

  React.useEffect(() => {
    const escapeKeydownFire = e => { if (e.keyCode === 27) dispatch(closeNewWorkoutModal()); }
    window.addEventListener('keydown', escapeKeydownFire);
    return () => { window.removeEventListener('keydown', escapeKeydownFire); }
  })

  // Set image on drop or change
  const onDrop = (icon) => setData({...data, icon: icon[0]});

  return (
    ReactDOM.createPortal(
      <>
        <StyledNewWorkoutModalBackground/>
        <StyledNewWorkoutModal>
          <div className="m-container">
            <div className="m-title">add new workout</div>

            <div className="m-body">
              <div className="row">

                {/* Title */}
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="new_workout_title">Title <span className="text-danger">*</span> :</label>
                    <input type="text" className="form-control" placeholder={'Workout title'} id="new_workout_title"
                           value={data.title} onChange={e => setData({...data, title: e.target.value})}/>
                  </div>
                </div>

                {/* Description */}
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="new_workout_description">Description <span className="text-danger">*</span> :</label>
                    <textarea id="new_workout_description" rows="7" className="form-control"
                              onChange={e => setData({...data, description: e.target.value})} value={data.description}/>
                  </div>
                </div>

                {/* Image */}
                <div className="col-12">
                  <ImageUploader
                    singleImage={true}
                    withPreview={true}
                    onChange={onDrop}
                    imgExtension={[".jpg", ".gif", ".png", ".gif"]}
                    buttonText={"Choose icon"}
                  />
                </div>

              </div>
            </div>

            <div className="row">
              <div className="col">
                <button
                  className="btn btn-outline-danger btn-block"
                  onClick={() => dispatch(closeNewWorkoutModal())}>Cancel
                </button>
              </div>
              <div className="col">
                <button
                  className="btn btn-outline-success btn-block"
                  onClick={() => dispatch(createWorkout(data))}>Add
                </button>
              </div>
            </div>
          </div>
        </StyledNewWorkoutModal>
      </>,
      document.getElementById('modal')
    )
  );
}

const StyledNewWorkoutModalBackground = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 1005; 
`;

const StyledNewWorkoutModal = styled.div`
  position: fixed;
  z-index: 1007;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  
  .m-container{
    max-height: ${window.innerHeight - 50}px;
    overflow-y: scroll;
    margin: 0 15px;
    min-width: 300px;
    width: 600px;
    background: #303030;
    padding: 20px 15px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px #444;
    
    height: auto;
    
    
    .m-title{
      font: 20px MontserratBold, sans-serif;
      text-transform:uppercase;
      text-align: center;
      margin-bottom: 15px;
    }
    
    .m-body{
      font-size: 14px;
      margin-bottom: 20px;
      
      p {margin-bottom: 5px}
    }
    
    
    button{
    
    }
  }
`;

export default NewWorkoutModal;
