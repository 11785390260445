import {ADMIN_SIGN_IN, ADMIN_SIGN_OUT} from "./types";


// Sign in action by setting new token
export const signInAction = (token) => dispatch => {
  dispatch({type: ADMIN_SIGN_IN, payload: token});
}


// Sign out action
export const signOutAction = () => dispatch => {
  dispatch({type: ADMIN_SIGN_OUT});
}
