import React from 'react';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faSave,
  faEdit,
  faPlus,
  faSync,
  faTrash,
  faTimes,
  faMinus,
  faCopy, faRunning, faWineBottle,
} from "@fortawesome/free-solid-svg-icons";
import {
  useDeleteWorkoutProgramMutation,
  useEditWorkoutProgramMutation,
  useGetWorkoutProgramMutation,
  useDeActivateWorkoutProgramMutation,
  useCreateWorkoutProgramWeekMutation,
  useIsNutritionalWorkoutProgramMutation,
  useCopyToMutation

} from "../../../actions/pcWorkoutApi";
import PCWorkoutWeek from "./PCWorkoutWeek";
import {processLoadingEnd, processLoadingStart} from "../../../actions/processActions";
import {useDispatch, useSelector} from "react-redux";


export default function PCWorkoutContainer({id, title="", description=""}) {

  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = React.useState(false);
  const [editTitle, setEditTitle] = React.useState(title);
  const [editDescription, setEditDescription] = React.useState(description);
  const [editWorkout, ewResult] = useEditWorkoutProgramMutation();
  const [getWorkout, gwResult] = useGetWorkoutProgramMutation();
  const [copyTo, ctResult] = useCopyToMutation();
  const [deActivateWorkout, deawResult] = useDeActivateWorkoutProgramMutation();
  const [isNutritionalWorkout, inwResult] = useIsNutritionalWorkoutProgramMutation();
  const clients = useSelector(state => state.clients.clients);
  const [selectedClient, setSelectedClient] = React.useState(null);

  const [createWorkoutWeek, cwwResult] = useCreateWorkoutProgramWeekMutation();

  const [isDelete, setIsDelete] = React.useState(false);
  const [deleteWorkout, dwResult] = useDeleteWorkoutProgramMutation();

  const [isNewWeek, setIsNewWeek] = React.useState(false);
  const [newWeekQueue, setNewWeekQueue] = React.useState(0);
  const [newWeekTitle, setNewWeekTitle] = React.useState('');
  const [newWeekDescription, setNewWeekDescription] = React.useState('');


  const [currentProgramData, setCurrentProgramData] = React.useState(null);


  // Get workout program effect
  React.useEffect(() => {
    if (!gwResult.isLoading && gwResult.isSuccess){
      setCurrentProgramData(gwResult.data);
      setEditTitle(gwResult.data.title);
      setEditDescription(gwResult.data.description);
    }

    if (!gwResult.isLoading && gwResult.isError)
      setCurrentProgramData(null);

    if (gwResult.isLoading){
      dispatch(processLoadingStart());
    }
    else
      dispatch(processLoadingEnd());

  }, [gwResult.isLoading]);


  // Copy to effect
  React.useEffect(() => {
    if (!ctResult.isLoading){
      ctResult.isSuccess && setCurrentProgramData(ctResult.data);
      ctResult.isError && console.log(ctResult.error.data);
    }

    (ctResult.isLoading) ? dispatch(processLoadingStart()) : dispatch(processLoadingEnd());

  }, [ctResult.isLoading]);


  // Delete workout program effect
  React.useEffect(() => {
    if (!dwResult.isLoading){
      dwResult.isSuccess && setIsDelete(true);
      dwResult.isError && console.log(dwResult.error.data);
    }

    (dwResult.isLoading) ? dispatch(processLoadingStart()) : dispatch(processLoadingEnd());

  }, [dwResult.isLoading]);


  // Activate/Deactivate workout program effect
  React.useEffect(() => {
    if (!deawResult.isLoading){
      deawResult.isSuccess && setCurrentProgramData(deawResult.data);
      deawResult.isError && console.log(deawResult.error.data);
    }

    (deawResult.isLoading) ? dispatch(processLoadingStart()) : dispatch(processLoadingEnd());

  }, [deawResult.isLoading]);


  // toggle is nutritional workout program effect
  React.useEffect(() => {
    if (!inwResult.isLoading){
      inwResult.isSuccess && setCurrentProgramData(inwResult.data);
      inwResult.isError && console.log(inwResult.error.data);
    }

    (inwResult.isLoading) ? dispatch(processLoadingStart()) : dispatch(processLoadingEnd());

  }, [inwResult.isLoading]);


  // Create new workout program week effect
  React.useEffect(() => {
    if (!cwwResult.isLoading){
      if(cwwResult.isSuccess) {
        setIsNewWeek(false);
        setNewWeekTitle('');
        setNewWeekDescription('');
        getWorkout(id);
      }

      cwwResult.isError && console.log(cwwResult.error.data);
    }

    (cwwResult.isLoading) ? dispatch(processLoadingStart()) : dispatch(processLoadingEnd());

  }, [cwwResult.isLoading]);

  const handleCreateNewWeek = () => {
    if (newWeekTitle && newWeekDescription){
      createWorkoutWeek({program_id: id, title: newWeekTitle, queue: newWeekQueue, description: newWeekDescription});
    }
  }


  // Edit workout program effect
  React.useEffect(() => {
    if (!ewResult.isLoading){
      ewResult.isSuccess && setCurrentProgramData(ewResult.data);
      ewResult.isError && console.log(ewResult.error.data);
    }

    (ewResult.isLoading) ? dispatch(processLoadingStart()) : dispatch(processLoadingEnd());

  }, [ewResult.isLoading]);


  // Edit workout program handle
  const handleEdit = () => {
    if (editTitle && editDescription){
      editWorkout({program_id: id, title: editTitle, description: editDescription});
      setIsEdit(false);
    }
  }

  // Cancel edit workout program handle
  const handleCancelEdit = () => {
    setEditTitle(currentProgramData ? currentProgramData.title : title);
    setEditDescription(currentProgramData ? currentProgramData.description : description);
    setIsEdit(false);
  }

  return (
    (!isDelete) && <div className="col-6 mb-4">
      <div className="card bg-dark">
        {/* title start */}
        <div className="card-header">
          {
            isEdit
              ? <div className="d-flex flex-row justify-content-between align-items-center">
                <div className="flex-grow-1 mr-1">
                  <input type="text" value={editTitle} onChange={(e) => setEditTitle(e.target.value)} className="form-control form-control-sm d-flex mb-1" placeholder={'Workout program title'}/>
                  <input type="text" value={editDescription} onChange={(e) => setEditDescription(e.target.value)} className="form-control form-control-sm d-flex" placeholder={'Workout program description'}/>
                </div>
                <div>
                  <Button onClick={handleEdit} className="btn p-0 px-1 mr-1 btn-primary btn-sm"><FontAwesomeIcon icon={faSave}/></Button>
                  <Button onClick={handleCancelEdit} className="btn p-0 px-1 btn-danger btn-sm"><FontAwesomeIcon icon={faTimes}/></Button>
                </div>
              </div>
              : <div className="d-flex flex-row justify-content-between align-items-center">
                <div>
                  <h6 className="card-title m-0">{currentProgramData ? currentProgramData.title : title}</h6>
                  <p className="m-0 card-subtitle text-muted">{ (currentProgramData ? currentProgramData.description : description).toString().substring(0, 25)+ " ..." }</p>
                </div>
                <div>
                  <Button onClick={() => isNutritionalWorkout(id)} className="btn p-0 px-1 mr-1 btn-info btn-sm"><FontAwesomeIcon icon={currentProgramData ? currentProgramData.is_nutrition ? faWineBottle : faRunning : faRunning}/></Button>
                  <Button onClick={() => setIsNewWeek(!isNewWeek)} className="btn p-0 px-1 mr-1 btn-warning btn-sm"><FontAwesomeIcon icon={isNewWeek ? faMinus : faPlus}/></Button>
                  <Button onClick={() => setIsEdit(true)} className="btn p-0 px-1 mr-1 btn-primary btn-sm"><FontAwesomeIcon icon={faEdit}/></Button>
                  <Button onClick={() => deActivateWorkout(id)} className="btn p-0 px-1 mr-1 btn-secondary btn-sm">{currentProgramData ? (currentProgramData.is_active ? "Active" : "Inactive") : "?"}</Button>
                  <Button onClick={() => {window.confirm('Are you sure to Delete?') && deleteWorkout(id);}} className="btn p-0 px-1 btn-danger btn-sm"><FontAwesomeIcon icon={faTrash}/></Button>
                </div>
              </div>
          }
          {
            isNewWeek
              ? <div className="d-flex flex-row justify-content-between align-items-center mt-2">
                <div className="d-flex mr-1">
                  <input type="text" value={newWeekQueue} onChange={(e) => setNewWeekQueue(e.target.value)} className="form-control form-control-sm d-flex mb-1 mr-1" placeholder={'New week queue'}/>
                  <input type="text" value={newWeekTitle} onChange={(e) => setNewWeekTitle(e.target.value)} className="form-control form-control-sm d-flex mb-1 mr-1" placeholder={'New week title'}/>
                  <input type="text" value={newWeekDescription} onChange={(e) => setNewWeekDescription(e.target.value)} className="form-control form-control-sm d-flex" placeholder={'New week description'}/>
                </div>
                <div className="d-flex">
                  <Button onClick={handleCreateNewWeek} className="btn p-0 px-1 mr-1 btn-primary btn-sm"><FontAwesomeIcon icon={faSave}/></Button>
                  <Button onClick={() => setIsNewWeek(false)} className="btn p-0 px-1 btn-danger btn-sm"><FontAwesomeIcon icon={faTimes}/></Button>
                </div>
              </div>
              : null
          }
        </div>
        {/* title end */}

        {/* body start */}
        <div className="card-body p-2">

          {/* week start */}
            {
              (currentProgramData && currentProgramData.workout_program_weeks.length > 0)
                ? <div className="weeks border border-secondary p-2">{
                  currentProgramData.workout_program_weeks.map((week) => {
                    return <PCWorkoutWeek key={week.id} week={week} program_id={id} reloadData={() => getWorkout(id)} />
                  })
                }</div>: null
            }
          {/* week end */}
        </div>
        {/* body end */}

        {/* footer start */}
        <div className="card-footer">
          <div className="d-flex justify-content-between">
            <Button onClick={() => {getWorkout(id)}} size={"sm"} variant={"primary"}>Get <FontAwesomeIcon icon={faSync}/></Button>
            <Button onClick={() => { setCurrentProgramData(null) }} size={"sm"} variant={"secondary"} className={"ml-2"}>Hide</Button>
          </div>
          <div className="input-group mt-2">
            <select value={selectedClient} onChange={(e) => setSelectedClient(e.target.value)} className="form-control form-control-sm">
              <option value={null}>Select a client</option>
              {
                clients.map((client) => {
                  return client.is_active && <option key={client.id} value={client.id}>{client.name}</option>
                })
              }
            </select>
            <div className="input-group-append">
              <Button onClick={() => copyTo({client_id: selectedClient, program_id: id})} className="btn p-0 px-2 btn-light btn-sm"><FontAwesomeIcon icon={faCopy}/></Button>
            </div>
          </div>
        </div>
        {/* footer end */}
      </div>
    </div>
  );
}