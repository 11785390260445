import {
  NUTRITION_GET_NUTRITION_UNIT_TYPES,
  NUTRITION_OPEN_MODAL_NEW_NUTRITION_UNIT_TYPE,
  NUTRITION_CLOSE_MODAL_NEW_NUTRITION_UNIT_TYPE,
  NUTRITION_OPEN_MODAL_EDIT_NUTRITION_UNIT_TYPE,
  NUTRITION_CLOSE_MODAL_EDIT_NUTRITION_UNIT_TYPE,
  NUTRITION_GET_NUTRITION_CATEGORIES,
  NUTRITION_OPEN_MODAL_NEW_NUTRITION_CATEGORY,
  NUTRITION_CLOSE_MODAL_NEW_NUTRITION_CATEGORY,
  NUTRITION_OPEN_MODAL_EDIT_NUTRITION_CATEGORY,
  NUTRITION_CLOSE_MODAL_EDIT_NUTRITION_CATEGORY,
  NUTRITION_GET_NUTRITION_CATEGORY_ITEMS,
  NUTRITION_OPEN_MODAL_NEW_NUTRITION_CATEGORY_ITEM,
  NUTRITION_OPEN_MODAL_EDIT_NUTRITION_CATEGORY_ITEM,
  NUTRITION_CLOSE_MODAL_EDIT_NUTRITION_CATEGORY_ITEM,
  NUTRITION_CLOSE_MODAL_NEW_NUTRITION_CATEGORY_ITEM
} from "../actions/nutritionActions";

const defaultState = {
  unit_types: [],
  is_new_unit_type_modal_open: false,
  is_edit_unit_type_modal_open: {state: false, data: null},

  categories: [],
  is_new_category_modal_open: false,
  is_edit_category_modal_open: {state: false, data: null},

  category_items: [],
  is_new_category_item_modal_open: false,
  is_edit_category_item_modal_open: {state: false, data: null},
}

const nutritionReducer = (state = defaultState, action) => {

  switch (action.type) {

    case NUTRITION_GET_NUTRITION_UNIT_TYPES:
      return {...state, unit_types: action.payload};

    case NUTRITION_OPEN_MODAL_NEW_NUTRITION_UNIT_TYPE:
      return {...state, is_new_unit_type_modal_open: true};

    case NUTRITION_CLOSE_MODAL_NEW_NUTRITION_UNIT_TYPE:
      return {...state, is_new_unit_type_modal_open: false};

    case NUTRITION_OPEN_MODAL_EDIT_NUTRITION_UNIT_TYPE:
      const found_unit_type = state.unit_types.filter(eut => eut.id === parseInt(action.payload));
      return {...state, is_edit_unit_type_modal_open: {state: true, data: found_unit_type[0]}};

    case NUTRITION_CLOSE_MODAL_EDIT_NUTRITION_UNIT_TYPE:
      return {...state, is_edit_unit_type_modal_open: {state: false, data: null}};


    case NUTRITION_GET_NUTRITION_CATEGORIES:
      return {...state, categories: action.payload};

    case NUTRITION_OPEN_MODAL_NEW_NUTRITION_CATEGORY:
      return {...state, is_new_category_modal_open: true};

    case NUTRITION_CLOSE_MODAL_NEW_NUTRITION_CATEGORY:
      return {...state, is_new_category_modal_open: false};

    case NUTRITION_OPEN_MODAL_EDIT_NUTRITION_CATEGORY:
      const found_category = state.categories.filter(ec => ec.id === parseInt(action.payload));
      return {...state, is_edit_category_modal_open: {state: true, data: found_category[0]}};

    case NUTRITION_CLOSE_MODAL_EDIT_NUTRITION_CATEGORY:
      return {...state, is_edit_category_modal_open: {state: false, data: null}};


    case NUTRITION_GET_NUTRITION_CATEGORY_ITEMS:
      return {...state, category_items: action.payload};

    case NUTRITION_OPEN_MODAL_NEW_NUTRITION_CATEGORY_ITEM:
      return {...state, is_new_category_item_modal_open: true};

    case NUTRITION_CLOSE_MODAL_NEW_NUTRITION_CATEGORY_ITEM:
      return {...state, is_new_category_item_modal_open: false};

    case NUTRITION_OPEN_MODAL_EDIT_NUTRITION_CATEGORY_ITEM:
      const found_category_item = state.category_items.filter(eci => eci.id === parseInt(action.payload));
      return {...state, is_edit_category_item_modal_open: {state: true, data: found_category_item[0]}};

    case NUTRITION_CLOSE_MODAL_EDIT_NUTRITION_CATEGORY_ITEM:
      return {...state, is_edit_category_item_modal_open: {state: false, data: null}};

    default :
      return state;
  }
}

export default nutritionReducer;
