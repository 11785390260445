import {useSelector} from "react-redux";
import React from "react";
import {Table} from "react-bootstrap";
import NewColClientStat from "./NewColClientStat";
import ClientStatRow from "./ClientStatRow";

const ClientStat = () => {
  const current_client = useSelector(state => state.clients.current_client);

  return <>
    <h5>Stats</h5>
    <div className="timetable text-center">
      <Table variant="dark" size={"sm"} bordered responsive className="small">
        <thead>
        <tr>
          <th>Date</th>
          <th>Muscle mass</th>
          <th>Body fat</th>
          <th>BMI</th>
          <th>Visceral fat</th>
          <th>Weight</th>
          <th>Height</th>
          <th>Age</th>
          <th>{" "}</th>
        </tr>
        </thead>
        <tbody>

        <NewColClientStat />

        {
          (!current_client.personal_client_data.length)
            ? null
            : current_client.personal_client_data.map(each => <ClientStatRow key={each.id} data={each}/>)
        }

        </tbody>
      </Table>
    </div>
  </>;
}

export default ClientStat;
