import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faFilm,
  faFolder, faFolderOpen,
  faPlus,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import ConfirmModal from "../ConfirmModal";
import {
  deActivateProgramHierarchy, deActivateProgramHierarchyIsFreeFeature,
  deleteProgramHierarchy,
  openEditProgramHierarchyModal, openNewProgramExerciseModal,
  openNewProgramHierarchyModal
} from "../../actions/programsActions";
import ProgramExerciseContainer from "./ProgramExerciseContainer";

const ProgramHierarchyContainer = ({data}) => {
  const dispatch = useDispatch();

  const [isDeleteModal, setIsDeleteModal] = React.useState({state: false, id: null});
  const [isOpen, setIsOpen] = React.useState(true);

  const hierarchyEditHandler = e => {
    e.preventDefault();
    dispatch(openEditProgramHierarchyModal(data));
  }

  return (
    <div className="hierarchy">
      <div className={`hierarchy-top ${data.is_active ? '' : 'not-seen'}`}>
        <div className="hierarchy-title">
          <span onClick={ () => setIsOpen(!isOpen) }>
          --- <FontAwesomeIcon icon={isOpen ? faFolderOpen : faFolder} />
          </span>{' '}
          <a href="#edit" onClick={ hierarchyEditHandler }>{data.title}</a>
        </div>
        <div className="hierarchy-actions">
          {
            (data.exercises_data.length === 0)
            ? <button className="btn btn-primary btn-sm" onClick={ () => dispatch(openNewProgramHierarchyModal(data.id, data.program_id))}>
                <FontAwesomeIcon icon={faPlus}/>{' '}
                <FontAwesomeIcon icon={faFolder}/>
              </button> : null
          }
          {
            (data.all_children_hierarchies.length === 0)
              ? <button className="btn btn-info btn-sm" onClick={ () => dispatch(openNewProgramExerciseModal(data.id, data.program_id))}>
                <FontAwesomeIcon icon={faPlus}/>{' '}
                <FontAwesomeIcon icon={faFilm}/>
              </button> : null
          }
          <button className="btn btn-outline-light btn-sm" onClick={() => dispatch(deActivateProgramHierarchy(data.id))}>
            <FontAwesomeIcon icon={data.is_active ? faEyeSlash : faEye} fixedWidth/>
          </button>
          <button className="btn btn-outline-light btn-sm" onClick={() => dispatch(deActivateProgramHierarchyIsFreeFeature(data.id))}>
            {data.is_free ? 'Free' : 'Pay'}
          </button>
          <button className="btn btn-outline-danger btn-sm" onClick={() => setIsDeleteModal({state: true, id: data.id})}>
            <FontAwesomeIcon icon={faTimes} fixedWidth/>
          </button>
        </div>
      </div>
      <div className={`hierarchy-content ${isOpen ? '' : 'd-none'}`}>
        {data.all_children_hierarchies.map(each => <ProgramHierarchyContainer key={each.id} data={each}/>)}
        {data.exercises_data.map(each => <ProgramExerciseContainer key={each.id} data={each}/>)}
      </div>
      {/* Hierarchy delete modal */}
      {
        (isDeleteModal.state)
          ? <ConfirmModal
            body={'Are you really sure you want to delete this Hierarchy?'}
            onCancel={() => {
              setIsDeleteModal({state: false, id: null})
            }}
            onSuccess={() => {
              dispatch(deleteProgramHierarchy(isDeleteModal.id));
              setIsDeleteModal({state: false, id: null})
            }}
          /> : null
      }
    </div>
  );
}

export default ProgramHierarchyContainer;
