import {processLoadingEnd, processLoadingStart, processModalShow} from "./processActions";

import urls from "../connect/urls";
import {signOutAction} from "./authActions";

export const FAQS_GET_ALL_FAQS = "FAQS_GET_ALL_FAQS";

export const FAQS_OPEN_NEW_FAQ_MODAL = "FAQS_OPEN_NEW_FAQ_MODAL";
export const FAQS_CLOSE_NEW_FAQ_MODAL = "FAQS_CLOSE_NEW_FAQ_MODAL";

export const FAQS_OPEN_EDIT_FAQ_MODAL = "FAQS_OPEN_EDIT_FAQ_MODAL";
export const FAQS_CLOSE_EDIT_FAQ_MODAL = "FAQS_CLOSE_EDIT_FAQ_MODAL";


/**  Faq view manipulation START */
// Get all faqs data
export const getFaqsData = () => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    const response = await ibospirit.post(urls.getFAQs, {},
      {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    dispatch({type: FAQS_GET_ALL_FAQS, payload: response.data});

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}

// Order faq item up
export const orderUpFaq = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.orderUpFAQ, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getFaqsData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}

// Order faq item down
export const orderDowFaq = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.orderDownFAQ, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getFaqsData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}
/**  Faq view manipulation END   */


/**  New faq modal START */
// New faq modal manipulation
export const openNewFaqModal = () => ({type: FAQS_OPEN_NEW_FAQ_MODAL});
export const closeNewFaqModal = () => ({type: FAQS_CLOSE_NEW_FAQ_MODAL });

// Create new faq
export const createFaq = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.createFAQ, data, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(closeNewFaqModal());
    dispatch(getFaqsData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) { dispatch(processModalShow('Network error. Check internet connection'));
    } finally { dispatch(processLoadingEnd());}
  }
}
/**  New faq modal END   */


/**  Edit faq modal START */
// Edit faq modal manipulation
export const openEditFaqModal = (id) => ({ type: FAQS_OPEN_EDIT_FAQ_MODAL, payload: id });
export const closeEditFaqModal = () => ({ type: FAQS_CLOSE_EDIT_FAQ_MODAL });

// edit faq
export const editFaq = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.editFAQ, data, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(closeEditFaqModal());
    dispatch(getFaqsData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));

    }finally {
      dispatch(processLoadingEnd());
    }
  }
}
/**  Edit faq modal END   */


// Activate / Deactivate faq
export const deActivateFaq = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deActivateFAQ, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getFaqsData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}

// Delete faq
export const deleteFaq = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deleteFAQ, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getFaqsData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}

// Reorder faq
export const reorderFaq = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.reorderFAQ, {faqs: data}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getFaqsData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}
