import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTimes} from "@fortawesome/free-solid-svg-icons";
import moment from 'moment';
import EditColClientStat from "./EditColClientStat";
import {openDeleteStatModal, prepareToEditStat} from "../../../actions/clientsActions";

const ClientStatRow = ({data}) => {

  const dispatch = useDispatch();
  const editing_stat = useSelector(state => state.clients.current_client.editing_stat);

  const onEdit = (id) => id && dispatch(prepareToEditStat(id));
  const onDelete = (id) => id && dispatch(openDeleteStatModal(id));

  if (editing_stat.is_open && editing_stat.id == data.id)
    return <EditColClientStat />

  return <tr>
    <td>{moment(data.date).format('DD.MM.YYYY')}</td>
    <td>{data.muscle_mass}</td>
    <td>{data.body_fat}</td>
    <td>{data.bmi}</td>
    <td>{data.visceral_fat}</td>
    <td>{data.weight}</td>
    <td>{data.height}</td>
    <td>{data.age}</td>
    <td>
      <Button variant="primary" onClick={() => onEdit(data.id)} size={"sm"} className="py-0">
        <FontAwesomeIcon icon={faEdit}/>
      </Button> {" "}
      <Button variant="danger" onClick={() => onDelete(data.id)} size={"sm"} className="py-0">
        <FontAwesomeIcon icon={faTimes}/>
      </Button>
    </td>
  </tr>;
}

export default ClientStatRow;
