import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faFilm, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import ConfirmModal from "../ConfirmModal";
import {
  deActivateProgramExercise,
  deleteProgramExercise,
  openEditProgramExerciseModal
} from "../../actions/programsActions";

const ProgramExerciseContainer = ({data}) => {

  const dispatch = useDispatch();

  const [isDeleteModal, setIsDeleteModal] = React.useState({state: false, id: null});

  return (
    <div className={`exercise ${data.is_active ? '' : 'not-seen' }`}>
      <div className="exercise-title"><a href="#edit" onClick={e => {e.preventDefault(); dispatch(openEditProgramExerciseModal(data))}}>
        --- <FontAwesomeIcon icon={faFilm} /> {data.title}</a></div>
      <div className="exercise-actions">
        <span>({data.views}) </span>
        <button className="btn btn-outline-light btn-sm" onClick={() => dispatch(deActivateProgramExercise(data.id))}>
          <FontAwesomeIcon icon={data.is_active ? faEyeSlash : faEye} fixedWidth />
        </button>
        <button className="btn btn-outline-danger btn-sm" onClick={() => setIsDeleteModal({state: true, id: data.id})}>
          <FontAwesomeIcon icon={faTimes} fixedWidth />
        </button>
      </div>

      {/* Exercise delete modal */}
      {
        (isDeleteModal.state)
          ? <ConfirmModal
            body={'Are you really sure you want to delete this exercise?'}
            onCancel={ () => { setIsDeleteModal({state: false, id: null}) } }
            onSuccess={ () => { dispatch(deleteProgramExercise(isDeleteModal.id)); setIsDeleteModal({state: false, id: null}) } }
          /> : null
      }
    </div>
  );
}

export default ProgramExerciseContainer;
