import React, {useState} from "react";
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from "react-redux";
import {closeNewProgramHierarchyModal, createProgramHierarchy} from "../../../actions/programsActions";
import MediumModal from "../../MediumModal";

const NewProgramHierarchyModal = () => {
  const dispatch = useDispatch();
  const new_p_h_additional_data = useSelector(s => s.programs.is_new_program_hierarchy_modal_open)
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  return (
    ReactDOM.createPortal(
      <MediumModal onSuccess={ () => dispatch(createProgramHierarchy({
        program_id: new_p_h_additional_data.program_id,
        parent_id: new_p_h_additional_data.parent_hierarchy_id,
        title, description
      }))}
                   onClose={ () => dispatch(closeNewProgramHierarchyModal())}
                   title={'Create new program hierarchy'} onSuccessText={'Add'}>

        {/* Title */}
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="new_program_hierarchy_title">Title :</label>
            <input type="text" className="form-control" placeholder={'Hierarchy title'} id="new_program_hierarchy_title"
                   value={title} onChange={e => setTitle(e.target.value)}/>
          </div>
        </div>

        {/* Description */}
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="new_program_hierarchy_description">Description :</label>
            <textarea className="form-control" placeholder={'Hierarchy description'} id="new_program_hierarchy_description"
                   value={description} onChange={e => setDescription(e.target.value)}/>
          </div>
        </div>

      </MediumModal>,
      document.getElementById('modal')
    )
  );
}

export default NewProgramHierarchyModal;
