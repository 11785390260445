import {processLoadingEnd, processLoadingStart, processModalShow} from "./processActions";

import urls from "../connect/urls";
import {signOutAction} from "./authActions";

export const PROGRAMS_GET_ALL_PROGRAMS = "PROGRAMS_GET_ALL_PROGRAMS";
export const PROGRAMS_GET_PROGRAM_DATA = "PROGRAMS_GET_PROGRAM_DATA";


export const PROGRAMS_OPEN_NEW_PROGRAM_MODAL = "PROGRAMS_OPEN_NEW_PROGRAM_MODAL";
export const PROGRAMS_CLOSE_NEW_PROGRAM_MODAL = "PROGRAMS_CLOSE_NEW_PROGRAM_MODAL";

export const PROGRAMS_OPEN_EDIT_PROGRAM_MODAL = "PROGRAMS_OPEN_EDIT_PROGRAM_MODAL";
export const PROGRAMS_CLOSE_EDIT_PROGRAM_MODAL = "PROGRAMS_CLOSE_EDIT_PROGRAM_MODAL";


export const PROGRAMS_OPEN_NEW_PROGRAM_HIERARCHY_MODAL = "PROGRAMS_OPEN_NEW_PROGRAM_HIERARCHY_MODAL";
export const PROGRAMS_CLOSE_NEW_PROGRAM_HIERARCHY_MODAL = "PROGRAMS_CLOSE_NEW_PROGRAM_HIERARCHY_MODAL";

export const PROGRAMS_OPEN_EDIT_PROGRAM_HIERARCHY_MODAL = "PROGRAMS_OPEN_EDIT_PROGRAM_HIERARCHY_MODAL";
export const PROGRAMS_CLOSE_EDIT_PROGRAM_HIERARCHY_MODAL = "PROGRAMS_CLOSE_EDIT_PROGRAM_HIERARCHY_MODAL";


export const PROGRAMS_OPEN_NEW_PROGRAM_EXERCISE_MODAL = "PROGRAMS_OPEN_NEW_PROGRAM_EXERCISE_MODAL";
export const PROGRAMS_CLOSE_NEW_PROGRAM_EXERCISE_MODAL = "PROGRAMS_CLOSE_NEW_PROGRAM_EXERCISE_MODAL";

export const PROGRAMS_OPEN_EDIT_PROGRAM_EXERCISE_MODAL = "PROGRAMS_OPEN_EDIT_PROGRAM_EXERCISE_MODAL";
export const PROGRAMS_CLOSE_EDIT_PROGRAM_EXERCISE_MODAL = "PROGRAMS_CLOSE_EDIT_PROGRAM_EXERCISE_MODAL";


// Get all programs
export const getPrograms = () => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    const response = await ibospirit.post(urls.getPrograms, {},
      {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    dispatch({type: PROGRAMS_GET_ALL_PROGRAMS, payload: response.data});

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}

// Get program data
export const getProgramData = (program_id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    const response = await ibospirit.post(urls.getProgramHierarchiesAndExercises, {id: program_id},
      {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    dispatch({type: PROGRAMS_GET_PROGRAM_DATA, payload: { program_id, data: response.data }});

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}


/** *********************************************************************** **/

/**  New Program modal START */
// New Workout modal manipulation
export const openNewProgramModal = () => ({type: PROGRAMS_OPEN_NEW_PROGRAM_MODAL});
export const closeNewProgramModal = () => ({type: PROGRAMS_CLOSE_NEW_PROGRAM_MODAL });

// Create new program
export const createProgram = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());
    let newData = new FormData();
    newData.append('title', data.title);
    newData.append('description', data.description);
    newData.append('is_repeatable', data.is_repeatable ? 1 : 0);

    if (data.icon)
      newData.append('icon', data.icon, data.icon.fileName);

    await ibospirit.post(urls.createProgram, newData, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(closeNewProgramModal());
    dispatch(getPrograms());

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) { dispatch(processModalShow('Network error. Check internet connection'));
    } finally { dispatch(processLoadingEnd());}
  }
}
/**  New Program modal END   */

/**  Edit program modal START */
// Edit program modal manipulation
export const openEditProgramModal = (id) => ({ type: PROGRAMS_OPEN_EDIT_PROGRAM_MODAL, payload: id });
export const closeEditProgramModal = () => ({ type: PROGRAMS_CLOSE_EDIT_PROGRAM_MODAL });

// Edit program
export const editProgram = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());
    let newData = new FormData();
    newData.append('id', data.id);
    newData.append('title', data.title);
    newData.append('description', data.description);
    newData.append('is_repeatable', data.is_repeatable ? 1 : 0);

    if (data.icon)
      newData.append('icon', data.icon, data.icon.fileName);

    await ibospirit.post(urls.editProgram, newData, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(closeEditProgramModal());
    dispatch(getPrograms());

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));

    }finally {
      dispatch(processLoadingEnd());
    }
  }
}
/**  Edit program modal END   */

// Activate / Deactivate Program
export const deActivateProgram = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deActivateProgram, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getPrograms());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}

// Activate / Deactivate Pro Program
export const deActivateProProgram = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deActivateProProgram, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getPrograms());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}

// Delete program
export const deleteProgram = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deleteProgram, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getPrograms());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}

/** *********************************************************************** **/

/**  New Program hierarchy modal START */
// New hierarchy modal manipulation
export const openNewProgramHierarchyModal = (parent_hierarchy_id, program_id) => ({type: PROGRAMS_OPEN_NEW_PROGRAM_HIERARCHY_MODAL, payload: {parent_hierarchy_id, program_id}});
export const closeNewProgramHierarchyModal = () => ({type: PROGRAMS_CLOSE_NEW_PROGRAM_HIERARCHY_MODAL });

// Create new program hierarchy
export const createProgramHierarchy = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.createProgramHierarchy, data, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(closeNewProgramHierarchyModal());
    dispatch(getProgramData(data.program_id));

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) { dispatch(processModalShow('Network error. Check internet connection'));
    } finally { dispatch(processLoadingEnd());}
  }
}
/**  New Program hierarchy modal END   */

/**  Edit Program hierarchy modal START */
// Edit hierarchy modal manipulation
export const openEditProgramHierarchyModal = (data) => ({type: PROGRAMS_OPEN_EDIT_PROGRAM_HIERARCHY_MODAL, payload: data});
export const closeEditProgramHierarchyModal = () => ({type: PROGRAMS_CLOSE_EDIT_PROGRAM_HIERARCHY_MODAL});

// Edit program hierarchy
export const editProgramHierarchy = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.editProgramHierarchy, data, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(closeEditProgramHierarchyModal());
    dispatch(getProgramData(getState().programs.current_program_data.program_id));

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) { dispatch(processModalShow('Network error. Check internet connection'));
    } finally { dispatch(processLoadingEnd());}
  }
}
/**  Edit Program hierarchy modal END   */

// Activate / Deactivate Program Hierarchy
export const deActivateProgramHierarchy = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deActivateProgramHierarchy, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getProgramData(getState().programs.current_program_data.program_id));
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}

// Activate / Deactivate Program Hierarchy is free feature
export const deActivateProgramHierarchyIsFreeFeature = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deActivateProgramHierarchyIsFreeFeature, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getProgramData(getState().programs.current_program_data.program_id));
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}

// Delete program hierarchy
export const deleteProgramHierarchy = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deleteProgramHierarchy, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getProgramData(getState().programs.current_program_data.program_id));
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}

/** *********************************************************************** **/

/**  New Program exercise modal START */
// New program exercise modal manipulation
export const openNewProgramExerciseModal = (hierarchy_id, program_id) => ({type: PROGRAMS_OPEN_NEW_PROGRAM_EXERCISE_MODAL, payload: {hierarchy_id, program_id}});
export const closeNewProgramExerciseModal = () => ({type: PROGRAMS_CLOSE_NEW_PROGRAM_EXERCISE_MODAL });

// Create new program exercise
export const createProgramExercise = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.createProgramExercise, data, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(closeNewProgramExerciseModal());
    dispatch(getProgramData(getState().programs.current_program_data.program_id));

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) { dispatch(processModalShow('Network error. Check internet connection'));
    } finally { dispatch(processLoadingEnd());}
  }
}
/**  New Program exercise modal END   */


/**  Edit Program exercise modal START */
// Edit program exercise modal manipulation
export const openEditProgramExerciseModal = (data) => ({type: PROGRAMS_OPEN_EDIT_PROGRAM_EXERCISE_MODAL, payload: data});
export const closeEditProgramExerciseModal = () => ({type: PROGRAMS_CLOSE_EDIT_PROGRAM_EXERCISE_MODAL });

// Edit program exercise
export const editProgramExercise = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.editProgramExercise, data, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(closeEditProgramExerciseModal());
    dispatch(getProgramData(getState().programs.current_program_data.program_id));

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) { dispatch(processModalShow('Network error. Check internet connection'));
    } finally { dispatch(processLoadingEnd());}
  }
}
/**  Edit Program exercise modal END   */

// Activate / Deactivate Program exercise
export const deActivateProgramExercise = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deActivateProgramExercise, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getProgramData(getState().programs.current_program_data.program_id));
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}

// Delete program exercise
export const deleteProgramExercise = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deleteProgramExercise, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getProgramData(getState().programs.current_program_data.program_id));
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}
