// Sign in, sign out action types
export const ADMIN_SIGN_IN = "ADMIN_SIGN_IN";
export const ADMIN_SIGN_OUT = "ADMIN_SIGN_OUT";


// Global processing and global modal action types
export const PROCESS_LOADING_START = "PROCESS_LOADING_START";
export const PROCESS_LOADING_END = "PROCESS_LOADING_END";
export const PROCESS_MODAL_SHOW = "PROCESS_MODAL_SHOW";
export const PROCESS_MODAL_SHOW_WITH_TITLE = "PROCESS_MODAL_SHOW_WITH_TITLE";
export const PROCESS_MODAL_CLOSE = "PROCESS_MODAL_CLOSE";

export const USERS_GET_ALL_12_WEEK_USERS = "USERS_GET_ALL_12_WEEK_USERS";

// Users manipulations
export const USERS_GET_ALL_USERS = "USERS_GET_ALL_USERS";
export const USERS_NEXT_PAGE = "USERS_NEXT_PAGE";
export const USERS_PREVIOUS_PAGE = "USERS_PREVIOUS_PAGE";
export const USERS_GIVEN_PAGE = "USERS_GIVEN_PAGE";

export const USERS_CLOSE_INFO_USER_MODAL = "USERS_CLOSE_INFO_USER_MODAL";
export const USERS_GET_USER_INFO_DATA = "USERS_GET_USER_INFO_DATA";


export const USERS_OPEN_NEW_USER_MODAL = "USERS_OPEN_NEW_USER_MODAL";
export const USERS_CLOSE_NEW_USER_MODAL = "USERS_CLOSE_NEW_USER_MODAL";

export const USERS_OPEN_EDIT_USER_MODAL = "USERS_OPEN_EDIT_USER_MODAL";
export const USERS_CLOSE_EDIT_USER_MODAL = "USERS_CLOSE_EDIT_USER_MODAL";

export const CLIENTS_NEW_CLIENT_MODAL_OPEN = "CLIENTS_NEW_CLIENT_MODAL_OPEN";
export const CLIENTS_NEW_CLIENT_MODAL_CLOSE = "CLIENTS_NEW_CLIENT_MODAL_CLOSE";
export const CLIENTS_GET_CLIENTS = "CLIENTS_GET_CLIENTS";
export const CLIENTS_GET_CLIENT = "CLIENTS_GET_CLIENT";
export const CLIENTS_RESET_CURRENT_CLIENT = "CLIENTS_RESET_CURRENT_CLIENT";

export const CLIENTS_DELETE_CLIENT_MODAL_OPEN = "CLIENTS_DELETE_CLIENT_MODAL_OPEN";
export const CLIENTS_DELETE_CLIENT_MODAL_CLOSE = "CLIENTS_DELETE_CLIENT_MODAL_CLOSE";

export const CLIENTS_CLEAR_NEW_TIMETABLE_ROW = "CLIENTS_CLEAR_NEW_TIMETABLE_ROW";

export const CLIENTS_CLEAR_EDITING_TIMETABLE_ROW = "CLIENTS_CLEAR_EDITING_TIMETABLE_ROW";
export const CLIENTS_PREPARE_EDITING_TIMETABLE_ROW = "CLIENTS_PREPARE_EDITING_TIMETABLE_ROW";

export const CLIENTS_DELETE_TIMETABLE_MODAL_OPEN = "CLIENTS_DELETE_TIMETABLE_MODAL_OPEN";
export const CLIENTS_DELETE_TIMETABLE_MODAL_CLOSE = "CLIENTS_DELETE_TIMETABLE_MODAL_CLOSE";

export const CLIENTS_CURRENT_TIMETABLE_MODAL_OPEN = "CLIENTS_CURRENT_TIMETABLE_MODAL_OPEN";
export const CLIENTS_CURRENT_TIMETABLE_MODAL_CLOSE = "CLIENTS_CURRENT_TIMETABLE_MODAL_CLOSE";


export const CLIENTS_CLEAR_NEW_STAT_ROW = "CLIENTS_CLEAR_NEW_STAT_ROW";

export const CLIENTS_CLEAR_EDITING_STAT_ROW = "CLIENTS_CLEAR_EDITING_STAT_ROW";
export const CLIENTS_PREPARE_EDITING_STAT_ROW = "CLIENTS_PREPARE_EDITING_STAT_ROW";

export const CLIENTS_DELETE_STAT_MODAL_OPEN = "CLIENTS_DELETE_STAT_MODAL_OPEN";
export const CLIENTS_DELETE_STAT_MODAL_CLOSE = "CLIENTS_DELETE_STAT_MODAL_CLOSE";

export const CLIENTS_GET_CLIENT_IMAGES = "CLIENTS_GET_CLIENT_IMAGES";
export const CLIENTS_DELETE_IMAGE_MODAL_OPEN = "CLIENTS_DELETE_IMAGE_MODAL_OPEN";
export const CLIENTS_DELETE_IMAGE_MODAL_CLOSE = "CLIENTS_DELETE_IMAGE_MODAL_CLOSE";



export const VIMEO_VIDEOS_FETCH_RESPONSE_VIDEO = "VIMEO_VIDEOS_FETCH_RESPONSE_VIDEO";
export const VIMEO_VIDEOS_GET_VIDEOS = "VIMEO_VIDEOS_GET_VIDEOS";
export const VIMEO_VIDEOS_CREATE_VIDEO = "VIMEO_VIDEOS_CREATE_VIDEO";
export const VIMEO_VIDEOS_EDIT_VIDEO = "VIMEO_VIDEOS_EDIT_VIDEO";
export const VIMEO_VIDEOS_DELETE_VIDEO = "VIMEO_VIDEOS_DELETE_VIDEO";