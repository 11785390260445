import {WEB_HOOKS_GET_ALL_WEB_HOOKS, WEB_HOOKS_NEXT_PAGE, WEB_HOOKS_PREVIOUS_PAGE} from "../actions/webHooksActions";


const defaultState = {
  data: [],
  current_page: 1,
  last_page: 1,
  total: 0
}

const webHooksReducer = (state = defaultState, action) => {
  switch (action.type) {

    case WEB_HOOKS_GET_ALL_WEB_HOOKS :
      const {data, current_page, total, last_page} = action.payload;
      return {...state, data, current_page, total, last_page};

    case WEB_HOOKS_NEXT_PAGE :
      return (state.current_page < state.last_page)
        ? {...state, current_page: state.current_page + 1} : state;

    case WEB_HOOKS_PREVIOUS_PAGE :
      return (state.current_page > 1)
        ? {...state, current_page: state.current_page - 1} : state;

    default :
      return state;
  }
}

export default webHooksReducer;
