import {
  WORKOUTS_CLOSE_EDIT_WORKOUT_EXERCISE_MODAL,
  WORKOUTS_CLOSE_EDIT_WORKOUT_MODAL,
  WORKOUTS_CLOSE_NEW_WORKOUT_EXERCISE_MODAL,
  WORKOUTS_CLOSE_NEW_WORKOUT_MODAL,
  WORKOUTS_GET_ALL_WORKOUTS,
  WORKOUTS_OPEN_EDIT_WORKOUT_EXERCISE_MODAL,
  WORKOUTS_OPEN_EDIT_WORKOUT_MODAL,
  WORKOUTS_OPEN_NEW_WORKOUT_EXERCISE_MODAL,
  WORKOUTS_OPEN_NEW_WORKOUT_MODAL
} from "../actions/workoutsActions";


const defaultState = {
  data: [],
  is_new_workout_modal_open: false,
  is_edit_workout_modal_open: {state: false, data: null},

  is_new_workout_exercise_modal_open: {state: false, workout_id: null, workout_title: null},
  is_edit_workout_exercise_modal_open: {state: false, data: null, workout_id: null, workout_title: null}
}

const workoutsReducer = (state = defaultState, action) => {
  let found_workout = null;

  switch (action.type) {

    case WORKOUTS_GET_ALL_WORKOUTS :
      return {...state, data: action.payload };

    case WORKOUTS_OPEN_NEW_WORKOUT_MODAL :
      return {...state, is_new_workout_modal_open: true};

    case WORKOUTS_CLOSE_NEW_WORKOUT_MODAL :
      return {...state, is_new_workout_modal_open: false};

    case WORKOUTS_OPEN_EDIT_WORKOUT_MODAL :
      found_workout = state.data.filter(wo => parseInt(wo.id) === parseInt(action.payload));
      return (found_workout.length === 0)
        ? state
        : {...state, is_edit_workout_modal_open: {state: true, data: found_workout[0]}};

    case WORKOUTS_CLOSE_EDIT_WORKOUT_MODAL :
      return {...state, is_edit_workout_modal_open: {state: false, data: null}};


    case WORKOUTS_OPEN_NEW_WORKOUT_EXERCISE_MODAL:
      found_workout = state.data.filter(wo => parseInt(wo.id) === parseInt(action.payload));
      return (found_workout.length === 0)
        ? state
        : {...state, is_new_workout_exercise_modal_open: {state: true, workout_id: action.payload, workout_title: found_workout[0].title}};

    case WORKOUTS_CLOSE_NEW_WORKOUT_EXERCISE_MODAL:
      return {...state, is_new_workout_exercise_modal_open: {...state.is_new_workout_exercise_modal_open, state: false}};


    case WORKOUTS_OPEN_EDIT_WORKOUT_EXERCISE_MODAL:
      found_workout = state.data.filter(wo => parseInt(wo.id) === parseInt(action.payload.workout_id));
      if (found_workout.length === 0)
        return state;
      else{
        let found_workout_exercise = found_workout[0].workout_exercises_data.filter(e => parseInt(e.id) === parseInt(action.payload.exercise_id));
        return (found_workout_exercise.length === 0)
        ? state
        : {...state, is_edit_workout_exercise_modal_open: { state: true, workout_id: found_workout[0].id, workout_title: found_workout[0].title, data: found_workout_exercise[0]}};
      }

    case WORKOUTS_CLOSE_EDIT_WORKOUT_EXERCISE_MODAL:
      return {...state, is_edit_workout_exercise_modal_open: {...state.is_edit_workout_exercise_modal_open, state: false}};

    default :
      return state;
  }
}

export default workoutsReducer;
