import React, {useState} from "react";
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  closeEditProgramHierarchyModal,
  editProgramHierarchy
} from "../../../actions/programsActions";
import MediumModal from "../../MediumModal";

const EditProgramHierarchyModal = () => {
  const dispatch = useDispatch();
  const edit_p_h_additional_data = useSelector(s => s.programs.is_edit_program_hierarchy_modal_open.data)

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');


  React.useEffect(() => {
    setTitle(edit_p_h_additional_data.title);
    setDescription(edit_p_h_additional_data.description);
  }, [])

  return (
    ReactDOM.createPortal(
      <MediumModal onSuccess={ () => dispatch(editProgramHierarchy({
        id: edit_p_h_additional_data.id,
        program_id: edit_p_h_additional_data.program_id,
        title, description
      }))}
                   onClose={ () => dispatch(closeEditProgramHierarchyModal())}
                   title={'Edit program hierarchy'} onSuccessText={'Edit'}>

        {/* Title */}
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="edit_program_hierarchy_title">Title :</label>
            <input type="text" className="form-control" placeholder={'Hierarchy title'} id="edit_program_hierarchy_title"
                   value={title} onChange={e => setTitle(e.target.value)}/>
          </div>
        </div>

        {/* Description */}
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="edit_program_hierarchy_description">Description :</label>
            <textarea className="form-control" placeholder={'Hierarchy description'} id="edit_program_hierarchy_description"
                      value={description} onChange={e => setDescription(e.target.value)}/>
          </div>
        </div>

      </MediumModal>,
      document.getElementById('modal')
    )
  );
}

export default EditProgramHierarchyModal;
