import React from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import ConfirmModal from "../components/ConfirmModal";
import {useDispatch, useSelector} from "react-redux";
import {websiteName} from "../constants";
import {
  deActivateFaq,
  deleteFaq,
  getFaqsData,
  openEditFaqModal,
  openNewFaqModal
} from "../actions/faqsActions";
import NewFaqModal from "../components/Faqs/NewFaqModal";
import EditFaqModal from "../components/Faqs/EditFaqModal";
import FaqTableItem from "../components/Faqs/FaqTableItem";


const Faqs = () => {
  const faqs = useSelector(state => state.faqs);
  const dispatch = useDispatch();

  const [isDeleteModal, setIsDeleteModal] = React.useState({state: false, id: null});

  React.useEffect(() => {
    document.title = 'FAQs' + websiteName;
    dispatch(getFaqsData());
  }, [])

  return <StyledFaqs>
    {/* New faq modal start */}
    {(faqs.is_new_faq_modal_open) ? <NewFaqModal /> : null}
    {/* New faq modal end   */}

    {/* Edit faq modal start */}
    {(faqs.is_edit_faq_modal_open.state) ? <EditFaqModal /> : null}
    {/* Edit faq modal end   */}


    {/* FAQ delete modal start */}
    {
      (isDeleteModal.state)
        ? <ConfirmModal
          body={'Are you really sure you want to delete this FAQ?'}
          onCancel={ () => { setIsDeleteModal({state: false, id: null}) } }
          onSuccess={ () => { dispatch(deleteFaq(isDeleteModal.id)); setIsDeleteModal({state: false, id: null}) } }
        /> : null
    }
    {/* FAQ delete modal end   */}

    {/* New faq add button and reorder button start */}
    <h4>Faqs{' '}
      <button className="btn btn-primary btn-sm" onClick={() => dispatch(openNewFaqModal())}>
        <FontAwesomeIcon icon={faPlus}/>
      </button>
    </h4>
    {/* New faq add button and reorder button end   */}

    <div className="container-fluid p-0">
      <div className="table-responsive">
        <table className="table table-bordered table-sm table-hover table-dark">
          <thead key={'thead'}>
          <tr className="text-center">
            <th>#</th>
            <th>Title</th>
            <th>Description</th>
            <th>Actions</th>
          </tr>
          </thead>

          <tbody key={'tbody'}>
          {
            (faqs.data.length > 0)
              ? faqs.data.map((each, index) => <FaqTableItem key={"faq-"+each.id} ind={index + 1} data={each}
                              onDelete={ (id) => setIsDeleteModal({state: true, id: id}) }
                              onEdit={(id) => dispatch(openEditFaqModal(id)) }
                              onDeActivate={(user_id) => dispatch(deActivateFaq(user_id))}
                />)
              : <tr>
                <td colSpan={4}>No FAQs found</td>
              </tr>
          }
          </tbody>
        </table>
      </div>

    </div>

  </StyledFaqs>;
};


const StyledFaqs = styled.div`
  min-height: ${window.innerHeight - 155}px;
  
  table{
    font-size: 14px;
    thead, tfoot{
      font-size: 11px;
      font-family: MontserratBold, sans-serif;
      td, th {
        padding: 10px;
      }
      
    }
    
    tbody{
      td{
        text-align: center;
      }
    }
  }  
`;

export default Faqs;
