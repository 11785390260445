import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faClock, faEdit, faTimes} from "@fortawesome/free-solid-svg-icons";
import {openDeleteTimetableModal, prepareToEditTimetable} from "../../../actions/clientsActions";
import moment from 'moment';
import EditColClientTimetable from "./EditColClientTimetable";

const ClientTimetableRow = ({data}) => {

  const dispatch = useDispatch();
  const editing_timetable = useSelector(state => state.clients.current_client.editing_timetable);

  const onEdit = (id) => id && dispatch(prepareToEditTimetable(id));
  const onDelete = (id) => id && dispatch(openDeleteTimetableModal(id));

  if (editing_timetable.is_open && editing_timetable.id == data.id)
    return <EditColClientTimetable />

  return <tr>
    <td>{moment(data.date).format('hh:mmA DD.MM.YYYY')}</td>
    <td>{data.workout}</td>
    <td>{data.notes}</td>
    <td>
      <FontAwesomeIcon size="lg" icon={data.is_done ? faCheck : faClock} />
    </td>
    <td>
      <FontAwesomeIcon size="lg" icon={data.is_paid ? faCheck : faTimes} />
    </td>
    <td>
      <Button variant="primary" onClick={() => onEdit(data.id)} size={"sm"} className="py-0">
        <FontAwesomeIcon icon={faEdit}/>
      </Button> {" "}
      <Button variant="danger" onClick={() => onDelete(data.id)} size={"sm"} className="py-0">
        <FontAwesomeIcon icon={faTimes}/>
      </Button>
    </td>
  </tr>;
}

export default ClientTimetableRow;
