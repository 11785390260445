import React, {useState} from "react";
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from "react-redux";
import { closeEditNutritionCategoryItemAction, editNutritionCategoryItemAction } from "../../actions/nutritionActions";
import MediumModal from "../MediumModal";

const EditNutritionCategoryItemModal = ({category_id}) => {
  const dispatch = useDispatch();
  const nutrition = useSelector(state => state.nutrition);
  const edit_item = useSelector(state => state.nutrition.is_edit_category_item_modal_open.data);

  const [id, setId] = useState('');
  const [title, setTitle] = useState('');
  const [quantity, setQuantity] = useState('');
  const [unit_type, setUnitType] = useState('0');
  const [calorie, setCalorie] = useState('');
  const [carbs, setCarbs] = useState('');
  const [protein, setProtein] = useState('');
  const [fat, setFat] = useState('');
  const [description, setDescription] = useState('');

  React.useEffect(() => {
    setId(edit_item.id);
    setTitle(edit_item.title);
    setQuantity(edit_item.quantity);
    setUnitType(edit_item.unit_type_id);
    setCalorie(edit_item.calorie);
    setCarbs(edit_item.carbs);
    setProtein(edit_item.protein);
    setFat(edit_item.fat);
    setDescription(edit_item.description ? edit_item.description : '');
  }, []);

  return (
    ReactDOM.createPortal(
      <MediumModal onSuccess={ () => dispatch(editNutritionCategoryItemAction({
        id, category_id, unit_type_id: unit_type, title, quantity, calorie, carbs, protein, fat, description
      }))}
                   onClose={ () => dispatch(closeEditNutritionCategoryItemAction())}
                   title={'Edit category item'} onSuccessText={'Edit'}>

        {/* Title */}
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="edit_category_item_title">Title :</label>
            <input type="text" className="form-control" placeholder={'Item title'} id="edit_category_item_title"
                   value={title} onChange={e => setTitle(e.target.value)}/>
          </div>
        </div>

        {/* Quantity */}
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="edit_category_item_quantity">Quantity :</label>
            <div className="input-group">
              <input type="number" className="form-control" placeholder={'Quantity'} id="edit_category_item_quantity"
                     value={quantity} onChange={e => setQuantity(e.target.value)}/>
              <div className="input-group-append">
                <select className="form-control" value={unit_type} onChange={e => setUnitType(e.target.value)}>
                  <option value="0">-Select-</option>
                  {
                    nutrition.unit_types.map(eut => <option key={"da"+eut.id} value={eut.id}>{eut.title}</option>)
                  }
                </select>
              </div>
            </div>
          </div>
        </div>

        {/* Calorie */}
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="edit_category_item_calorie">Calorie :</label>
            <input type="number" className="form-control" placeholder={'Calorie'} id="edit_category_item_calorie"
                   value={calorie} onChange={e => setCalorie(e.target.value)}/>
          </div>
        </div>

        {/* Carbs */}
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="edit_category_item_carbs">Carbs :</label>
            <input type="number" className="form-control" placeholder={'Carbs'} id="edit_category_item_carbs"
                   value={carbs} onChange={e => setCarbs(e.target.value)}/>
          </div>
        </div>

        {/* Protein */}
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="edit_category_item_protein">Protein :</label>
            <input type="number" className="form-control" placeholder={'Protein'} id="edit_category_item_protein"
                   value={protein} onChange={e => setProtein(e.target.value)}/>
          </div>
        </div>

        {/* Fat */}
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="edit_category_item_fat">Fat :</label>
            <input type="number" className="form-control" placeholder={'Fat'} id="edit_category_item_fat"
                   value={fat} onChange={e => setFat(e.target.value)}/>
          </div>
        </div>

        {/* Description */}
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="edit_category_item_description">Description :</label>
            <input type="text" className="form-control" placeholder={'Optional'} id="edit_category_item_description"
                   value={description} onChange={e => setDescription(e.target.value)}/>
          </div>
        </div>

      </MediumModal>,
      document.getElementById('modal')
    )
  );
}

export default EditNutritionCategoryItemModal;
