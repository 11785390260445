import {
  VIMEO_VIDEOS_FETCH_RESPONSE_VIDEO, VIMEO_VIDEOS_GET_VIDEOS, VIMEO_VIDEOS_DELETE_VIDEO, VIMEO_VIDEOS_EDIT_VIDEO
} from "../actions/types";


const iniState = {
  current_page: 1,
  next_page_url: null,
  prev_page_url: null,
  videos: [],
  links: [],
}

export default function vimeoVideosReducer (state= iniState, action) {

  switch (action.type) {


    case VIMEO_VIDEOS_GET_VIDEOS:
      return {...state,
        videos: action.payload.data,
        current_page: action.payload.current_page,
        next_page_url: action.payload.next_page_url,
        prev_page_url: action.payload.prev_page_url,
        links: action.payload.links,
      };

    case VIMEO_VIDEOS_FETCH_RESPONSE_VIDEO:
      return {...state, videos: [action.payload, ...state.videos]};

    case VIMEO_VIDEOS_EDIT_VIDEO:
      let new_edited_videos = state.videos.map(video => {
        if (video.id !== action.payload.id)
          return video;
        else
          return action.payload;
      });
      return {...state, videos: [...new_edited_videos]};

    case VIMEO_VIDEOS_DELETE_VIDEO:
      let new_videos = state.videos.filter(video => video.id !== action.payload.id);
      return {...state, videos: [...new_videos]};

    default : return state;
  }
}
