import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {Button, FormControl, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTimes} from "@fortawesome/free-solid-svg-icons";
import Cleave from 'cleave.js/react';
import moment from 'moment';
import {clearEditingTimetable, editTimetableToClient} from "../../../actions/clientsActions";

const EditColClientTimetable = () => {
  const dispatch = useDispatch();
  const editing_timetable = useSelector(state => state.clients.current_client.editing_timetable);

  const [currData, setCurrData] = React.useState({
    id: '',
    time: '',
    date: '',
    workout: '',
    notes: '',
    is_done: false,
    is_paid: false
  });

  React.useEffect(() => {
    setCurrData(state => ({
      ...state,
      time: moment(editing_timetable.date).format('HH:mm'),
      date: moment(editing_timetable.date).format('DD/MM/YYYY'),
      workout: editing_timetable.workout,
      id: editing_timetable.id,
      is_paid: editing_timetable.is_paid,
      is_done: editing_timetable.is_done,
      notes: editing_timetable.notes
    }));
  }, [])


  const onSubmit = () => currData.id && dispatch(editTimetableToClient(currData));
  const onClear = () => currData.id && dispatch(clearEditingTimetable());

  return <tr>
    <td>
      <div className="input-group input-group-sm justify-content-center">
        <Cleave style={{maxWidth: '60px'}}
                options={{time: true, timePattern: ['h', 'm']}}
                className="form-control form-control-sm"
                placeholder="00:00"
                value={currData.time}
                onChange={(e) => {
                  setCurrData({...currData, time: e.target.value});
                }}
        />
        <div className="input-group-prepend">
          <span className="input-group-text">{" "}</span>
        </div>
        <Cleave style={{maxWidth: '100px'}}
                className="form-control form-control-sm"
                options={{date: true, datePattern: ['d', 'm', 'Y']}}
                placeholder="01/12/2020"
                value={currData.date}
                onChange={(e) => {
                  setCurrData({...currData, date: e.target.value});
                }}
        />
      </div>
    </td>
    <td>
      <FormControl placeholder="Workout title"
                   type="text" size="sm"
                   value={currData.workout}
                   onChange={(e) => {
                     setCurrData({...currData, workout: e.target.value});
                   }}
      />
    </td>
    <td>
      <FormControl placeholder="Notes"
                   value={currData.notes}
                   onChange={(e) => {
                     setCurrData({...currData, notes: e.target.value});
                   }}
                   type="text" size="sm"
      />
    </td>
    <td>
      <Form.Check type="checkbox"
                  checked={currData.is_done}
                  onChange={() => setCurrData({...currData, is_done: !currData.is_done})}
      />
    </td>
    <td>
      <Form.Check type="checkbox"
                  checked={currData.is_paid}
                  onChange={() => setCurrData({...currData, is_paid: !currData.is_paid})}
      />
    </td>
    <td>
      <Button onClick={onClear} variant="secondary" size={"sm"} className="py-0">
        <FontAwesomeIcon icon={faTimes}/>
      </Button> {" "}
      <Button onClick={onSubmit} variant="info" size={"sm"} className="py-0">
        <FontAwesomeIcon icon={faSave}/>
      </Button>
    </td>
  </tr>
}

export default EditColClientTimetable;
