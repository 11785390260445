import {
  CLIENTS_CLEAR_EDITING_STAT_ROW,
  CLIENTS_CLEAR_EDITING_TIMETABLE_ROW,
  CLIENTS_CLEAR_NEW_STAT_ROW,
  CLIENTS_CLEAR_NEW_TIMETABLE_ROW, CLIENTS_CURRENT_TIMETABLE_MODAL_CLOSE, CLIENTS_CURRENT_TIMETABLE_MODAL_OPEN,
  CLIENTS_DELETE_CLIENT_MODAL_CLOSE,
  CLIENTS_DELETE_CLIENT_MODAL_OPEN,
  CLIENTS_DELETE_IMAGE_MODAL_CLOSE, CLIENTS_DELETE_IMAGE_MODAL_OPEN,
  CLIENTS_DELETE_STAT_MODAL_CLOSE,
  CLIENTS_DELETE_STAT_MODAL_OPEN,
  CLIENTS_DELETE_TIMETABLE_MODAL_CLOSE,
  CLIENTS_DELETE_TIMETABLE_MODAL_OPEN,
  CLIENTS_GET_CLIENT,
  CLIENTS_GET_CLIENT_IMAGES,
  CLIENTS_GET_CLIENTS,
  CLIENTS_NEW_CLIENT_MODAL_CLOSE,
  CLIENTS_NEW_CLIENT_MODAL_OPEN,
  CLIENTS_PREPARE_EDITING_STAT_ROW,
  CLIENTS_PREPARE_EDITING_TIMETABLE_ROW,
  CLIENTS_RESET_CURRENT_CLIENT
} from "../actions/types";


const iniState = {
  new_client_modal_is_open: false,
  clients: [],
  current_client: {
    id: "",
    name: "",
    login: "",
    is_active: 0,
    personal_client_data: [],
    personal_client_timetable: [],
    workout_programs: [],
    is_delete_modal_open: false,


    is_new_timetable_row_clear: false,
    editing_timetable: {
      is_open: false,
      id: '',
      workout: '',
      notes: '',
      date: '',
      is_paid: false,
      is_done: false
    },
    delete_timetable: {
      is_open: false,
      id: ''
    },

    current_timetable: {
      is_open: false,
      data: []
    },

    is_new_stat_row_clear: false,
    editing_stat: {
      is_open: false,
      id: '',
      weight: '',
      height: '',
      muscle_mass: '',
      body_fat: '',
      bmi: '',
      visceral_fat: '',
      age: '',
      date: '',
    },
    delete_stat: {
      is_open: false,
      id: ''
    },

    personal_client_images: [],
    delete_image: { is_open: false, id: '' }
  }
}

const clientsReducer = (state= iniState, action) => {
  switch (action.type) {
    case CLIENTS_NEW_CLIENT_MODAL_OPEN:
      return {...state, new_client_modal_is_open: true }

    case CLIENTS_NEW_CLIENT_MODAL_CLOSE:
      return {...state, new_client_modal_is_open: false }

    case CLIENTS_GET_CLIENTS:
      return {...state, clients: action.payload};

    case CLIENTS_GET_CLIENT:
      return {...state, current_client: {...state.current_client, ...action.payload} };

    case CLIENTS_RESET_CURRENT_CLIENT:
      return {...state, current_client: {...state.current_client, ...iniState.current_client} };

    case CLIENTS_DELETE_CLIENT_MODAL_OPEN:
      return {...state, current_client: {...state.current_client, is_delete_modal_open: true} };

    case CLIENTS_DELETE_CLIENT_MODAL_CLOSE:
      return {...state, current_client: {...state.current_client, is_delete_modal_open: false} };



    case CLIENTS_CLEAR_NEW_TIMETABLE_ROW:
      return {...state, current_client: {...state.current_client, is_new_timetable_row_clear: !state.current_client.is_new_timetable_row_clear} };

    case CLIENTS_CLEAR_EDITING_TIMETABLE_ROW:
      return {...state, current_client: {...state.current_client, editing_timetable: iniState.current_client.editing_timetable} };

    case CLIENTS_PREPARE_EDITING_TIMETABLE_ROW:
      const row_id = parseInt(action.payload);

      let editable_row = state.current_client.personal_client_timetable.filter( e => e.id === row_id);

      if (editable_row.length)  editable_row = editable_row[0];
      else                      return state;

      return {...state, current_client: {...state.current_client, editing_timetable: {
            id: editable_row.id,
            is_open: true,
            date: editable_row.date,
            is_done: editable_row.is_done,
            is_paid: editable_row.is_paid,
            notes: editable_row.notes,
            workout: editable_row.workout
          }
      } };

    case CLIENTS_DELETE_TIMETABLE_MODAL_OPEN:
      return {...state, current_client: {...state.current_client, delete_timetable: {...state.current_client.delete_timetable, is_open: true, id: action.payload} } };

    case CLIENTS_DELETE_TIMETABLE_MODAL_CLOSE:
      return {...state, current_client: {...state.current_client, delete_timetable: {...state.current_client.delete_timetable, is_open: false, id: ''}} };



    case CLIENTS_CURRENT_TIMETABLE_MODAL_OPEN:
      return {...state, current_client: {...state.current_client, current_timetable: {...state.current_client.current_timetable, is_open: true, data: action.payload}} };

    case CLIENTS_CURRENT_TIMETABLE_MODAL_CLOSE:
      return {...state, current_client: {...state.current_client, current_timetable: {...state.current_client.current_timetable, is_open: false}} };




    case CLIENTS_CLEAR_NEW_STAT_ROW:
      return {...state, current_client: {...state.current_client, is_new_stat_row_clear: !state.current_client.is_new_stat_row_clear} };

    case CLIENTS_CLEAR_EDITING_STAT_ROW:
      return {...state, current_client: {...state.current_client, editing_stat: iniState.current_client.editing_stat} };

    case CLIENTS_PREPARE_EDITING_STAT_ROW:
      let row_id_2 = parseInt(action.payload);

      let editable_row_2 = state.current_client.personal_client_data.filter( e => e.id === row_id_2);

      if (editable_row_2.length)  editable_row_2 = editable_row_2[0];
      else                      return state;

      return {...state, current_client: {...state.current_client, editing_stat: {
            is_open: true,
            id: editable_row_2.id,
            weight: editable_row_2.weight,
            height: editable_row_2.height,
            muscle_mass: editable_row_2.muscle_mass,
            body_fat: editable_row_2.body_fat,
            bmi: editable_row_2.bmi,
            visceral_fat: editable_row_2.visceral_fat,
            age: editable_row_2.age,
            date: editable_row_2.date,

      }}};

    case CLIENTS_DELETE_STAT_MODAL_OPEN:
      return {...state, current_client: {...state.current_client, delete_stat: {is_open: true, id: action.payload} } };

    case CLIENTS_DELETE_STAT_MODAL_CLOSE:
      return {...state, current_client: {...state.current_client, delete_stat: {is_open: false, id: ''}} };

    case CLIENTS_GET_CLIENT_IMAGES:
      return {...state, current_client: {...state.current_client, personal_client_images: action.payload} };

    case CLIENTS_DELETE_IMAGE_MODAL_OPEN:
      return {...state, current_client: {...state.current_client, delete_image: {is_open: true, id: action.payload} } };

    case CLIENTS_DELETE_IMAGE_MODAL_CLOSE:
      return {...state, current_client: {...state.current_client, delete_image: {is_open: false, id: ''}} };

    default : return state;
  }
}

export default clientsReducer;
