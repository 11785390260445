import React from 'react';
import styled from "styled-components";
import {websiteName} from "../constants";
import QuestionContainer from "../components/Questions/QuestionContainer";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleLeft, faAngleRight} from "@fortawesome/free-solid-svg-icons";
import {
  getQuestionsAction,
  getQuestionsNextPageAction,
  getQuestionsPreviousPageAction
} from "../actions/questionsActions";
import {useDispatch, useSelector} from "react-redux";

const Questions = () => {

  const questions = useSelector(state => state.questions);
  const dispatch = useDispatch();

  React.useEffect(() => {
    document.title = 'Questions' + websiteName
  }, []);

  React.useEffect(() => {
    dispatch(getQuestionsAction())
  }, [questions.current_page])

  return <StyledQuestions>
    <h4>Questions</h4>
    <div className="container-fluid p-0">
      <div className="row">
        {
          questions.data.length
            ? questions.data.map(q => <QuestionContainer key={"q_"+q.id} data={q}/>)
            : <div className="col-12"><h2 className="text-center">Questions not found yet</h2></div>
        }
      </div>

      <div className="pagination justify-content-between">
        <div>Questions: {questions.total}</div>
        <div>
          <button className="btn btn-outline-light btn-sm py-0"
                  onClick={() => dispatch(getQuestionsPreviousPageAction())}>
            <FontAwesomeIcon icon={faAngleLeft}/>
          </button>
          <span className="d-inline-block px-3">{questions.current_page}</span>
          <button className="btn btn-outline-light btn-sm py-0" onClick={() => dispatch(getQuestionsNextPageAction())}>
            <FontAwesomeIcon icon={faAngleRight}/>
          </button>
        </div>

      </div>
    </div>

  </StyledQuestions>;
};

const StyledQuestions = styled.div`
  min-height: ${window.innerHeight - 155}px;
  
  a{
    background: #1B1C1E;
    border-radius: 5px;
    padding: 5px 10px;
    margin:0 10px;
  }  
`;

export default Questions;
