import React from 'react';
import styled from "styled-components";
import ConfirmModal from "../components/ConfirmModal";
import UserTableItem from "../components/Users/UserTableItem";
import {useDispatch, useSelector} from "react-redux";
import {
  deleteUser,
  generateMembership, getUserRealInfo,
  getUsersWithGivenPage, openEditUserModal,
  toggleMembership, toggleUserActiveness
} from "../actions/usersActions";

import {getUsersData} from '../actions/TwelveWeekActions'
import {websiteName} from "../constants";
import NewUserModal from "../components/Users/NewUserModal";
import EditUserModal from "../components/Users/EditUserModal";
import InfoUserModal from "../components/Users/InfoUserModal";
import TwelveWeekUserTableItem from "../components/Users/TwelveWeekUserTableItem";


const TwelveWeekProgram = () => {
  const users = useSelector(state => state.twelveWeekUsers);
  const dispatch = useDispatch();

  const [isDeleteModal, setIsDeleteModal] = React.useState({state: false, id: null});
  const [pages, setPages] = React.useState({data: []});

  React.useEffect(() => {
    document.title = '12-W Users' + websiteName;
    dispatch(getUsersData());

  }, [users.current_page]);

  React.useEffect(() => {
    let temp = [];
    for(let i=1; i<=users.last_page; i++){
      let tt = <button key={"ff"+i} className="btn btn-outline-light btn-sm py-0 mr-1" onClick={ () => { dispatch(getUsersWithGivenPage(i)) }}>{i}</button>;
      temp.push(tt);
    }
    setPages({...pages, data: temp});

  }, [users.current_page, users.last_page]);



  return <StyledUsers>
    {/* New user modal start */}
    {(users.is_new_user_modal_open) ? <NewUserModal /> : null}
    {/* New user modal end   */}

    {/* Edit user modal start */}
    {(users.is_edit_user_modal_open.state) ? <EditUserModal /> : null}
    {/* Edit user modal end   */}

    {/* Info user modal start */}
    {(users.current_user_info.state) ? <InfoUserModal /> : null}
    {/* Info user modal end   */}


    {/* User delete modal start */}
    {
      (isDeleteModal.state)
        ? <ConfirmModal
          body={'Are you really sure you want to delete user?'}
          onCancel={ () => { setIsDeleteModal({state: false, id: null}) } }
          onSuccess={ () => { dispatch(deleteUser(isDeleteModal.id)); setIsDeleteModal({state: false, id: null}) } }
        /> : null
    }
    {/* User delete modal end   */}

    {/* New user add button start */}
    <h4>12-W clients{' '}
      {/*<button className="btn btn-primary btn-sm" onClick={() => dispatch(openNewUserModal())}>*/}
      {/*  <FontAwesomeIcon icon={faPlus}/>*/}
      {/*</button>*/}
    </h4>
    {/* New user add button end   */}

    <div className="container-fluid p-0">
      <div className="table-responsive">
        <table className="table table-bordered table-sm table-hover table-dark">
          <thead key={'thead'}>
          <tr className="text-center">
            <th>#</th>
            <th>Name</th>
            <th>Age</th>
            <th>Gender</th>
            <th>Country</th>
            <th>Email</th>
            <th>Whatsapp</th>
            <th>Note</th>
            <th>Timestamp</th>
          </tr>
          </thead>

          <tbody key={'tbody'}>
          {
            (users.data.length > 0)
              ? users.data.map((each, index) =>
                <TwelveWeekUserTableItem key={each.id} ind={index + 1} data={each}/>)
              : <tr>
                <td colSpan={9}>No users found</td>
              </tr>
          }


          </tbody>

          <tfoot key={'tfoot'}>
          <tr>
            <td colSpan="9">
              <div className="row">
                <div className="col d-flex align-items-center">
                  Overall users: {users.total}
                </div>
                <div className="col text-right">
                  { pages.data }
                </div>
              </div>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>

    </div>

  </StyledUsers>;
};


const StyledUsers = styled.div`
  min-height: ${window.innerHeight - 155}px;

  table{
    font-size: 14px;
    thead, tfoot{
      font-size: 11px;
      font-family: MontserratBold, sans-serif;
      td, th {
        padding: 10px;
      }

    }

    tbody{
      td{
        text-align: center;
      }
    }
  }
`;

export default TwelveWeekProgram;
