import {parseJwt} from "../scripts";
import { ADMIN_SIGN_IN, ADMIN_SIGN_OUT } from "../actions/types";


const defaultState = {
  email: '',
  token: null
}

try{
  const token = window.localStorage.getItem('atoken');
  let parsedToken = (token != null && token !== "") ? parseJwt(token) : null;

  if (parsedToken != null && 'email' in parsedToken){
    defaultState.email = parsedToken.email;
    defaultState.token = token;
  }

}catch (e) {  }

const authReducer = (state= defaultState, action) => {
  switch (action.type) {
    case ADMIN_SIGN_IN :
      const signedInState = {...state};
      const signedInToken = action.payload;
      let parsedSignInToken = (signedInToken != null && signedInToken !== "") ? parseJwt(signedInToken) : null;

      if (parsedSignInToken != null && 'email' in parsedSignInToken){
        signedInState.email = parsedSignInToken.email;
        signedInState.token = signedInToken;

        window.localStorage.setItem('atoken', signedInToken);
      }
      return signedInState;


    case ADMIN_SIGN_OUT :
      window.localStorage.removeItem('atoken');
      return  {...state, email: '', token: null};

    default : return state;
  }
}

export default authReducer;
