import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faCheckDouble} from "@fortawesome/free-solid-svg-icons";


const MessageContainer = ({data, name}) => {
  return (
    <div className={`msg-container ${data.is_msg_from_admin ? 'is-admin' : ""}`}>
      <div className="msg">
        <div className="msg-author mb-1">{data.is_msg_from_admin ? 'You' : name}</div>
        <div className="msg-body mb-1">{data.question}</div>
        <div className="msg-info text-right">
          {
            data.is_msg_from_admin
              ? <FontAwesomeIcon icon={data.status ? faCheckDouble : faCheck }/>
              : null
          }
          <span className="pl-2">{data.created_at}</span>
        </div>
      </div>
    </div>
  );
}

export default MessageContainer;
