import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useDispatch} from "react-redux";
import {
  deActivateWorkoutExercise, deleteWorkoutExercise, openEditWorkoutExerciseModal,
} from "../../actions/workoutsActions";
import ConfirmModal from "../ConfirmModal";

const WorkoutExerciseContainer = ({data}) => {

  const dispatch = useDispatch();

  const [isDeleteModal, setIsDeleteModal] = React.useState({state: false, id: null});

  return (
    <div className={`exercise ${data.is_active ? '' : 'not-seen'}`}>
      <div className="exercise-title"><a href="#edit" onClick={e => {e.stopPropagation(); dispatch(openEditWorkoutExerciseModal(data.id, data.workout_id)) }}>
        {
          (data.title.length > 30)
          ? data.title.substr(0, 30)+"..."
          : data.title
        } ({data.views})
      </a></div>
      <div className="exercise-actions">
        <button className="btn btn-outline-light btn-sm" onClick={() => dispatch(deActivateWorkoutExercise(data.id))}>
          {
            (data.is_active)
             ? <FontAwesomeIcon icon={faEyeSlash} fixedWidth />
             : <FontAwesomeIcon icon={faEye} fixedWidth />
          }
        </button>
        <button className="btn btn-outline-danger btn-sm" onClick={() => setIsDeleteModal({state: true, id: data.id}) }>
          <FontAwesomeIcon icon={faTimes} fixedWidth />
        </button>
      </div>

      {/* Workout delete modal */}
      {
        (isDeleteModal.state)
          ? <ConfirmModal
            body={'Are you really sure you want to delete this exercise?'}
            onCancel={ () => { setIsDeleteModal({state: false, id: null}) } }
            onSuccess={ () => { dispatch(deleteWorkoutExercise(isDeleteModal.id)); setIsDeleteModal({state: false, id: null}) } }
          /> : null
      }
    </div>
  );
}

export default WorkoutExerciseContainer;
