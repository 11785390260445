import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {Button, Col, Container, Form, FormControl, Row} from "react-bootstrap";
import {deActivateClientMainData, editClientMainData, openDeleteClientModal} from "../../actions/clientsActions";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye, faEyeSlash, faSave, faTrash} from "@fortawesome/free-solid-svg-icons";

const ClientMainData = () => {
  const dispatch = useDispatch();
  const current_client = useSelector(state => state.clients.current_client);
  const [currData, setCurrData] = React.useState({id: '', name: '', login: '', password: '', is_personal: false, is_current: false});

  React.useEffect(() => {
    setCurrData({
      ...currData,
      id: current_client.id,
      name: current_client.name,
      login: current_client.login,
      is_personal: current_client.is_personal,
      is_current: current_client.is_current,
    });
  }, [setCurrData, current_client]);

  const onSave = () => currData.id && dispatch(editClientMainData(currData));
  const onToggle = () => currData.id && dispatch(deActivateClientMainData(currData.id));
  const onDelete = () => currData.id && dispatch(openDeleteClientModal());

  return <>
    <h5>Personal client data</h5>
    <Container fluid className="px-0">
      <Row>
        <Col>
          <FormControl type="string" value={currData.name} onChange={(e) => setCurrData({...currData, name: e.target.value})} placeholder="Name" />
        </Col>
        <Col>
          <FormControl type="string" value={currData.login} onChange={(e) => setCurrData({...currData, login: e.target.value})} placeholder="Login" />
        </Col>
        <Col>
          <FormControl type="password" autoComplete="new-password" value={currData.password} onChange={(e) => setCurrData({...currData, password: e.target.value})} placeholder="Password" />
        </Col>
        <Col>
          <Form.Check type="checkbox"
                      label={"Is PC?"}
                      checked={currData.is_personal}
                      onChange={() => setCurrData({...currData, is_personal: !currData.is_personal})}
          />
          <Form.Check type="checkbox"
                      label={"Is Current?"}
                      checked={currData.is_current}
                      onChange={() => setCurrData({...currData, is_current: !currData.is_current})}
          />
        </Col>
        <Col>
          <Button variant="primary" onClick={onSave}><FontAwesomeIcon icon={faSave} /></Button>
          {" "}
          <Button variant={current_client.is_active ? "secondary" : "success"} onClick={onToggle}>
            {
              current_client.is_active
                ? <FontAwesomeIcon icon={faEyeSlash} />
                : <FontAwesomeIcon icon={faEye} />
            }
          </Button>
          {" "}
          <Button variant="danger" onClick={onDelete}><FontAwesomeIcon icon={faTrash} /></Button>
        </Col>
      </Row>
    </Container>
  </>;
}

export default ClientMainData;
