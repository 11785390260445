import {processLoadingEnd, processLoadingStart, processModalShow} from "./processActions";

import urls from "../connect/urls";
import {signOutAction} from "./authActions";

export const QUESTIONS_GET_ALL_MESSAGES = "QUESTIONS_GET_ALL_MESSAGES";
export const QUESTIONS_NEXT_PAGE = "QUESTIONS_NEXT_PAGE";
export const QUESTIONS_PREVIOUS_PAGE = "QUESTIONS_PREVIOUS_PAGE";

// Get all questions
export const getQuestionsAction = () => async (dispatch, getState, ibospirit) => {
  try {
    dispatch(processLoadingStart());

    const response = await ibospirit.post(`${urls.getQuestions}?page=${getState().questions.current_page}`, {},
      {headers: {'Authorization': 'Bearer ' + getState().auth.token}})
    dispatch({type: QUESTIONS_GET_ALL_MESSAGES, payload: response.data});
  } catch (e) {

    try {
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object") {
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key] + "\n");
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  } finally {
    dispatch(processLoadingEnd());
  }
}

// Go to next page
export const getQuestionsNextPageAction = () => ({type: QUESTIONS_NEXT_PAGE});

// Go to prev page
export const getQuestionsPreviousPageAction = () => ({type: QUESTIONS_PREVIOUS_PAGE});
