import {useDispatch, useSelector} from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import {Button, Modal} from "react-bootstrap";
import {closeDeleteStatModal, deleteStatClient} from "../../../actions/clientsActions";

const DeleteStatModal = () => {
  const dispatch = useDispatch();
  const delete_stat = useSelector(state => state.clients.current_client.delete_stat);

  return ReactDOM.createPortal(<React.Fragment>
    <Modal show={delete_stat.is_open} size={"sm"} onHide={() => dispatch(closeDeleteStatModal())}>
      <Modal.Header closeButton className="bg-dark">
        <Modal.Title>Delete stat</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark">
        <h5>Dou you really want to delete?</h5>
      </Modal.Body>
      <Modal.Footer className="bg-dark">
        <Button variant="danger" onClick={() => dispatch(deleteStatClient(delete_stat.id))}>Delete</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>, document.getElementById('modal'));
}

export default DeleteStatModal;
