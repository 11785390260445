import React from "react";
import ReactDOM from "react-dom";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SideMenu from "./SideMenu";
import MenuContent from "./MenuContent";


const Header = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);

  return (
    <div className="App-header">
      <div className="App-header__inner">
        {/* Logo start */}
        <div className="logo" />
        {/* Logo end   */}

        {/* Menu content start */}
        <MenuContent />
        {/* Menu content end   */}

        {/* Menu toggler start */}
        <div className="main-menu-bar" onClick={() => setToggleMenu(true)}>
          <FontAwesomeIcon icon={faBars} />
        </div>
        {/* Menu toggler end   */}

        {/* Menu toggles on mobile devices start */}
        {toggleMenu
          ? ReactDOM.createPortal(
              <SideMenu closeMenu={() => setToggleMenu(false)}>
                <MenuContent />
              </SideMenu>,
              document.getElementById("menu")
            )
          : null}
        {/* Menu toggles on mobile devices end   */}
      </div>
    </div>
  );
};

export default Header;
