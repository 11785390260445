import React from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import {useSelector} from "react-redux";
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

const Loading = () => {
  const process = useSelector(state => state.process);

  return (
    (process.isLoading)
      ? ReactDOM.createPortal(
      <>
        <StyledLoadingBackground/>
        <StyledLoading>
          <div className="m-container">
            <FontAwesomeIcon fixedWidth icon={faSpinner} spin />{' '}Loading...
          </div>
        </StyledLoading>
      </>,
      document.getElementById('loading')
      )
      : null
  );
}

const StyledLoadingBackground = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 1100; 
`;

const StyledLoading = styled.div`
  position: fixed;
  z-index: 1100;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  
  .m-container{
    background: #EC243C;
    padding: 15px 20px;
    border-radius: 10px;
    box-shadow: 0 0 30px #555;
    font-size: 16px;
    color: #f1f1f1;
  }
`;

export default Loading;
