import {processLoadingEnd, processLoadingStart, processModalShow} from "./processActions";

import urls from "../connect/urls";
import {signOutAction} from "./authActions";

/** Unit type stuff start */
export const NUTRITION_GET_NUTRITION_UNIT_TYPES = "NUTRITION_GET_NUTRITION_UNIT_TYPES";
export const NUTRITION_OPEN_MODAL_NEW_NUTRITION_UNIT_TYPE = "NUTRITION_OPEN_MODAL_NEW_NUTRITION_UNIT_TYPE";
export const NUTRITION_CLOSE_MODAL_NEW_NUTRITION_UNIT_TYPE = "NUTRITION_CLOSE_MODAL_NEW_NUTRITION_UNIT_TYPE";
export const NUTRITION_OPEN_MODAL_EDIT_NUTRITION_UNIT_TYPE = "NUTRITION_OPEN_MODAL_EDIT_NUTRITION_UNIT_TYPE";
export const NUTRITION_CLOSE_MODAL_EDIT_NUTRITION_UNIT_TYPE = "NUTRITION_CLOSE_MODAL_EDIT_NUTRITION_UNIT_TYPE";

// Get nutrition unit types
export const getNutritionUnitTypesAction = () => async (dispatch, getState, ibospirit) => {
  try {
    dispatch(processLoadingStart());

    const response = await ibospirit.post(urls.getNutritionUnitTypes, {},
      {headers: {'Authorization': 'Bearer ' + getState().auth.token}});

    dispatch({type: NUTRITION_GET_NUTRITION_UNIT_TYPES, payload: response.data});

  } catch (e) {
    try {
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object") {
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key] + "\n");
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  } finally {
    dispatch(processLoadingEnd());
  }
}


// Open new unit type modal
export const openNewNutritionUnitTypeAction = () => ({type: NUTRITION_OPEN_MODAL_NEW_NUTRITION_UNIT_TYPE});

// Close new unit type modal
export const closeNewNutritionUnitTypeAction = () => ({type: NUTRITION_CLOSE_MODAL_NEW_NUTRITION_UNIT_TYPE});

// Create unit type
export const createNutritionUnitTypeAction = (data) => async (dispatch, getState, ibospirit) => {
  try {
    // Start loading process
    dispatch(processLoadingStart());

    // Send data to create
    await ibospirit.post(urls.createNutritionUnitType, data,
      {headers: {'Authorization': 'Bearer ' + getState().auth.token}});

    // Close modal and get all unit types
    dispatch(closeNewNutritionUnitTypeAction());
    dispatch(getNutritionUnitTypesAction());

  } catch (e) {
    try {
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object") {
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key] + "\n");
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    } finally {
      dispatch(processLoadingEnd());
    }
  }
}



// Open edit unit type modal
export const openEditNutritionUnitTypeAction = (unit_id) => ({type: NUTRITION_OPEN_MODAL_EDIT_NUTRITION_UNIT_TYPE, payload: unit_id});

// Close edit unit type modal
export const closeEditNutritionUnitTypeAction = () => ({type: NUTRITION_CLOSE_MODAL_EDIT_NUTRITION_UNIT_TYPE});

// Edit unit type
export const editNutritionUnitTypeAction = (data) => async (dispatch, getState, ibospirit) => {
  try {
    // Start loading process
    dispatch(processLoadingStart());

    // Send data to edit
    await ibospirit.post(urls.editNutritionUnitType, data,
      {headers: {'Authorization': 'Bearer ' + getState().auth.token}});

    // Close modal and get all unit types
    dispatch(closeEditNutritionUnitTypeAction());
    dispatch(getNutritionUnitTypesAction());

  } catch (e) {
    try {
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object") {
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key] + "\n");
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    } finally {
      dispatch(processLoadingEnd());
    }
  }
}


// Delete unit type
export const deleteNutritionUnitTypeAction = (id) => async (dispatch, getState, ibospirit) => {
  try {
    // Start loading process
    dispatch(processLoadingStart());

    // Send data to delete
    await ibospirit.post(urls.deleteNutritionUnitType, {id},
      {headers: {'Authorization': 'Bearer ' + getState().auth.token}});

    // Get all unit types
    dispatch(getNutritionUnitTypesAction());

  } catch (e) {
    try {
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object") {
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key] + "\n");
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    } finally {
      dispatch(processLoadingEnd());
    }
  }
}

/** Unit type stuff end */

/** *************************************************************************************************** */

/** Category stuff start */
export const NUTRITION_GET_NUTRITION_CATEGORIES = "NUTRITION_GET_NUTRITION_CATEGORIES";
export const NUTRITION_OPEN_MODAL_NEW_NUTRITION_CATEGORY = "NUTRITION_OPEN_MODAL_NEW_NUTRITION_CATEGORY";
export const NUTRITION_CLOSE_MODAL_NEW_NUTRITION_CATEGORY = "NUTRITION_CLOSE_MODAL_NEW_NUTRITION_CATEGORY";
export const NUTRITION_OPEN_MODAL_EDIT_NUTRITION_CATEGORY = "NUTRITION_OPEN_MODAL_EDIT_NUTRITION_CATEGORY";
export const NUTRITION_CLOSE_MODAL_EDIT_NUTRITION_CATEGORY = "NUTRITION_CLOSE_MODAL_EDIT_NUTRITION_CATEGORY";

// Get nutrition categories
export const getNutritionCategoriesAction = () => async (dispatch, getState, ibospirit) => {
  try {
    dispatch(processLoadingStart());

    const response = await ibospirit.post(urls.getNutritionCategories, {},
      {headers: {'Authorization': 'Bearer ' + getState().auth.token}});

    dispatch({type: NUTRITION_GET_NUTRITION_CATEGORIES, payload: response.data});

  } catch (e) {
    try {
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object") {
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key] + "\n");
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  } finally {
    dispatch(processLoadingEnd());
  }
}


// Open new category modal
export const openNewNutritionCategoryAction = () => ({type: NUTRITION_OPEN_MODAL_NEW_NUTRITION_CATEGORY});

// Close new category modal
export const closeNewNutritionCategoryAction = () => ({type: NUTRITION_CLOSE_MODAL_NEW_NUTRITION_CATEGORY});

// Create category
export const createNutritionCategoryAction = (data) => async (dispatch, getState, ibospirit) => {
  try {
    // Start loading process
    dispatch(processLoadingStart());

    // Send data to create
    await ibospirit.post(urls.createNutritionCategory, data,
      {headers: {'Authorization': 'Bearer ' + getState().auth.token}});

    // Close modal and get all categories
    dispatch(closeNewNutritionCategoryAction());
    dispatch(getNutritionCategoriesAction());

  } catch (e) {
    try {
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object") {
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key] + "\n");
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    } finally {
      dispatch(processLoadingEnd());
    }
  }
}


// Open edit category modal
export const openEditNutritionCategoryAction = (category_id) => ({type: NUTRITION_OPEN_MODAL_EDIT_NUTRITION_CATEGORY, payload: category_id});

// Close edit category modal
export const closeEditNutritionCategoryAction = () => ({type: NUTRITION_CLOSE_MODAL_EDIT_NUTRITION_CATEGORY});

// Edit category
export const editNutritionCategoryAction = (data) => async (dispatch, getState, ibospirit) => {
  try {
    // Start loading process
    dispatch(processLoadingStart());

    // Send data to edit
    await ibospirit.post(urls.editNutritionCategory, data,
      {headers: {'Authorization': 'Bearer ' + getState().auth.token}});

    // Close modal and get all categories
    dispatch(closeEditNutritionCategoryAction());
    dispatch(getNutritionCategoriesAction());

  } catch (e) {
    try {
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object") {
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key] + "\n");
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    } finally {
      dispatch(processLoadingEnd());
    }
  }
}


// Delete category
export const deleteNutritionCategoryAction = (id) => async (dispatch, getState, ibospirit) => {
  try {
    // Start loading process
    dispatch(processLoadingStart());

    // Send data to delete
    await ibospirit.post(urls.deleteNutritionCategory, {id},
      {headers: {'Authorization': 'Bearer ' + getState().auth.token}});

    // Get all categories
    dispatch(getNutritionCategoriesAction());

  } catch (e) {
    try {
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object") {
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key] + "\n");
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    } finally {
      dispatch(processLoadingEnd());
    }
  }
}
/** Category stuff end */

/** *************************************************************************************************** */

/** Category items stuff start */
export const NUTRITION_GET_NUTRITION_CATEGORY_ITEMS = "NUTRITION_GET_NUTRITION_CATEGORY_ITEMS";
export const NUTRITION_OPEN_MODAL_NEW_NUTRITION_CATEGORY_ITEM = "NUTRITION_OPEN_MODAL_NEW_NUTRITION_CATEGORY_ITEM";
export const NUTRITION_CLOSE_MODAL_NEW_NUTRITION_CATEGORY_ITEM = "NUTRITION_CLOSE_MODAL_NEW_NUTRITION_CATEGORY_ITEM";
export const NUTRITION_OPEN_MODAL_EDIT_NUTRITION_CATEGORY_ITEM = "NUTRITION_OPEN_MODAL_EDIT_NUTRITION_CATEGORY_ITEM";
export const NUTRITION_CLOSE_MODAL_EDIT_NUTRITION_CATEGORY_ITEM = "NUTRITION_CLOSE_MODAL_EDIT_NUTRITION_CATEGORY_ITEM";

// Get nutrition items
export const getNutritionCategoryItemsAction = (category_id) => async (dispatch, getState, ibospirit) => {
  try {
    dispatch(processLoadingStart());

    const response = await ibospirit.post(urls.getNutritionCategoryItems, {id: category_id},
      {headers: {'Authorization': 'Bearer ' + getState().auth.token}});

    dispatch({type: NUTRITION_GET_NUTRITION_CATEGORY_ITEMS, payload: response.data});

  } catch (e) {
    try {
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object") {
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key] + "\n");
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  } finally {
    dispatch(processLoadingEnd());
  }
}


// Open new category item modal
export const openNewNutritionCategoryItemAction = () => ({type: NUTRITION_OPEN_MODAL_NEW_NUTRITION_CATEGORY_ITEM});

// Close new category item modal
export const closeNewNutritionCategoryItemAction = () => ({type: NUTRITION_CLOSE_MODAL_NEW_NUTRITION_CATEGORY_ITEM});

// Create category item
export const createNutritionCategoryItemAction = (data) => async (dispatch, getState, ibospirit) => {
  try {
    // Start loading process
    dispatch(processLoadingStart());

    // Send data to create
    await ibospirit.post(urls.createNutritionCategoryItem, data,
      {headers: {'Authorization': 'Bearer ' + getState().auth.token}});

    // Close modal and get all category items
    dispatch(closeNewNutritionCategoryItemAction());
    dispatch(getNutritionCategoryItemsAction(data.category_id));

  } catch (e) {
    try {
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object") {
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key] + "\n");
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    } finally {
      dispatch(processLoadingEnd());
    }
  }
}


// Open edit category item modal
export const openEditNutritionCategoryItemAction = (id) => ({type: NUTRITION_OPEN_MODAL_EDIT_NUTRITION_CATEGORY_ITEM, payload: id});

// Close edit category item modal
export const closeEditNutritionCategoryItemAction = () => ({type: NUTRITION_CLOSE_MODAL_EDIT_NUTRITION_CATEGORY_ITEM});

// Edit category item
export const editNutritionCategoryItemAction = (data) => async (dispatch, getState, ibospirit) => {
  try {
    // Start loading process
    dispatch(processLoadingStart());

    // Send data to create
    await ibospirit.post(urls.editNutritionCategoryItem, data,
      {headers: {'Authorization': 'Bearer ' + getState().auth.token}});

    // Close modal and get all category items
    dispatch(closeEditNutritionCategoryItemAction());
    dispatch(getNutritionCategoryItemsAction(data.category_id));

  } catch (e) {
    try {
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object") {
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key] + "\n");
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    } finally {
      dispatch(processLoadingEnd());
    }
  }
}


// Delete category item
export const deleteNutritionCategoryItemAction = (id, category_id) => async (dispatch, getState, ibospirit) => {
  try {
    // Start loading process
    dispatch(processLoadingStart());

    // Send data to delete
    await ibospirit.post(urls.deleteNutritionCategoryItem, {id},
      {headers: {'Authorization': 'Bearer ' + getState().auth.token}});

    // Get all category items
    dispatch(getNutritionCategoryItemsAction(category_id));

  } catch (e) {
    try {
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object") {
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key] + "\n");
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    } finally {
      dispatch(processLoadingEnd());
    }
  }
}
/** Category items stuff end */
