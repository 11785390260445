import React from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";

const MediumModal = ({ onClose, onSuccess, onSuccessText, title, children}) => {

  React.useEffect(() => {
    const escapeKeydownFire = e => { if (e.keyCode === 27) onClose(); }
    window.addEventListener('keydown', escapeKeydownFire);
    return () => { window.removeEventListener('keydown', escapeKeydownFire); }
  })

  return (
    ReactDOM.createPortal(
      <>
        <StyledMediumModalBackground/>
        <StyledMediumModal>
          <div className="m-container">
            <div className="m-title">{title}</div>

            <div className="m-body">
              <div className="row">
                { children }
              </div>
            </div>

            <div className="row">
              <div className="col">
                <button className="btn btn-outline-danger btn-block"
                  onClick={() => onClose()}>close</button>
              </div>
              <div className="col">
                <button className="btn btn-outline-success btn-block"
                  onClick={() => onSuccess() }>{onSuccessText}</button>
              </div>
            </div>
          </div>
        </StyledMediumModal>
      </>,
      document.getElementById('modal')
    )
  );
}

const StyledMediumModalBackground = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 1005; 
`;

const StyledMediumModal = styled.div`
  position: fixed;
  z-index: 1007;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  
  .m-container{
    max-height: ${window.innerHeight - 50}px;
    overflow-y: scroll;
    margin: 0 15px;
    min-width: 300px;
    width: 600px;
    background: #303030;
    padding: 20px 15px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px #444;
    
    height: auto;
    
    
    .m-title{
      font: 20px MontserratBold, sans-serif;
      text-transform:uppercase;
      text-align: center;
      margin-bottom: 15px;
    }
    
    .m-body{
      font-size: 14px;
      margin-bottom: 20px;
      
      p {margin-bottom: 5px}
    }
    
    
    button{
    
    }
  }
`;

export default MediumModal;
