import reducers from "./reducers";
import ibospirit from "./connect/ibospirit";
import { configureStore } from '@reduxjs/toolkit';
import pcWorkoutApi from "./actions/pcWorkoutApi";

const store = configureStore({
  reducer: reducers,

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: {extraArgument: ibospirit},
      immutableCheck: {warnAfter: 128},
      serializableCheck: {warnAfter: 128}
    })
    .concat(pcWorkoutApi.middleware),
});

export default store;
