import {useDispatch, useSelector} from "react-redux";
import React from "react";
import ImageUploader from "react-images-upload";
import {FormControl, Button, Container, Row, Col} from 'react-bootstrap';
import moment from "moment";
import Cleave from "cleave.js/react";
import {addImageToClient} from "../../../actions/clientsActions";

const ClientImageDropbox = () => {
  const dispatch = useDispatch();

  const images = useSelector(s => s.clients.current_client.personal_client_images);
  const [data, setData] = React.useState({
    notes: '',
    date: moment().format('DD/MM/YYYY'),
    image: ''
  });

  React.useEffect(() => {
    setData({notes: '', date: moment().format('DD/MM/YYYY'), image: ''});
    return () => {}
  }, [images.length]);

  const onAdd = () => dispatch(addImageToClient(data));
  const onDrop = (icon) => setData({...data, image: icon[0]});

  return <div className="d-flex align-content-center justify-content-center">
    <div className="w-50">
      <Container fluid className="px-0">
        <Row>
          <Col>
            <Cleave className="form-control"
                    options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
                    placeholder="01/12/2020"
                    value={data.date}
                    onChange={e => setData({...data, date: e.target.value})}/>
          </Col>
          <Col>
            <FormControl value={data.notes}
                         onChange={e => setData({...data, notes: e.target.value})}
                         placeholder={"Notes for image"}/>
          </Col>
        </Row>
      </Container>
      <ImageUploader singleImage={true} withPreview={true}
                     buttonText={"Drop client image"}
                     onChange={onDrop}
      />
      <div className="text-center">
        <Button variant="primary" onClick={onAdd}>Add image</Button>
      </div>
    </div>
  </div>;
}

export default ClientImageDropbox;
