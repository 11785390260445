import {useDispatch, useSelector} from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import {Button, Modal, Table} from "react-bootstrap";
import {closeTodayClientTimetableModal} from "../../../actions/clientsActions";
import moment from "moment";

const CurrentTimetableModal = () => {
  const dispatch = useDispatch();
  const current_timetable = useSelector(state => state.clients.current_client.current_timetable);

  return ReactDOM.createPortal(<React.Fragment>
    <Modal show={current_timetable.is_open} onHide={() => dispatch(closeTodayClientTimetableModal())}>
      <Modal.Header closeButton className="bg-dark">
        <Modal.Title>Current timetable</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark">
        <Table variant="dark" size={"sm"}>
          <thead>
          <tr>
            <th>Workout</th>
            <th>Date</th>
            <th>Payment</th>
            <th>Status</th>
          </tr>
          </thead>
          <tbody>
          {
            current_timetable.data.map(client => <>
              <tr className="bg-success" key={client.id}><td colSpan={4}>{client.name}</td></tr>
              {
                client.personal_client_timetable.map(timetable => <tr className={"small"} key={timetable.id}>
                  <td>{timetable.workout}</td>
                  <td>{moment(timetable.date).format('hh:mmA DD.MM.YYYY')}</td>
                  <td>{timetable.is_paid ? "paid" : "not paid"}</td>
                  <td>{timetable.is_done ? "finished" : "pending"}</td>
                </tr>)
              }
            </>)
          }
          </tbody>
        </Table>
      </Modal.Body>
      <Modal.Footer className="bg-dark">
        <Button variant="danger" onClick={() => dispatch(closeTodayClientTimetableModal())}>Close</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>, document.getElementById('modal'));
}

export default CurrentTimetableModal;
