import React from 'react';
import styled from "styled-components";
import {websiteName} from "../constants";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPaperPlane} from "@fortawesome/free-solid-svg-icons";
import MessageContainer from "../components/Questions/MessageContainer";
import {processLoadingEnd, processLoadingStart, processModalShow} from "../actions/processActions";
import urls from "../connect/urls";
import {signOutAction} from "../actions/authActions";
import {useDispatch, useSelector} from "react-redux";
import ibospirit from "../connect/ibospirit";
import history from "../history/history";

const Conversation = ({match: {params}}) => {
  const dispatch = useDispatch();
  const authToken = useSelector(s => s.auth.token);

  const is_workout = parseInt(params.is_workout);
  const user_id = parseInt(params.user_id);
  const exercise_id = parseInt(params.exercise_id);
  const [name, setName] = React.useState('');
  const [exerciseName, setExerciseName] = React.useState('');
  const [messages, setMessages] = React.useState([]);

  const messagesEndRef = React.useRef();
  const [msgInput, setMsgInput] = React.useState('');

  React.useEffect(() => {
    document.title = 'Messenger' + websiteName;
  }, []);

  // Send message handler
  const _sendMessageHandler = async () => {
    try {
      dispatch(processLoadingStart());

      await ibospirit.post(urls.sendMessageToUser, {message: msgInput, is_workout, user_id, exercise_id},
        {headers: {'Authorization': 'Bearer ' + authToken}});

      setMsgInput('');
      await _getFullConversationHandler();

    } catch (e) {
      try {
        const status = parseInt(e.response.status);
        switch (status) {
          // If input data has error
          case 400:
            if (typeof e.response.data == "object") {
              const data = e.response.data;
              let errorText = "";
              Object.keys(data).forEach(key => errorText += data[key] + "\n");
              dispatch(processModalShow(errorText))
            }
            break;

          // If token expired
          case 401:
            dispatch(processModalShow(e.response.data));
            dispatch(signOutAction());
            break;

          // If made many requests
          case 419:
            dispatch(processModalShow('Too many request try again later'));
            break;

          // If none matches
          default:
            dispatch(processModalShow());
        }
      } catch (e) {
        dispatch(processModalShow('Network error. Check internet connection'));
      } finally {
        dispatch(processLoadingEnd());
      }
    }
  }

  // Get messages handler
  const _getFullConversationHandler = async () => {
    try {
      dispatch(processLoadingStart());

      const response = await ibospirit.post(urls.getFullConversation, {is_workout, user_id, exercise_id},
        {headers: {'Authorization': 'Bearer ' + authToken}});

      const {data: messages, user_name, exercise_name} = response.data;

      if (messages.length == 0)
        history.push('/admin/questions');

      setExerciseName(exercise_name);
      setMessages(messages);
      setName(user_name);

    } catch (e) {
      try {
        const status = parseInt(e.response.status);
        switch (status) {
          // If input data has error
          case 400:
            if (typeof e.response.data == "object") {
              const data = e.response.data;
              let errorText = "";
              Object.keys(data).forEach(key => errorText += data[key] + "\n");
              dispatch(processModalShow(errorText))
            }
            break;

          // If token expired
          case 401:
            dispatch(processModalShow(e.response.data));
            dispatch(signOutAction());
            break;

          // If made many requests
          case 419:
            dispatch(processModalShow('Too many request try again later'));
            break;

          // If none matches
          default:
            dispatch(processModalShow());
        }

      } catch (e) {
        dispatch(processModalShow('Network error. Check internet connection'));
      }
    } finally {
      dispatch(processLoadingEnd());
    }
  }

  React.useEffect(() => {
    _getFullConversationHandler();
  }, []);

  React.useEffect(() => {
    messagesEndRef.current.scrollIntoView({ behavior: 'smooth' })
  }, [messages]);

  const _keyPressHandler = async (e) => {
    if(e.keyCode === 13)
      await _sendMessageHandler();
  }

  return <StyledConversation>
    <div className="container-fluid p-0">
      <div className="message-title px-2 py-3 bg-dark mb-3">{is_workout ? 'Workout' : "Program"} exercise: {exerciseName}</div>
      <div className="messages">
        {messages.map(m => <MessageContainer key={'msg_' + m.id} name={name} data={m}/>)}
        <div className="end" ref={messagesEndRef} />
      </div>
      <div className="messenger">
        <input type="text" onKeyDown={_keyPressHandler} placeholder={"Your message is here ..."} value={msgInput} onChange={e => setMsgInput(e.target.value)} className="form-control"/>
        <button className="btn btn-danger" onClick={_sendMessageHandler}><FontAwesomeIcon icon={faPaperPlane}/></button>
      </div>
    </div>
  </StyledConversation>;
};

const StyledConversation = styled.div`
  min-height: ${window.innerHeight - 155}px;
  
  .container-fluid{
    display: flex;
    flex-direction: column;
    min-height: ${window.innerHeight - 165}px;
  }
  
  .messages{
    height: ${window.innerHeight - 260}px;
    overflow-y: scroll;
    
    .end{ height: 40px; }
    .msg-container{
      display: flex;
      margin-bottom: 15px;
      &.is-admin{ 
        justify-content: flex-end;
        .msg{ background: #EC243C; }
      } 
      .msg{
        box-shadow: 0 0 5px #000;
        flex: 0.7;
        background: #1B1C1E;
        padding: 10px;
        border-radius: 5px;
        &-author{font: 11px MontserratBold, sans-serif; text-shadow: 1px 1px 0 #222}
        &-body{ font: 15px MontserratItalic, sans-serif; padding-left: 10px; text-shadow: 1px 1px 0 #222 }
        &-info{font-size: 11px; text-shadow: 1px 1px 0 #222}
      }
    }
  }
  
  .messenger{
    position: relative;
    input{
    padding-right: 45px;
    }
    .btn{
      position: absolute;
      top: 0;
      right: 0;
    }
  }
`;

export default Conversation;
