import {
  USERS_CLOSE_EDIT_USER_MODAL, USERS_CLOSE_INFO_USER_MODAL,
  USERS_CLOSE_NEW_USER_MODAL,
  USERS_GET_ALL_USERS,
  USERS_GET_USER_INFO_DATA, USERS_GIVEN_PAGE,
  USERS_NEXT_PAGE, USERS_OPEN_EDIT_USER_MODAL, USERS_OPEN_NEW_USER_MODAL,
  USERS_PREVIOUS_PAGE,
} from "../actions/types";


const defaultState = {
  data: [],
  current_page: 1,
  last_page: 1,
  total: 0,
  current_user_info: { state: false, data: {}},
  is_new_user_modal_open: false,
  is_edit_user_modal_open: {state: false, id: null}
}

const usersReducer = (state = defaultState, action) => {
  switch (action.type) {

    case USERS_GET_ALL_USERS :
      const {data, current_page, total, last_page} = action.payload;
      return {...state, data, current_page, total, last_page};

    case USERS_NEXT_PAGE :
      return (state.current_page < state.last_page)
        ? {...state, current_page: state.current_page + 1} : state;

    case USERS_PREVIOUS_PAGE :
      return (state.current_page > 1)
        ? {...state, current_page: state.current_page - 1} : state;

    case USERS_GIVEN_PAGE :
      return {...state, current_page: action.payload };


    case USERS_OPEN_NEW_USER_MODAL :
      return {...state, is_new_user_modal_open: true};

    case USERS_CLOSE_NEW_USER_MODAL :
      return {...state, is_new_user_modal_open: false};


    case USERS_OPEN_EDIT_USER_MODAL :
      return {...state, is_edit_user_modal_open: {state: true, id: action.payload}};

    case USERS_CLOSE_EDIT_USER_MODAL :
      return {...state, is_edit_user_modal_open: {state: false, id: null}};


    case USERS_CLOSE_INFO_USER_MODAL :
      return {...state, current_user_info: {state: false, data: {}}};

    case USERS_GET_USER_INFO_DATA :
      return {...state, current_user_info: {state: true, data: action.payload} };

    default :
      return state;
  }
}

export default usersReducer;
