import React from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";

const ConfirmModal = ({ onSuccess, onCancel, body, title = "Confirm"}) => {
  return (
    ReactDOM.createPortal(
      <>
        <ConfirmModalBackground/>
        <StyledConfirmModal>
          <div className="m-container">
            <div className="m-title">{ title }</div>

            <div className="m-body">{body}</div>

            <div className="row">
              <div className="col">
                <button className="btn btn-outline-success btn-block" onClick={onSuccess}>Yes</button>
              </div>
              <div className="col">
                <button className="btn btn-danger btn-block" onClick={onCancel}>No</button>
              </div>
            </div>

          </div>
        </StyledConfirmModal>
      </>,
      document.getElementById('modal')
    )
  );
}

const ConfirmModalBackground = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 1005; 
`;

const StyledConfirmModal = styled.div`
  position: fixed;
  z-index: 1007;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  
  .m-container{
    width: 300px;
    background: #303030;
    padding: 20px 15px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px #444;
    
    .m-title{
      font: 20px MontserratBold, sans-serif;
      text-transform:uppercase;
      text-align: center;
      margin-bottom: 15px;
    }
    
    .m-body{
      font-size: 14px;
      margin-bottom: 20px;
      text-align: center;
      
      p {margin-bottom: 5px}
    }
    
    
    button{
    
    }
  }
`;

export default ConfirmModal;
