import React from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {websiteName} from "../constants";
import {
  deleteNutritionCategoryItemAction,
  getNutritionCategoryItemsAction,
  openEditNutritionCategoryItemAction,
  openNewNutritionCategoryItemAction,
} from "../actions/nutritionActions";
import ConfirmModal from "../components/ConfirmModal";
import history from "../history/history";
import NewNutritionCategoryItemModal from "../components/Nutrition/NewNutritionCategoryItemModal";
import EditNutritionCategoryItemModal from "../components/Nutrition/EditNutritionCategoryItemModal";


const NutritionItems = ({match}) => {
  const nutrition = useSelector(state => state.nutrition);
  const dispatch = useDispatch();

  const [header, setHeader] = React.useState('');
  const [isDeleteModalCategoryItem, setIsDeleteModalCategoryItem] = React.useState({state: false, id: null});

  React.useEffect(() => {
    const category_id = parseInt(match.params.category_id);

    const found = nutrition.categories.filter(ec => ec.id === category_id);
    if (! found.length)
      history.push('/admin/nutrition')

    document.title = 'Nutrition items: ' + found[0].title + websiteName;
    setHeader(found[0].title);

    dispatch(getNutritionCategoryItemsAction(category_id));
  }, [])

  return <StyledNutritionItems>
    {/* New category item modal start */}
    {(nutrition.is_new_category_item_modal_open) ? <NewNutritionCategoryItemModal category_id={match.params.category_id} /> : null}
    {/* New category item modal end   */}

    {/* Edit category item modal start */}
    {(nutrition.is_edit_category_item_modal_open.state) ? <EditNutritionCategoryItemModal category_id={match.params.category_id}  /> : null}
    {/* Edit category item modal end   */}

    {/* Category item delete modal start */}
    {
      (isDeleteModalCategoryItem.state)
        ? <ConfirmModal
          body={'Are you really sure you want to delete this item?'}
          onCancel={ () => { setIsDeleteModalCategoryItem({state: false, id: null}) } }
          onSuccess={ () => { dispatch(deleteNutritionCategoryItemAction(isDeleteModalCategoryItem.id, match.params.category_id)); setIsDeleteModalCategoryItem({state: false, id: null}) } }
        /> : null
    }
    {/* Category item delete modal end */}

    {/* New category item button start */}
    <h4>
      Nutrition items: {header} {''}
      <button className="btn btn-primary btn-sm px-1 py-0"
              onClick={() => dispatch(openNewNutritionCategoryItemAction())}>
        <FontAwesomeIcon icon={faPlus}/>
      </button>
    </h4>
    {/* New category item button end */}

    <div className="container-fluid p-0">
      <div className="row">
        <div className="col-12">
          <div className="table-responsive">
            <table className="table table-sm table-dark">
              <thead>
              <tr className="text-center small">
                <th>#</th>
                <th>Title</th>
                <th>Description</th>
                <th>Quantity</th>
                <th>Calorie</th>
                <th>Carbs</th>
                <th>Protein</th>
                <th>Fat</th>
                <th>{' '}</th>
              </tr>
              </thead>
              <tbody>
              {
                nutrition.category_items.length
                  ? nutrition.category_items.map((ec, i) => {
                    return <tr className="text-center" key={"ccci"+ec.id}>
                      <td>{i+1}</td>
                      <td>{ec.title}</td>
                      <td>{ec.description}</td>
                      <td>{ec.quantity + " " + ec.unit_type_data.title}</td>
                      <td>{ec.calorie}</td>
                      <td>{ec.carbs}</td>
                      <td>{ec.protein}</td>
                      <td>{ec.fat}</td>
                      <td>
                        <button className="btn btn-outline-primary btn-sm px-1 py-0 ml-1"
                                onClick={() => dispatch(openEditNutritionCategoryItemAction(ec.id))}
                        ><FontAwesomeIcon fixedWidth icon={faEdit} />
                        </button>
                        <button className="btn btn-outline-danger btn-sm px-1 py-0 ml-1"
                                onClick={() => setIsDeleteModalCategoryItem({id: ec.id, state: true})}
                        ><FontAwesomeIcon fixedWidth icon={faTimes} />
                        </button>
                      </td>
                    </tr>
                  })
                  : <tr className="text-center"><td colSpan={9}>Not found</td></tr>
              }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

  </StyledNutritionItems>;
};


const StyledNutritionItems = styled.div`
  min-height: ${window.innerHeight - 155}px;
  
  .unit-types-container{
    border-radius: 5px;
    border: 1px solid #777;
    padding: 10px;
    margin-bottom: 30px;
    background: #404040;
    box-shadow: 0 0 10px #444;
  }  
`;

export default NutritionItems;
