import {
  PROCESS_MODAL_CLOSE, PROCESS_MODAL_SHOW, PROCESS_LOADING_END, PROCESS_LOADING_START, PROCESS_MODAL_SHOW_WITH_TITLE
} from "../actions/types";


const defaultState = {
  isLoading: false,
  modalIsShown: false,
  modalError: '',
  modalTitle: 'Error',
}

const processReducer = (state= defaultState, action) => {
  switch (action.type) {

    case PROCESS_LOADING_START :
      return {...state, isLoading: true};

    case PROCESS_LOADING_END :
      return {...state, isLoading: false };

    case PROCESS_MODAL_SHOW :
      return {...state, modalIsShown: true, modalTitle: 'error', modalError: action.payload || "Server error, try again later" };

    case PROCESS_MODAL_SHOW_WITH_TITLE :
      return {...state, modalIsShown: true, modalTitle: action.payload.title, modalError: action.payload.body || "Server error, try again later" };

    case PROCESS_MODAL_CLOSE :
      return {...state, modalIsShown: false};

    default : return state;
  }
}

export default processReducer;
