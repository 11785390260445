import {useDispatch} from "react-redux";
import React from "react";
import {FormControl, Button, Image} from 'react-bootstrap';
import moment from "moment";
import Cleave from "cleave.js/react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faTimes} from "@fortawesome/free-solid-svg-icons";
import {editImageToClient, openDeleteClientImageModal} from "../../../actions/clientsActions";

const ClientImageContainer = ({data: d}) => {
  const dispatch = useDispatch();

  const [data, setData] = React.useState({
    id: '',
    notes: '',
    date: '',
  });

  React.useEffect(() => {
    setData({id: d.id, notes: d.notes, date: moment(d.date).format('DD/MM/YYYY')})
  }, []);

  const onSubmit = () => dispatch(editImageToClient(data));
  const onDelete = () => dispatch(openDeleteClientImageModal(d.id));

  // const source = "http://ibospirit.loc.192.168.1.205.nip.io";
  const source = "https://backend.ibospirit.com";
  return <>
    <div className="p-2 text-center mr-2">

      <Image style={{height: '200px', paddingBottom: '5px'}} rounded src={source+d.image} />

      <Cleave className="form-control form-control-sm mb-2"
              options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
              placeholder="01/12/2020"
              value={data.date}
              onChange={e => setData({...data, date: e.target.value})}
      />

      <FormControl size={'sm'}
                   placeholder={"Notes for image"}
                   value={data.notes}
                   onChange={e => setData({...data, notes: e.target.value})}
      />

      <div className="d-flex justify-content-around pt-2">
        <Button variant="primary" onClick={onSubmit} size="sm"><FontAwesomeIcon icon={faEdit} fixedWidth /></Button>
        {/*<Button variant="info" onClick={onUpdate} size="sm"><FontAwesomeIcon icon={faSyncAlt} fixedWidth /></Button>*/}
        <Button variant="danger" onClick={onDelete} size="sm"><FontAwesomeIcon icon={faTimes} fixedWidth /></Button>
      </div>
    </div>
  </>;
}

export default ClientImageContainer;
