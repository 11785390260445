import React, {useState} from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import {useDispatch} from "react-redux";
import {closeNewFaqModal, createFaq} from "../../actions/faqsActions";

const NewFaqModal = () => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');


  return (
    ReactDOM.createPortal(
      <>
        <StyledNewFaqModalBackground/>
        <StyledNewFaqModal>
          <div className="m-container">
            <div className="m-title">add new Faq</div>

            <div className="m-body">
              <div className="row">

                {/* Title */}
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="new_faq_title">Title <span className="text-danger">*</span> :</label>
                    <input type="text" className="form-control" placeholder={'FAQ question title'} id="new_faq_title"
                           value={title} onChange={e => setTitle(e.target.value)}/>
                  </div>
                </div>

                {/* Description */}
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="new_faq_description">Description <span className="text-danger">*</span> :</label>
                    <textarea id="new_faq_description" rows="7" className="form-control"
                              onChange={e => setDescription(e.target.value)} value={description}/>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <button
                  className="btn btn-outline-danger btn-block"
                  onClick={() => dispatch(closeNewFaqModal())}>Cancel
                </button>
              </div>
              <div className="col">
                <button
                  className="btn btn-outline-success btn-block"
                  onClick={() => dispatch(createFaq({title, description}))}>Add
                </button>
              </div>
            </div>
          </div>
        </StyledNewFaqModal>
      </>,
      document.getElementById('modal')
    )
  );
}

const StyledNewFaqModalBackground = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 1005; 
`;

const StyledNewFaqModal = styled.div`
  position: fixed;
  z-index: 1007;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  
  .m-container{
    max-height: ${window.innerHeight - 50}px;
    overflow-y: scroll;
    margin: 0 15px;
    min-width: 300px;
    width: 600px;
    background: #303030;
    padding: 20px 15px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px #444;
    
    height: auto;
    
    
    .m-title{
      font: 20px MontserratBold, sans-serif;
      text-transform:uppercase;
      text-align: center;
      margin-bottom: 15px;
    }
    
    .m-body{
      font-size: 14px;
      margin-bottom: 20px;
      
      p {margin-bottom: 5px}
    }
    
    
    button{
    
    }
  }
`;

export default NewFaqModal;
