import authReducer from "./authReducer";
import processReducer from "./processReducer";
import usersReducer from "./usersReducer";
import webHooksReducer from "./webHooksReducer";
import faqsReducer from "./faqsReducer";
import workoutsReducer from "./workoutsReducer";
import programsReducer from "./programsReducer";
import questionsReducer from "./questionsReducer";
import nutritionReducer from "./nutritionReducer";
import clientsReducer from "./clientsReducer";
import twelveWeekUsersReducer from "./twelveWeekIUsersReducer";
import vimeoVideosReducer from "./vimeoVideosReducer";
import pcWorkoutApi from "../actions/pcWorkoutApi";

const reducers = {
  vimeoVideos: vimeoVideosReducer,
  clients: clientsReducer,
  auth: authReducer,
  process: processReducer,
  users: usersReducer,
  twelveWeekUsers: twelveWeekUsersReducer,
  webHooks: webHooksReducer,
  faqs: faqsReducer,
  workouts: workoutsReducer,
  programs: programsReducer,
  questions: questionsReducer,
  nutrition: nutritionReducer,
  [pcWorkoutApi.reducerPath]: pcWorkoutApi.reducer,
};

export default reducers;

