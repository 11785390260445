import React, {useState} from "react";
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from "react-redux";
import {closeNewProgramExerciseModal, createProgramExercise} from "../../../../actions/programsActions";
import MediumModal from "../../../MediumModal";
import {processModalShow} from "../../../../actions/processActions";
import urls from "../../../../connect/urls";
import {signOutAction} from "../../../../actions/authActions";
import ibospirit from "../../../../connect/ibospirit";
import axios from 'axios';

const NewProgramExerciseModal = () => {
  const dispatch = useDispatch();
  const program_id = useSelector(s => s.programs.is_new_program_exercise_modal_open.program_id);
  const hierarchy_id = useSelector(s => s.programs.is_new_program_exercise_modal_open.hierarchy_id);
  const authToken = useSelector(s => s.auth.token);

  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');

  const [beginner, setBeginner] = useState('');
  const [intermediate, setIntermediate] = useState('');
  const [advanced, setAdvanced] = useState('');
  const [rest_time, setRestTime] = useState('');

  const [source, setSource] = useState('');

  const [equipments, setEquipments] = useState('');
  const [worked_muscles, setWorkedMuscles] = useState('');


  // Search program exercise handler
  const [found, setFound] = useState([]);
  const searchProgramExerciseHandler = async (key) => {
    try{
      let cancelToken;

      //Check if there are any previous pending requests
      if (typeof cancelToken != typeof undefined) {
        cancelToken.cancel("Operation canceled due to new request.");
      }

      //Save the cancel token for the current request
      cancelToken = axios.CancelToken.source();

      const response = await ibospirit.post(urls.searchProgramExercises, {key}, {
        headers: { 'Authorization': 'Bearer ' + authToken },
        cancelToken: cancelToken.token
      })
      setFound(response.data);
    }catch (e) {
      if (axios.isCancel(e))
        return false;
      try{
        const status = parseInt(e.response.status);
        switch (status) {
          // If input data has error
          case 400:
            if (typeof e.response.data == "object"){
              const data = e.response.data;
              let errorText = "";
              Object.keys(data).forEach(key => errorText += data[key]+"\n" );
              dispatch(processModalShow(errorText))
            }
            break;

          // If token expired
          case 401:
            dispatch(processModalShow(e.response.data));
            dispatch(signOutAction());
            break;

          // If made many requests
          case 429:
            dispatch(processModalShow('Too many request try again later'));
            break;

          // If none matches
          default:
            dispatch(processModalShow());
        }

      } catch (e) {
        console.log(e);
        dispatch(processModalShow('Network error. Check internet connection'));
      }
    }
  }

  return (
    ReactDOM.createPortal(
      <MediumModal title={`new program exercise`}
                   onClose={ () => dispatch(closeNewProgramExerciseModal()) }
                   onSuccessText={'Add'}
                   onSuccess={ () => dispatch(createProgramExercise({program_id, hierarchy_id, title, description, beginner, intermediate, advanced, rest_time, source, equipments, worked_muscles})) }
      >

        {/* Title */}
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="new_pe_title">Title :</label>
            <input type="text" className="form-control" placeholder={'Exercise title'} id="new_pe_title"
                   value={title} onChange={e => setTitle(e.target.value)}/>
          </div>
        </div>

        {/* Source */}
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="new_pe_source">Source :</label>
            <input type="text" className="form-control" placeholder={'Source name'} id="new_pe_source"
                   value={source} onChange={e => setSource(e.target.value)}
                   onKeyPress={ () => {
                     if (source.length > 3)
                       searchProgramExerciseHandler(source);
                   }}
            />
            <p className="text-muted pt-1">
              {
                found.map( (f, i) => <a  key={i} href="#" className="mb-1 d-block"
                onClick={e => {e.preventDefault(); setSource(f); setFound([])} }>
                  {f}
                </a>)
              }

            </p>
          </div>
        </div>

        {/* Description */}
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="new_pe_description">Description :</label>
            <textarea id="new_pe_description" rows="3" className="form-control" placeholder="Exercise description"
                      onChange={e => setDescription(e.target.value)} value={description}/>
          </div>
        </div>

        <div className="col-12 p-0"><hr/></div>

        {/* Beginner */}
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="new_pe_beginner">Beginner :</label>
            <input type="text" className="form-control" placeholder={'Beginner reps'} id="new_pe_beginner"
                   value={beginner} onChange={e => setBeginner(e.target.value)}/>
          </div>
        </div>

        {/* Intermediate */}
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="new_pe_intermediate">Intermediate :</label>
            <input type="text" className="form-control" placeholder={'Intermediate reps'} id="new_pe_intermediate"
                   value={intermediate} onChange={e => setIntermediate(e.target.value)}/>
          </div>
        </div>

        {/* Advanced */}
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="new_pe_advanced">Advanced :</label>
            <input type="text" className="form-control" placeholder={'Advanced reps'} id="new_pe_advanced"
                   value={advanced} onChange={e => setAdvanced(e.target.value)}/>
          </div>
        </div>

        {/* Rest time */}
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="new_pe_rest_time">Rest time :</label>
            <input type="text" className="form-control" placeholder={'1 minute'} id="new_pe_rest_time"
                   value={rest_time} onChange={e => setRestTime(e.target.value)}/>
          </div>
        </div>

        {/* Equipments */}
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="new_pe_equipments">Equipments :</label>
            <input type="text" className="form-control" placeholder={'Barbell, dumbbell'} id="new_pe_equipments"
                   value={equipments} onChange={e => setEquipments(e.target.value)}/>
          </div>
        </div>

        {/* Worked muscles */}
        <div className="col-12 col-md-6">
          <div className="form-group">
            <label htmlFor="new_pe_worked_muscles">Worked muscles :</label>
            <input type="text" className="form-control" placeholder={'biceps, triceps'} id="new_pe_worked_muscles"
                   value={worked_muscles} onChange={e => setWorkedMuscles(e.target.value)}/>
          </div>
        </div>


      </MediumModal>,
      document.getElementById('modal')
    )
  );
}

export default NewProgramExerciseModal;
