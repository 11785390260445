import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {Button, FormControl, Form} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import Cleave from 'cleave.js/react';
import moment from 'moment';
import {addTimetableToClient, clearNewTimetableRow} from "../../../actions/clientsActions";

const NewColClientTimetable = () => {
  const dispatch = useDispatch();
  const current_client = useSelector(state => state.clients.current_client);

  const initialData = {
    id: '',
    time: '',
    date: moment().format('DD/MM/YYYY'),
    workout: '',
    notes: '',
    is_done: false,
    is_paid: false
  };

  const [currData, setCurrData] = React.useState({
    id: '',
    time: '',
    date: moment().format('DD/MM/YYYY'),
    workout: '',
    notes: '',
    is_done: false,
    is_paid: false
  });

  React.useEffect(() => {
    setCurrData(state => ({...state, id: current_client.id}));
  }, [current_client.id]);

  React.useEffect(() => {
    if (current_client.is_new_timetable_row_clear){
      setCurrData(state => ({...state, ...initialData, id: current_client.id}));
      dispatch(clearNewTimetableRow());
    }
  }, [current_client.is_new_timetable_row_clear])


  const onSubmit = () => currData.id && dispatch(addTimetableToClient(currData));

  return <>
    <tr>
      <td>
        <div className="input-group input-group-sm justify-content-center">
          <Cleave style={{maxWidth: '60px'}}
                  options={{ time: true, timePattern: ['h', 'm']}}
                  className="form-control form-control-sm"
                  placeholder="00:00"
                  value={currData.time}
                  onChange={(e) => { setCurrData({...currData, time: e.target.value}); }}
          />
          <div className="input-group-prepend">
            <span className="input-group-text">{" "}</span>
          </div>
          <Cleave style={{maxWidth: '100px'}}
                  className="form-control form-control-sm"
                  options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
                  placeholder="01/12/2020"
                  value={currData.date}
                  onChange={(e) => { setCurrData({...currData, date: e.target.value}); }}
          />
        </div>
      </td>
      <td>
        <FormControl placeholder="Workout title"
                     type="text" size="sm"
                     value={currData.workout}
                     onChange={(e) => { setCurrData({...currData, workout: e.target.value}); }}
        />
      </td>
      <td>
        <FormControl placeholder="Notes"
                     value={currData.notes}
                     onChange={(e) => { setCurrData({...currData, notes: e.target.value}); }}
                     type="text" size="sm"
        />
      </td>
      <td>
        <Form.Check type="checkbox"
                    checked={currData.is_done}
                    onChange={() => setCurrData({...currData, is_done: !currData.is_done})}
        />
      </td>
      <td>
        <Form.Check type="checkbox"
                    checked={currData.is_paid}
                    onChange={() => setCurrData({...currData, is_paid: !currData.is_paid})}
        />
      </td>
      <td>
        <Button onClick={onSubmit} variant="info" size={"sm"} className="py-0">
          <FontAwesomeIcon icon={faSave}/>
        </Button>
      </td>
    </tr>
  </>;
}

export default NewColClientTimetable;
