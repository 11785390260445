import {processLoadingEnd, processLoadingStart, processModalShow} from "./processActions";

import urls from "../connect/urls";
import {signOutAction} from "./authActions";

export const WORKOUTS_GET_ALL_WORKOUTS = "WORKOUTS_GET_ALL_WORKOUTS";

export const WORKOUTS_OPEN_NEW_WORKOUT_MODAL = "WORKOUTS_OPEN_NEW_WORKOUT_MODAL";
export const WORKOUTS_CLOSE_NEW_WORKOUT_MODAL = "WORKOUTS_CLOSE_NEW_WORKOUT_MODAL";
export const WORKOUTS_OPEN_EDIT_WORKOUT_MODAL = "WORKOUTS_OPEN_EDIT_WORKOUT_MODAL";
export const WORKOUTS_CLOSE_EDIT_WORKOUT_MODAL = "WORKOUTS_CLOSE_EDIT_WORKOUT_MODAL";

export const WORKOUTS_OPEN_NEW_WORKOUT_EXERCISE_MODAL = "WORKOUTS_OPEN_NEW_WORKOUT_EXERCISE_MODAL";
export const WORKOUTS_CLOSE_NEW_WORKOUT_EXERCISE_MODAL = "WORKOUTS_CLOSE_NEW_WORKOUT_EXERCISE_MODAL";
export const WORKOUTS_OPEN_EDIT_WORKOUT_EXERCISE_MODAL = "WORKOUTS_OPEN_EDIT_WORKOUT_EXERCISE_MODAL";
export const WORKOUTS_CLOSE_EDIT_WORKOUT_EXERCISE_MODAL = "WORKOUTS_CLOSE_EDIT_WORKOUT_EXERCISE_MODAL";


/**  Workout view manipulation START */
// Get all workouts data
export const getWorkoutsData = () => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    const response = await ibospirit.post(urls.getWorkouts, {},
      {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    dispatch({type: WORKOUTS_GET_ALL_WORKOUTS, payload: response.data});

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}
/**  Workout view manipulation END   */

/**  New Workout modal START */
// New Workout modal manipulation
export const openNewWorkoutModal = () => ({type: WORKOUTS_OPEN_NEW_WORKOUT_MODAL});
export const closeNewWorkoutModal = () => ({type: WORKOUTS_CLOSE_NEW_WORKOUT_MODAL });

// Create new workout
export const createWorkout = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());
    let newData = new FormData();
    newData.append('title', data.title);
    newData.append('description', data.description);

    if (data.icon)
      newData.append('icon', data.icon, data.icon.fileName);

    await ibospirit.post(urls.createWorkout, newData, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(closeNewWorkoutModal());
    dispatch(getWorkoutsData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) { dispatch(processModalShow('Network error. Check internet connection'));
    } finally { dispatch(processLoadingEnd());}
  }
}
/**  New Workout modal END   */


/**  Edit workout modal START */
// New workout modal manipulation
export const openEditWorkoutModal = (id) => ({ type: WORKOUTS_OPEN_EDIT_WORKOUT_MODAL, payload: id });
export const closeEditWorkoutModal = () => ({ type: WORKOUTS_CLOSE_EDIT_WORKOUT_MODAL });

// Create new workout
export const editWorkout = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());
    let newData = new FormData();
    newData.append('id', data.id);
    newData.append('title', data.title);
    newData.append('description', data.description);

    if (data.icon)
      newData.append('icon', data.icon, data.icon.fileName);

    await ibospirit.post(urls.editWorkout, newData, {headers: { 'content-type': 'multipart/form-data', 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(closeEditWorkoutModal());
    dispatch(getWorkoutsData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));

    }finally {
      dispatch(processLoadingEnd());
    }
  }
}
/**  Edit workout modal END   */


// Activate / Deactivate Workout
export const deActivateWorkout = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deActivateWorkout, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getWorkoutsData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}

// Activate / Deactivate Pro Workout
export const deActivateProWorkout = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deActivateProWorkout, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getWorkoutsData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}

// Delete workout
export const deleteWorkout = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deleteWorkout, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getWorkoutsData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}


/** *********************************************************************** **/


/**  New Workout exercise modal START */
// New Workout modal manipulation
export const openNewWorkoutExerciseModal = (workout_id) => ({type: WORKOUTS_OPEN_NEW_WORKOUT_EXERCISE_MODAL, payload: workout_id});
export const closeNewWorkoutExerciseModal = () => ({type: WORKOUTS_CLOSE_NEW_WORKOUT_EXERCISE_MODAL });

// Create new workout exercise
export const createWorkoutExercise = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.createWorkoutExercise, data, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(closeNewWorkoutExerciseModal());
    dispatch(getWorkoutsData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) { dispatch(processModalShow('Network error. Check internet connection'));
    } finally { dispatch(processLoadingEnd());}
  }
}
/**  New Workout exercise modal END   */


/**  Edit Workout exercise modal START */
// Edit Workout modal manipulation
export const openEditWorkoutExerciseModal = (exercise_id, workout_id) => ({type: WORKOUTS_OPEN_EDIT_WORKOUT_EXERCISE_MODAL, payload: { exercise_id, workout_id}});
export const closeEditWorkoutExerciseModal = () => ({type: WORKOUTS_CLOSE_EDIT_WORKOUT_EXERCISE_MODAL });

// Create new workout exercise
export const editWorkoutExercise = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.editWorkoutExercise, data, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(closeEditWorkoutExerciseModal());
    dispatch(getWorkoutsData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) { dispatch(processModalShow('Network error. Check internet connection'));
    } finally { dispatch(processLoadingEnd());}
  }
}
/**  Edit Workout exercise modal END   */


// Activate / Deactivate Workout Exercise
export const deActivateWorkoutExercise = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deActivateWorkoutExercise, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getWorkoutsData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}

// Delete workout Exercise
export const deleteWorkoutExercise = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deleteWorkoutExercise, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch(getWorkoutsData());
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 429:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }finally {
      dispatch(processLoadingEnd());
    }
  }
}
