const urls = {
  imagePath: "https://backend.ibospirit.com",
  // imagePath : "http://ibospirit.loc.192.168.1.3.nip.io",
  signInUrl: "/admin/signIn",

  get12WUsers: "/admin/TwelveWeek/get12WUsers",

  getUsers: "/admin/users/getUsers",
  getProUsers: "/admin/users/getProUsers",
  createUser: "/admin/users/createUser",
  editUser: "/admin/users/editUser",
  deActivateUser: "/admin/users/deActivateUser",
  deleteUser: "/admin/users/deleteUser",
  getUserRealInfo: "/admin/users/getUserRealInfo",

  generateMembership: "/admin/users/generateMembership",
  toggleMembership: "/admin/users/toggleMembership",
  getPaypalWebhookEvents: "/admin/users/getPaypalWebhookEvents",


  /** FAQ management start */

  getFAQs: "/admin/FAQs/getFAQs",
  createFAQ: "/admin/FAQs/createFAQ",
  editFAQ: "/admin/FAQs/editFAQ",
  orderUpFAQ: "/admin/FAQs/orderUpFAQ",
  orderDownFAQ: "/admin/FAQs/orderDownFAQ",
  deActivateFAQ: "/admin/FAQs/deActivateFAQ",
  deleteFAQ: "/admin/FAQs/deleteFAQ",

  /** FAQ management end */


  /** Workouts management start */

  getWorkouts: "/admin/workouts/getWorkouts",

  createWorkout: "/admin/workouts/createWorkout",
  editWorkout: "/admin/workouts/editWorkout",
  deleteWorkout: "/admin/workouts/deleteWorkout",
  deActivateWorkout: "/admin/workouts/deActivateWorkout",
  deActivateProWorkout: "/admin/workouts/deActivateProWorkout",

  searchWorkoutExercises: "/admin/workouts/searchWorkoutExercises",
  createWorkoutExercise: "/admin/workouts/createWorkoutExercise",
  editWorkoutExercise: "/admin/workouts/editWorkoutExercise",
  deleteWorkoutExercise: "/admin/workouts/deleteWorkoutExercise",
  deActivateWorkoutExercise: "/admin/workouts/deActivateWorkoutExercise",

  /** Workouts management end */


  /** Programs management start */

  getPrograms: "/admin/programs/getPrograms",
  getProgramHierarchiesAndExercises: "/admin/programs/getProgramHierarchiesAndExercises",
  createProgram: "/admin/programs/createProgram",
  editProgram: "/admin/programs/editProgram",
  deActivateProgram: "/admin/programs/deActivateProgram",
  deActivateProProgram: "/admin/programs/deActivateProProgram",
  deleteProgram: "/admin/programs/deleteProgram",
  createProgramHierarchy: "/admin/programs/createHierarchy",
  editProgramHierarchy: "/admin/programs/editHierarchy",
  deActivateProgramHierarchy: "/admin/programs/deActivateHierarchy",
  deActivateProgramHierarchyIsFreeFeature: "/admin/programs/deActivateHierarchyIsFreeFeature",
  deleteProgramHierarchy: "/admin/programs/deleteHierarchy",
  createProgramExercise: "/admin/programs/createExercise",
  editProgramExercise: "/admin/programs/editExercise",
  deActivateProgramExercise: "/admin/programs/deActivateExercise",
  deleteProgramExercise: "/admin/programs/deleteExercise",
  searchProgramExercises: "/admin/programs/searchProgramExercises",

  /** Programs management end */


  /** Questions management start */

  getQuestions: "/admin/questions/getQuestions",
  getFullConversation: "/admin/questions/getFullConversation",
  sendMessageToUser: "/admin/questions/sendMessageToUser",

  /** Questions management end */


  /** Nutrition management start */

  getNutritionUnitTypes: '/admin/nutrition/getUnitTypes',
  createNutritionUnitType: '/admin/nutrition/createUnitType',
  editNutritionUnitType: '/admin/nutrition/editUnitType',
  deleteNutritionUnitType: '/admin/nutrition/deleteUnitType',
  getNutritionCategories: '/admin/nutrition/getCategories',
  createNutritionCategory: '/admin/nutrition/createCategory',
  editNutritionCategory: '/admin/nutrition/editCategory',
  deleteNutritionCategory: '/admin/nutrition/deleteCategory',
  getNutritionCategoryItems: '/admin/nutrition/getItems',
  createNutritionCategoryItem: '/admin/nutrition/createItem',
  editNutritionCategoryItem: '/admin/nutrition/editItem',
  deleteNutritionCategoryItem: '/admin/nutrition/deleteItem',

  /** Nutrition management end */



  /** Clients management start */

  getClients: "/admin/personalClients/getClients",
  getClient: "/admin/personalClients/getClient",
  addClient: "/admin/personalClients/addClient",

  deleteClient: "/admin/personalClients/deleteClient",
  deActivateClient: "/admin/personalClients/deActivateClient",
  editClientMainData: "/admin/personalClients/editMainDataClient",

  addTimetableToClient: "/admin/personalClients/addTimetableToClient",
  editTimetableToClient: "/admin/personalClients/editTimetableToClient",
  deleteTimetableClient: "/admin/personalClients/deleteTimetableClient",
  getTodayTimetableClient: "/admin/personalClients/getTodayTimetableClient",

  addStatToClient: "/admin/personalClients/addStatToClient",
  editStatToClient: "/admin/personalClients/editStatToClient",
  deleteStatClient: "/admin/personalClients/deleteStatClient",

  getImagesClient: "/admin/personalClients/getImagesClient",
  addImageClient: "/admin/personalClients/addImageClient",
  editImageClient: "/admin/personalClients/editImageClient",
  deleteImageClient: "/admin/personalClients/deleteImageClient",
  updateImageClient: "/admin/personalClients/updateImageClient",

  /** Clients management end */


  /** Vimeo videos start */
  getVimeoVideos: "/admin/vimeoVideos/getAll",
  getVimeoVideo: "/admin/vimeoVideos/get",
  createVimeoVideo: "/admin/vimeoVideos/create",
  editVimeoVideo: "/admin/vimeoVideos/edit",
  deleteVimeoVideo: "/admin/vimeoVideos/delete",
  /** Vimeo videos end */

  /** PC workout program start */
  pcWorkoutProgram: {
    create: "admin/pcWorkoutProgram/create",
    delete: "admin/pcWorkoutProgram/delete",
    edit: "admin/pcWorkoutProgram/edit",
    get: "admin/pcWorkoutProgram/get",
    getAll: "admin/pcWorkoutProgram/getAll",
    isNutritional: "admin/pcWorkoutProgram/isNutritional",
    copyTo: "admin/pcWorkoutProgram/copyTo",
    deActivate: "admin/pcWorkoutProgram/deActivate",

    week: {
      create: "admin/pcWorkoutProgram/week/create",
      delete: "admin/pcWorkoutProgram/week/delete",
      edit: "admin/pcWorkoutProgram/week/edit",
      deActivate: "admin/pcWorkoutProgram/week/deActivate",
    },

    day: {
      create: "admin/pcWorkoutProgram/day/create",
      delete: "admin/pcWorkoutProgram/day/delete",
      edit: "admin/pcWorkoutProgram/day/edit",
      deActivate: "admin/pcWorkoutProgram/day/deActivate",
    },

    exercise: {
      create: "admin/pcWorkoutProgram/exercise/create",
      delete: "admin/pcWorkoutProgram/exercise/delete",
      edit: "admin/pcWorkoutProgram/exercise/edit",
      search : "admin/pcWorkoutProgram/exercise/search",
    }
  },
}

export default urls;
