import React from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {websiteName} from "../constants";
import {getPrograms, openNewProgramModal} from "../actions/programsActions";
import NewProgramModal from "../components/Programs/NewProgramModal";
import EditProgramModal from "../components/Programs/EditProgramModal";
import ProgramContainer from "../components/Programs/ProgramContainer";
import NewProgramHierarchyModal from "../components/Programs/Hierarchies/NewProgramHierarchyModal";
import EditProgramHierarchyModal from "../components/Programs/Hierarchies/EditProgramHierarchyModal";
import NewProgramExerciseModal from "../components/Programs/Hierarchies/Exercises/NewProgramExerciseModal";
import EditProgramExerciseModal from "../components/Programs/Hierarchies/Exercises/EditProgramExerciseModal";


const Programs = () => {
  const programs = useSelector(state => state.programs);
  const dispatch = useDispatch();

  React.useEffect(() => {
    document.title = 'Programs' + websiteName;
    dispatch(getPrograms());
  }, [])

  return <StyledPrograms>
    {/* New program modal start */}
    {(programs.is_new_program_modal_open) ? <NewProgramModal /> : null}
    {/* New program modal end   */}

    {/* Edit program modal start */}
    {(programs.is_edit_program_modal_open.state) ? <EditProgramModal /> : null}
    {/* Edit program modal end   */}



    {/* New program hierarchy modal start */}
    {(programs.is_new_program_hierarchy_modal_open.state) ? <NewProgramHierarchyModal /> : null}
    {/* New program modal end   */}

    {/* Edit program modal start */}
    {(programs.is_edit_program_hierarchy_modal_open.state) ? <EditProgramHierarchyModal /> : null}
    {/* Edit program hierarchy modal end   */}


    {/* New program exercise modal start */}
    {(programs.is_new_program_exercise_modal_open.state) ? <NewProgramExerciseModal /> : null}
    {/* New program exercise modal end   */}

    {/* Edit program exercise modal start */}
    {(programs.is_edit_program_exercise_modal_open.state) ? <EditProgramExerciseModal /> : null}
    {/* Edit program exercise modal end   */}

    {/* New program add button start */}
    <h4>Programs{' '}
      <button className="btn btn-primary btn-sm" onClick={() => dispatch(openNewProgramModal())}>
        <FontAwesomeIcon icon={faPlus}/>
      </button>
    </h4>
    {/* New program add button end */}

    <div className="container-fluid p-0">
      <div className="row">
        {
          (programs.programs.length)
            ? programs.programs.map(program => <ProgramContainer key={program.id} data={program}/>)
            : <div className="col-12"><h2 className="text-center">Programs not found yet</h2></div>
        }
      </div>
    </div>

  </StyledPrograms>;
};


const StyledPrograms = styled.div`
  min-height: ${window.innerHeight - 155}px;
  
  .program{
    border-radius: 5px;
    border: 1px solid #777;
    padding: 10px;
    margin-bottom: 30px;
    background: #404040;
    box-shadow: 0 0 10px #444;
    &.not-seen{ opacity: 0.4; }
    
    &-actions{
      margin: 10px 0 20px;
      padding-bottom: 10px;
      border-bottom: 1px dotted #777;
      text-align: right;
      button{
        padding: 0 3px;
        margin: 0 3px;
      }
    }
    
    &-hierarchies{
      border-left: 1px dashed #f1f1f1;
      
      .hierarchy{
        &-top{
          display: flex;
          justify-content: space-between;
          align-content: center;
          font-size: 14px;
          padding: 3px 0;
          
          &:hover{ background: #555; }
          &.not-seen{ opacity: 0.4; }
          
          .hierarchy-actions{
            button{
              padding: 0 3px;
              margin: 0 3px;
            }
          }
        }
        &-content{
          border-left: 1px dashed #f1f1f1;
          margin-left: 25px;
        } 
      }
      
      .exercise{
        &:hover{ background: #555; }
        display: flex;
        justify-content: space-between;
        align-content: center;
        font-size: 14px;
        padding: 3px 0;
        
        &:last-child{ border: none; }
        
        &.not-seen{ opacity: 0.4; }
        
        &-actions{
          button{
            padding: 0 3px;
            margin: 0 3px;
          }
        }
      }
    }
  }  
`;

export default Programs;
