import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faDollarSign, faDownload,
  faEye,
  faEyeSlash,
  faFolder, faSyncAlt,
  faTimes, faToggleOff, faToggleOn,
} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import ConfirmModal from "../ConfirmModal";
import {
  deActivateProgram,
  deActivateProProgram, deleteProgram, getProgramData,
  openEditProgramModal,
  openNewProgramHierarchyModal
} from "../../actions/programsActions";
import ProgramHierarchyContainer from "./ProgramHierarchyContainer";
import urls from "../../connect/urls";

const ProgramContainer = ({data}) => {
  const dispatch = useDispatch();
  const current_program_data = useSelector(state => state.programs.current_program_data);
  const [isDeleteModal, setIsDeleteModal] = React.useState({state: false, id: null});
  const [isHidden, setIsHidden] = React.useState(false);

  return (
    <div className="col-12 col-md-6">
      <div className={`program ${data.is_active ? '' : 'not-seen'}`}>
        {
          data.image ? <img className={'img-thumbnail float-left mr-2'} style={{height: 50}} src={urls.imagePath+data.image} alt=""/> : null
        }
        <div className="program-title">
          <a href="#edit" onClick={e => {e.stopPropagation(); dispatch(openEditProgramModal(data.id)) }}>
            {data.title}</a>{' '}
          {
            (data.is_pro === 1)
              ? <span className="badge badge-danger">PRO</span>
              : null
          }
          {
            (data.is_repeatable === 1)
              ? <span className="ml-1 badge badge-primary"><FontAwesomeIcon icon={faSyncAlt} /></span>
              : null
          }
        </div>
        <div className="program-actions">
          <button className="btn btn-success btn-sm" onClick={() => dispatch(getProgramData(data.id)) }>
            <FontAwesomeIcon icon={faDownload} />
          </button>
          <button className="btn btn-dark btn-sm" onClick={() => setIsHidden(!isHidden) }>
            <FontAwesomeIcon icon={ !isHidden ? faToggleOn : faToggleOff} />
          </button>
          <button className="btn btn-primary btn-sm" onClick={() => dispatch(openNewProgramHierarchyModal(null, data.id))}>
            <FontAwesomeIcon icon={faFolder} fixedWidth />
          </button>
          <button className="btn btn-outline-success btn-sm" onClick={() => dispatch(deActivateProProgram(data.id))}>
            <FontAwesomeIcon icon={faDollarSign} fixedWidth/>
          </button>
          <button className="btn btn-outline-light btn-sm" onClick={() => dispatch(deActivateProgram(data.id))}>
            {
              data.is_active ? <FontAwesomeIcon icon={faEyeSlash} fixedWidth/> : <FontAwesomeIcon icon={faEye} fixedWidth/>
            }
          </button>
          <button className="btn btn-outline-danger btn-sm" onClick={() => setIsDeleteModal({state: true, id: data.id}) }>
            <FontAwesomeIcon icon={faTimes} fixedWidth/>
          </button>
        </div>

        <div className={`program-hierarchies ${isHidden ? 'd-none' : ""}`}>
          {
            (current_program_data.program_id === data.id && current_program_data.data.length > 0)
            ? current_program_data.data.map(each => <ProgramHierarchyContainer key={each.id} data={each} />)
            : <h6>--- Empty or not downloaded</h6>
          }
        </div>

      </div>

      {/* Program delete modal */}
      {
        (isDeleteModal.state)
          ? <ConfirmModal
            body={'Are you really sure you want to delete this Program?'}
            onCancel={ () => { setIsDeleteModal({state: false, id: null}) } }
            onSuccess={ () => { dispatch(deleteProgram(isDeleteModal.id)); setIsDeleteModal({state: false, id: null}) } }
          /> : null
      }
    </div>
  );
}

export default ProgramContainer;
