import React from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from "react-redux";
import {closeUserInfoUserModal} from "../../actions/usersActions";

const InfoUserModal = () => {
  const dispatch = useDispatch();

  const info = useSelector(state => state.users.current_user_info.data);

  return (
    ReactDOM.createPortal(
      <>
        <StyledInfoUserModalBackground/>
        <StyledInfoUserModal>
          <div className="m-container">
            <div className="m-title">user real time info</div>

            <div className="m-body">
              <div className="row">

                <div className="col-12 col-md-6">Name: {info.user_info_data.name}</div>
                <div className="col-12 col-md-6">Email: {info.email}</div>
                <div className="col-12 col-md-6">Joined: {info.created_at}</div>
                <div className="col-12 col-md-6">Activeness: {info.is_active === 1 ? 'ACTIVE' : 'INACTIVE'}</div>
                <hr/>
                <div className="col-12 col-md-3">Gender: {info.user_info_data.gender ? 'Male' : 'Female'}</div>
                <div className="col-12 col-md-3">Height: {info.user_info_data.height} sm</div>
                <div className="col-12 col-md-3">Weight: {info.user_info_data.weight} kg</div>
                <div className="col-12 col-md-3">Device: {info.user_info_data.device}</div>
                <hr/>
                <div className="col-12">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <h4>Database info:</h4>
                      <div className="row">
                        {
                          info.subscription_data.map(each => <div key={each.id} className="col-12 mb-3">
                            <p>Subscription: {each.subscription_id}</p>
                            <p>Last update: {each.status_update_time} ({each.status})</p>
                            <p>{ each.is_exception === 0 ? 'CREATED BY USER' : 'CREATED BY ADMIN' }</p>
                          </div>)
                        }
                      </div>
                    </div>

                    <div className="col-12 col-md-6">
                      <h4>Paypal info:</h4>
                      <div className="row">
                        {
                          info.subscription_data_server.map(each => <div key={each.id} className="col-12 mb-3">
                            <p>Subscription: {each.id}</p>
                            <p>Last update: {each.status_update_time} ({each.status})</p>
                            <p>Created time: {each.create_time}</p>
                            <p>Last billing: {each.last_billing} ({each.last_billing_time})</p>
                            <p>Next billing: {each.next_billing_time}</p>
                          </div>)
                        }
                      </div>
                    </div>
                  </div>
                </div>


                <hr/>

              </div>
            </div>

            <div className="row">
              <div className="col">{' '}</div>
              <div className="col">
                <button
                  className="btn btn-outline-success btn-block"
                  onClick={ () => dispatch(closeUserInfoUserModal()) }>Close</button>
              </div>
            </div>
          </div>
        </StyledInfoUserModal>
      </>,
      document.getElementById('modal')
    )
  );
}

const StyledInfoUserModalBackground = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 1005; 
`;

const StyledInfoUserModal = styled.div`
  position: fixed;
  z-index: 1007;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  
  .m-container{
    max-height: ${window.innerHeight - 50}px;
    overflow: scroll;
    margin: 0 15px;
    min-width: 300px;
    width: 600px;
    background: #303030;
    padding: 20px 15px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px #444;
    
    .m-title{
      font: 20px MontserratBold, sans-serif;
      text-transform:uppercase;
      text-align: center;
      margin-bottom: 15px;
    }
    
    .m-body{
      font-size: 14px;
      margin-bottom: 20px;
      
      p {margin-bottom: 5px}
    }
    
    
    button{
    
    }
  }
`;

export default InfoUserModal;
