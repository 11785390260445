import React, {useState} from "react";
import ReactDOM from "react-dom";
import {useDispatch} from "react-redux";
import {
  closeNewNutritionCategoryAction,
  createNutritionCategoryAction,
} from "../../actions/nutritionActions";
import MediumModal from "../MediumModal";

const NewNutritionCategoryModal = () => {
  const dispatch = useDispatch();

  const [title, setTitle] = useState('');

  return (
    ReactDOM.createPortal(
      <MediumModal onSuccess={ () => dispatch(createNutritionCategoryAction({title}))}
                   onClose={ () => dispatch(closeNewNutritionCategoryAction())}
                   title={'Create category'} onSuccessText={'Add'}>

        {/* Title */}
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="new_category_title">Title <span className="text-danger">*</span> :</label>
            <input type="text" className="form-control" placeholder={'Category title'} id="new_category_title"
                   value={title} onChange={e => setTitle(e.target.value)}/>
          </div>
        </div>
      </MediumModal>,
      document.getElementById('modal')
    )
  );
}

export default NewNutritionCategoryModal;
