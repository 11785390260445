import {useDispatch, useSelector} from "react-redux";
import React from "react";
import ReactDOM from "react-dom";
import {Button, Form, FormControl, FormLabel, Modal} from "react-bootstrap";
import {addNewClient, closeNewClientModal} from "../../actions/clientsActions";

const NewClientModal = () => {
  const dispatch = useDispatch();
  const new_client = useSelector(state => state.clients);

  const [data, setData] = React.useState({
    name: '',
    login: '',
    password: '',
    is_personal: false
  })

  React.useEffect(() => {
    if (!new_client.new_client_modal_is_open)
      setData({...data, password: '', name: '', login: '', is_personal: false});

  }, [new_client.new_client_modal_is_open])

  return ReactDOM.createPortal(<React.Fragment>
    <Modal show={new_client.new_client_modal_is_open} size={"sm"} onHide={() => dispatch(closeNewClientModal())}>
      <Modal.Header closeButton className="bg-dark">
        <Modal.Title>New client</Modal.Title>
      </Modal.Header>
      <Modal.Body className="bg-dark">
        <FormLabel className="">Client name</FormLabel>
        <FormControl value={data.name} onChange={(e) => setData({...data, name: e.target.value})} type="text" className="mb-3" placeholder="Client name" />

        <FormLabel className="">Client login</FormLabel>
        <FormControl value={data.login} onChange={(e) => setData({...data, login: e.target.value})} type="text" className="mb-3" placeholder="Client login" />

        <FormLabel className="">Client password</FormLabel>
        <FormControl value={data.password} onChange={(e) => setData({...data, password: e.target.value})} type="password" className="mb-3" placeholder="Client password" />

        <FormLabel className="">Personality type</FormLabel>
        <Form.Check type="checkbox"
                    className="mb-3"
                    label={"is personal client?"}
                    checked={data.is_personal}
                    onChange={() => setData({...data, is_personal: !data.is_personal})}
        />
      </Modal.Body>
      <Modal.Footer className="bg-dark">
        <Button variant="primary" onClick={() => dispatch(addNewClient(data))}>Add</Button>
      </Modal.Footer>
    </Modal>
  </React.Fragment>, document.getElementById('modal'));
}

export default NewClientModal;
