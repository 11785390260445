import {useDispatch, useSelector} from "react-redux";
import React from "react";
import {Button, FormControl} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import Cleave from 'cleave.js/react';
import moment from 'moment';
import {addStatToClient, clearNewStatRow,} from "../../../actions/clientsActions";

const NewColClientStat = () => {
  const dispatch = useDispatch();
  const current_client = useSelector(state => state.clients.current_client);

  const initialData = {
    id: '',
    weight: '',
    height: '',
    muscle_mass: '',
    body_fat: '',
    bmi: '',
    visceral_fat: '',
    age: '',
    date: moment().format('DD/MM/YYYY')
  };

  const [currData, setCurrData] = React.useState({
    id: '',
    weight: '',
    height: '',
    muscle_mass: '',
    body_fat: '',
    bmi: '',
    visceral_fat: '',
    age: '',
    date: moment().format('DD/MM/YYYY')
  });

  React.useEffect(() => {
    setCurrData(state => ({...state, id: current_client.id}));
  }, [current_client.id]);

  React.useEffect(() => {
    if (current_client.is_new_timetable_row_clear){
      setCurrData(state => ({...state, ...initialData, id: current_client.id}));
      dispatch(clearNewStatRow());
    }
  }, [current_client.is_new_timetable_row_clear])


  const onSubmit = () => currData.id && dispatch(addStatToClient(currData));

  return <>
    <tr>
      <td>
        <Cleave className="form-control form-control-sm"
                options={{ date: true, datePattern: ['d', 'm', 'Y'] }}
                placeholder="01/12/2020"
                value={currData.date}
                onChange={(e) => { setCurrData({...currData, date: e.target.value}); }}
        />
      </td>
      <td>
        <FormControl placeholder="Muscle mass" type="number" size="sm"
                     value={currData.muscle_mass} onChange={(e) => { setCurrData({...currData, muscle_mass: e.target.value}); }}/>
      </td>
      <td>
        <FormControl placeholder="Body fat" type="number" size="sm"
                     value={currData.body_fat} onChange={(e) => { setCurrData({...currData, body_fat: e.target.value}); }}/>
      </td>
      <td>
        <FormControl placeholder="BMI" type="number" size="sm"
                     value={currData.bmi} onChange={(e) => { setCurrData({...currData, bmi: e.target.value}); }}/>
      </td>
      <td>
        <FormControl placeholder="Visceral fat" type="number" size="sm"
                     value={currData.visceral_fat} onChange={(e) => { setCurrData({...currData, visceral_fat: e.target.value}); }}/>
      </td>
      <td>
        <FormControl placeholder="Weight (kg)" type="number" size="sm"
                     value={currData.weight} onChange={(e) => { setCurrData({...currData, weight: e.target.value}); }}/>
      </td>
      <td>
        <FormControl placeholder="Height (sm)" type="number" size="sm"
                     value={currData.height} onChange={(e) => { setCurrData({...currData, height: e.target.value}); }}/>
      </td>
      <td>
        <FormControl placeholder="Age" type="number" size="sm"
                     value={currData.age} onChange={(e) => { setCurrData({...currData, age: e.target.value}); }}/>
      </td>
      <td>
        <Button onClick={onSubmit} variant="info" size={"sm"} className="py-0">
          <FontAwesomeIcon icon={faSave}/>
        </Button>
      </td>
    </tr>
  </>;
}

export default NewColClientStat;
