import React from "react";
import { faInstagram, faYoutube, faFacebookF } from "@fortawesome/free-brands-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Footer = () => {
  return (
    <div className="App-footer">
      <div className="App-footer__inner">
        <p className="copyright">IBOSpirit.com &copy; {new Date().getFullYear()}</p>
        <div className="social-icons">
          <a target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/ibospirit/">
            <FontAwesomeIcon icon={faInstagram}/>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://youtube.com/c/ibospirit">
            <FontAwesomeIcon icon={faYoutube}/>
          </a>
          <a target="_blank" rel="noopener noreferrer" href="https://www.facebook.com/Ibocoach/">
            <FontAwesomeIcon icon={faFacebookF}/>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
