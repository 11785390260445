import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faDollarSign, faEye, faEyeSlash, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import WorkoutExerciseContainer from "./WorkoutExerciseContainer";
import {
  deActivateProWorkout,
  deActivateWorkout,
  deleteWorkout,
  openEditWorkoutModal, openNewWorkoutExerciseModal
} from "../../actions/workoutsActions";
import {useDispatch} from "react-redux";
import ConfirmModal from "../ConfirmModal";

const WorkoutContainer = ({data}) => {
  const dispatch = useDispatch();

  const [isDeleteModal, setIsDeleteModal] = React.useState({state: false, id: null});
  const [isHidden, setIsHidden] = React.useState(true);

  return (
    <div className="col-12 col-md-4">
      <div className={`workout ${data.is_active ? '' : 'not-seen'}`}>
        {
          data.image ? <img className={'img-thumbnail float-left mr-2'} style={{height: 50}} src={'https://backend.ibospirit.com'+data.image} alt=""/> : null
        }
        <div className="workout-title">
          <a href="#edit" onClick={e => {e.stopPropagation(); dispatch(openEditWorkoutModal(data.id)) }}>
            {data.title}</a>{' '}
          {
            (data.is_pro === 1)
              ? <span className="badge badge-danger">PRO</span>
              : null
          }

        </div>
        <div className="workout-actions">
          <button className="btn btn-dark btn-sm" onClick={() => setIsHidden(!isHidden) }>
            Toggle
          </button>
          <button className="btn btn-outline-primary btn-sm" onClick={() => dispatch(openNewWorkoutExerciseModal(data.id))}>
            <FontAwesomeIcon icon={faPlus} fixedWidth/>
          </button>
          <button className="btn btn-outline-success btn-sm" onClick={() => dispatch(deActivateProWorkout(data.id))}>
            <FontAwesomeIcon icon={faDollarSign} fixedWidth/>
          </button>
          <button className="btn btn-outline-light btn-sm" onClick={() => dispatch(deActivateWorkout(data.id))}>
            {
              data.is_active ? <FontAwesomeIcon icon={faEyeSlash} fixedWidth/> : <FontAwesomeIcon icon={faEye} fixedWidth/>
            }
          </button>
          <button className="btn btn-outline-danger btn-sm" onClick={() => setIsDeleteModal({state: true, id: data.id}) }>
            <FontAwesomeIcon icon={faTimes} fixedWidth/>
          </button>
        </div>

        <div className={`workout-exercises ${isHidden ? 'd-none' : ""}`}>
          {
            data.workout_exercises_data.length
              ? data.workout_exercises_data.map(exercise => <WorkoutExerciseContainer key={exercise.id}
                                                                                      data={exercise}/>)
              : <h6 className="text-center">exercises not found</h6>
          }
        </div>

      </div>

      {/* Workout delete modal */}
      {
        (isDeleteModal.state)
          ? <ConfirmModal
            body={'Are you really sure you want to delete this Workout?'}
            onCancel={ () => { setIsDeleteModal({state: false, id: null}) } }
            onSuccess={ () => { dispatch(deleteWorkout(isDeleteModal.id)); setIsDeleteModal({state: false, id: null}) } }
          /> : null
      }
    </div>
  );
}

export default WorkoutContainer;
