import React, {useState} from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import {useDispatch} from "react-redux";
import {closeNewUserModal, createUser} from "../../actions/usersActions";

const NewUserModal = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [name, setName] = useState('');
  const [gender, setGender] = useState(1);
  const [height, setHeight] = useState('');
  const [weight, setWeight] = useState('');

  return (
    ReactDOM.createPortal(
      <>
        <StyledNewUserModalBackground/>
        <StyledNewUserModal>
          <div className="m-container">
            <div className="m-title">add new user</div>

            <div className="m-body">
              <div className="row">

                {/* Email */}
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="new_user_email">Email <span className="text-danger">*</span> :</label>
                    <input type="email" className="form-control" placeholder={'User email'} id="new_user_email"
                           value={email} onChange={e => setEmail(e.target.value)}/>
                  </div>
                </div>

                {/* Password */}
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="new_user_password">Password <span className="text-danger">*</span> :</label>
                    <input type="password" className="form-control" placeholder={'User password'} id="new_user_password"
                           value={password} onChange={e => setPassword(e.target.value)}/>
                  </div>
                </div>

                {/* Name */}
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="new_user_name">Name <span className="text-danger">*</span> :</label>
                    <input type="text" className="form-control" placeholder={'User name'} id="new_user_name"
                           value={name} onChange={e => setName(e.target.value)}/>
                  </div>
                </div>

                {/* Gender */}
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="new_user_gender">Gender <span className="text-danger">*</span> :</label>
                    <select id="new_user_gender" className="form-control"
                            value={gender} onChange={e => setGender(e.target.value)}>
                      <option value="1">Male</option>
                      <option value="0">Female</option>
                    </select>
                  </div>
                </div>

                {/* Height */}
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="new_user_height">Height:</label>
                    <input type="text" className="form-control" placeholder={'User height'} id="new_user_height"
                           value={height} onChange={e => setHeight(e.target.value)}/>
                  </div>
                </div>

                {/* Weight */}
                <div className="col-12 col-md-6">
                  <div className="form-group">
                    <label htmlFor="new_user_weight">Weight:</label>
                    <input type="text" className="form-control" placeholder={'User weight'} id="new_user_weight"
                           value={weight} onChange={e => setWeight(e.target.value)}/>
                  </div>
                </div>

              </div>
            </div>

            <div className="row">
              <div className="col">
                <button
                  className="btn btn-outline-danger btn-block"
                  onClick={ () => dispatch(closeNewUserModal()) }>Cancel
                </button>
              </div>
              <div className="col">
                <button
                  className="btn btn-outline-success btn-block"
                  onClick={ () => dispatch(createUser({
                    email, password, name, gender, height, weight
                  })) }>Add
                </button>
              </div>
            </div>
          </div>
        </StyledNewUserModal>
      </>,
      document.getElementById('modal')
    )
  );
}

const StyledNewUserModalBackground = styled.div`
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 1005; 
`;

const StyledNewUserModal = styled.div`
  position: fixed;
  z-index: 1007;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;

  
  .m-container{
    max-height: ${window.innerHeight - 50}px;
    overflow-y: scroll;
    margin: 0 15px;
    min-width: 300px;
    width: 600px;
    background: #303030;
    padding: 20px 15px 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px #444;
    
    height: auto;
    
    
    .m-title{
      font: 20px MontserratBold, sans-serif;
      text-transform:uppercase;
      text-align: center;
      margin-bottom: 15px;
    }
    
    .m-body{
      font-size: 14px;
      margin-bottom: 20px;
      
      p {margin-bottom: 5px}
    }
    
    
    button{
    
    }
  }
`;

export default NewUserModal;
