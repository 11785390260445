import {
  QUESTIONS_GET_ALL_MESSAGES,
  QUESTIONS_NEXT_PAGE,
  QUESTIONS_PREVIOUS_PAGE
} from "../actions/questionsActions";


const defaultState = {
  data: [],
  current_page: 1,
  last_page: 1,
  total: 0
}

const questionsReducer = (state = defaultState, action) => {
  switch (action.type) {

    case QUESTIONS_GET_ALL_MESSAGES :
      const {data, current_page, total, last_page} = action.payload;
      return {...state, data, current_page, total, last_page};

    case QUESTIONS_NEXT_PAGE :
      return (state.current_page < state.last_page)
        ? {...state, current_page: state.current_page + 1} : state;

    case QUESTIONS_PREVIOUS_PAGE :
      return (state.current_page > 1)
        ? {...state, current_page: state.current_page - 1} : state;

    default :
      return state;
  }
}

export default questionsReducer;
