import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faEdit,
  faEye,
  faEyeSlash,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {orderDowFaq, orderUpFaq} from "../../actions/faqsActions";
import {useDispatch} from "react-redux";

const FaqTableItem = ({ind, data, onDelete, onEdit, onDeActivate}) => {
  const dispatch = useDispatch();

  return (
    <tr key={data.id}>
      <td>{ind}</td>
      <td>{data.title}</td>
      <td>{data.description}</td>
      <td>
        <button className="btn btn-sm btn-outline-light py-0 px-1 ml-1" onClick={() => dispatch(orderUpFaq(data.id)) }>
          <FontAwesomeIcon fixedWidth icon={faAngleDown}/>
        </button>
        <button className="btn btn-sm btn-outline-light py-0 px-1 ml-1" onClick={() => dispatch(orderDowFaq(data.id)) }>
          <FontAwesomeIcon fixedWidth icon={faAngleUp}/>
        </button>
        <button className="btn btn-sm btn-outline-info py-0 px-1 ml-1" onClick={() => onDeActivate(data.id)}>
          <FontAwesomeIcon fixedWidth icon={ data.is_active === 1 ? faEyeSlash: faEye }/>
        </button>
        <button className="btn btn-sm btn-outline-primary py-0 px-1 ml-1" onClick={() => onEdit(data.id)}>
          <FontAwesomeIcon fixedWidth icon={faEdit}/>
        </button>
        <button className="btn btn-sm btn-outline-danger py-0 px-1 mx-1" onClick={() => onDelete(data.id)}>
          <FontAwesomeIcon fixedWidth icon={faTimes}/>
        </button>
      </td>
    </tr>
  );
}

export default FaqTableItem;
