import React from 'react';
import Footer from "./Footer";
import Header from "./Header/Header";
import history from '../history/history';
import {Router, Switch, Route, Redirect} from 'react-router-dom';
import {useSelector} from "react-redux";

import Modal from "./Modal";
import Loading from "./Loading";
import E404 from "../pages/e404";
import Home from "../pages/Home";
import Users from "../pages/Users";
import Workouts from "../pages/Workouts";
import SignIn from "../pages/signIn";
import WebHooks from "../pages/WebHooks";
import Faqs from "../pages/Faqs";
import Programs from "../pages/Programs";
import Questions from "../pages/Questions";
import Conversation from "../pages/Conversation";
import Nutrition from "../pages/Nutrition";
import NutritionItems from "../pages/NutritionItems";
import PC from "../pages/PC";
import TwelveWeekProgram from "../pages/TwelveWeekProgram";
import VimeoVideos from "../pages/VimeoVideos";

const PrivateRoute = ({children, comp: Component, ...rest}) => {
  const authToken = useSelector(state => state.auth.token);
  return (
    <Route {...rest} render={() =>
      authToken
        ? <Component/>
        : <Redirect to="/signIn"/>
    }
    />
  );
}

const NotAllowedIfAuthRoute = ({children, comp: Component, ...rest}) => {
  const authToken = useSelector(state => state.auth.token);
  return (
    <Route {...rest} render={() =>
      authToken
        ? <Redirect to="/admin/workouts"/>
        : <Component/>
    }
    />
  );
}

const App = () => {
  return <Router history={history}>
    <div className="App">
      <Switch>
        <Route exact path={"/"} component={Home}/>
        <Route exact path={"/404"} component={E404}/>
        <Route path={"/admin"} render={() => (<>
            <Header/>
            <div className="App-container">
              <div className="App-container__inner" style={{minHeight: window.innerHeight - 95}}>
                <Switch>
                  <PrivateRoute path={"/admin"} comp={UserPrivateRoutes}/>
                </Switch>
              </div>
            </div>
            <Footer/>
          </>
        )}/>
        <NotAllowedIfAuthRoute path={"/signIn"} comp={SignIn}/>
        <Redirect to={"/404"}/>
      </Switch>
      <Modal/>
      <Loading/>
    </div>
  </Router>
};

const UserPrivateRoutes = () => {
  return <Switch>
    <Route path={"/admin/webHooks"} component={WebHooks}/>
    <Route path={"/admin/workouts"} component={Workouts}/>
    <Route path={"/admin/programs"} component={Programs}/>
    <Route path={"/admin/users"} component={Users}/>
    <Route path={"/admin/faqs"} component={Faqs}/>
    <Route path={"/admin/questions/:is_workout/:user_id/:exercise_id"} component={Conversation}/>
    <Route path={"/admin/questions"} component={Questions}/>
    <Route path={"/admin/nutrition/category/:category_id"} component={NutritionItems}/>
    <Route path={"/admin/nutrition"} component={Nutrition}/>
    <Route path={"/admin/pc"} component={PC}/>
    <Route path={"/admin/twelveWeekProgram"} component={TwelveWeekProgram}/>
    <Route path={"/admin/vimeoVideos"} component={VimeoVideos}/>
    <Redirect to={"/admin/workouts"}/>
  </Switch>
}

export default App;
