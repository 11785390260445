import React from 'react';
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faEdit, faEye, faEyeSlash,
  faMinus,
  faPlus,
  faSave,
  faTimes,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import PCWorkoutDay from "./PCWorkoutDay";
import {
  useDeleteWorkoutProgramWeekMutation,
  useEditWorkoutProgramWeekMutation,
  useCreateWorkoutProgramDayMutation,
  useDeActivateWorkoutProgramWeekMutation,
} from "../../../actions/pcWorkoutApi";


export default function PCWorkoutWeek({program_id, week, reloadData}) {


  const [editWorkoutWeek, ewwResult] = useEditWorkoutProgramWeekMutation();
  const [isEdit, setIsEdit] = React.useState(false);
  const [editQueue, setEditQueue] = React.useState(week.queue);
  const [editTitle, setEditTitle] = React.useState(week.title);
  const [editDescription, setEditDescription] = React.useState(week.description);
  const [deleteWorkoutWeek, dwwResult] = useDeleteWorkoutProgramWeekMutation();
  const [deActivateWorkoutWeek, dawwResult] = useDeActivateWorkoutProgramWeekMutation();

  const [createWorkoutDay, cwdResult] = useCreateWorkoutProgramDayMutation();

  const [isNewDay, setIsNewDay] = React.useState(false);
  const [newDayQueue, setNewDayQueue] = React.useState(0);
  const [newDayTitle, setNewDayTitle] = React.useState('');
  const [newDayDescription, setNewDayDescription] = React.useState('');

  // Edit workout week effect
  React.useEffect(() => {
    if (!ewwResult.isLoading){
      ewwResult.isSuccess && reloadData();
      ewwResult.isError && console.log(ewwResult.error.data);
    }
  }, [ewwResult.isLoading]);

  // Deactivate workout week effect
  React.useEffect(() => {
    if (!dawwResult.isLoading){
      dawwResult.isSuccess && reloadData();
      dawwResult.isError && console.log(dawwResult.error.data);
    }
  }, [dawwResult.isLoading]);

  // handle edit
  const handleEdit = () => {
    if(editTitle || editDescription){
      editWorkoutWeek({week_id: week.id, title: editTitle, queue: editQueue, description: editDescription})
      setIsEdit(false);
    }
  }

  // handle cancel edit
  const handleCancelEdit = () => {
    setIsEdit(false);
    setEditTitle(week.title);
    setEditDescription(week.description);
  }

  // handle delete
  React.useEffect(() => {
    if (!dwwResult.isLoading){
      dwwResult.isSuccess && reloadData();
      dwwResult.isError && console.log(dwwResult.error.data);
    }
  }, [dwwResult.isLoading]);



  // Create new workout program day effect
  React.useEffect(() => {
    if (!cwdResult.isLoading){
      if(cwdResult.isSuccess) {
        setIsNewDay(false);
        setNewDayTitle('');
        setNewDayDescription('');
        reloadData();
      }

      cwdResult.isError && console.log(cwdResult.error.data);
    }
  }, [cwdResult.isLoading]);

  const handleCreateNewDay = () => {
    if (newDayTitle && newDayDescription){
      createWorkoutDay({week_id: week.id, title: newDayTitle, queue: newDayQueue, description: newDayDescription});
      setIsNewDay(false);
    }
  }


  return (
    <div className="week-container">
      <div className="week-header d-flex flex-row border-bottom border-secondary justify-content-between align-items-center">
        {
          (isEdit)
            ? <>
              <div className="flex-grow-1 mr-1">
                <input type="text" value={editQueue} onChange={(e) => setEditQueue(e.target.value)} className="form-control form-control-sm d-flex mb-1" placeholder={'Week queue'}/>
                <input type="text" value={editTitle} onChange={(e) => setEditTitle(e.target.value)} className="form-control form-control-sm d-flex mb-1" placeholder={'Week title'}/>
                <input type="text" value={editDescription} onChange={(e) => setEditDescription(e.target.value)} className="form-control form-control-sm d-flex" placeholder={'Week description'}/>
              </div>
              <div>
                <Button onClick={handleEdit} className="btn p-0 px-1 mr-1 btn-primary btn-sm"><FontAwesomeIcon icon={faSave}/></Button>
                <Button onClick={handleCancelEdit} className="btn p-0 px-1 btn-danger btn-sm"><FontAwesomeIcon icon={faTimes}/></Button>
              </div>
          </>
            : <>
              <div>
                <p className={"m-0"}><span className="badge badge-danger">{week.queue}</span> {week.title ? week.title : ""}</p>
                <span className={"small text-muted"}>{(week.description ? week.description : "").toString().substring(0, 40)+ " ..."}</span>
              </div>
              <div>
                <Button onClick={() => setIsNewDay(!isNewDay)} className="btn p-0 px-1 btn-warning btn-sm"><FontAwesomeIcon icon={ isNewDay ? faMinus : faPlus} /></Button>
                <Button onClick={() => deActivateWorkoutWeek(week.id)} className={`btn p-0 px-1 ${week.is_active ? "btn-success" : "btn-secondary"} btn-sm`}><FontAwesomeIcon icon={week.is_active ? faEye : faEyeSlash} /></Button>
                <Button onClick={() => setIsEdit(true)} className="btn p-0 px-1 btn-primary btn-sm"><FontAwesomeIcon icon={faEdit} /></Button>
                <Button onClick={() => window.confirm('You are deleting week?') && deleteWorkoutWeek(week.id) } className="btn p-0 px-1 btn-danger btn-sm"><FontAwesomeIcon icon={faTrash} /></Button>
              </div>
            </>
        }
      </div>
      {
        (isNewDay)
          ? <div className="d-flex flex-row justify-content-between align-items-center mt-2">
            <div className="d-flex mr-1">
              <input type="text" value={newDayQueue} onChange={(e) => setNewDayQueue(e.target.value)} className="form-control form-control-sm d-flex mb-1 mr-1" placeholder={'New day queue'}/>
              <input type="text" value={newDayTitle} onChange={(e) => setNewDayTitle(e.target.value)} className="form-control form-control-sm d-flex mb-1 mr-1" placeholder={'New day title'}/>
              <input type="text" value={newDayDescription} onChange={(e) => setNewDayDescription(e.target.value)} className="form-control form-control-sm d-flex" placeholder={'New day description'}/>
            </div>
            <div className="d-flex">
              <Button onClick={handleCreateNewDay} className="btn p-0 px-1 mr-1 btn-primary btn-sm"><FontAwesomeIcon icon={faSave}/></Button>
              <Button onClick={() => setIsNewDay(false)} className="btn p-0 px-1 btn-danger btn-sm"><FontAwesomeIcon icon={faTimes}/></Button>
            </div>
          </div>
          : null
      }
      {

        (week.workout_program_days.length > 0) && <div className="days bg-secondary p-1 pl-3">
          {
            week.workout_program_days.map((day) => <PCWorkoutDay key={day.id} day={day} week_id={week.id} reloadData={reloadData} /> )
          }
        </div>
      }
    </div>
  );
}