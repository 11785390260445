import {processLoadingEnd, processLoadingStart, processModalShow} from "./processActions";

import urls from "../connect/urls";
import {signOutAction} from "./authActions";

export const WEB_HOOKS_GET_ALL_WEB_HOOKS = "WEB_HOOKS_GET_ALL_WEB_HOOKS";
export const WEB_HOOKS_NEXT_PAGE = "WEB_HOOKS_NEXT_PAGE";
export const WEB_HOOKS_PREVIOUS_PAGE = "WEB_HOOKS_PREVIOUS_PAGE";


// Get all users data
export const getWebHooksData = () => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    const response = await ibospirit.post(`${urls.getPaypalWebhookEvents}?page=${getState().webHooks.current_page}`, {},
      {headers: { 'Authorization': 'Bearer ' + getState().auth.token } })

    dispatch({type: WEB_HOOKS_GET_ALL_WEB_HOOKS, payload: response.data});

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}

// Go to next page
export const getWebHooksNextPage = () => ({type: WEB_HOOKS_NEXT_PAGE});

// Go to prev page
export const getWebHooksPreviousPage = () => ({type: WEB_HOOKS_PREVIOUS_PAGE});
