import {
  FAQS_CLOSE_EDIT_FAQ_MODAL,
  FAQS_CLOSE_NEW_FAQ_MODAL,
  FAQS_GET_ALL_FAQS,
  FAQS_OPEN_EDIT_FAQ_MODAL,
  FAQS_OPEN_NEW_FAQ_MODAL,
} from "../actions/faqsActions";


const defaultState = {
  data: [],
  is_new_faq_modal_open: false,
  is_edit_faq_modal_open: {state: false, id: null}
}

const faqsReducer = (state = defaultState, action) => {
  switch (action.type) {

    case FAQS_GET_ALL_FAQS :
      return {...state, data: action.payload };

    case FAQS_OPEN_NEW_FAQ_MODAL :
      return {...state, is_new_faq_modal_open: true};

    case FAQS_CLOSE_NEW_FAQ_MODAL :
      return {...state, is_new_faq_modal_open: false};

    case FAQS_OPEN_EDIT_FAQ_MODAL :
      return {...state, is_edit_faq_modal_open: {state: true, id: action.payload}};

    case FAQS_CLOSE_EDIT_FAQ_MODAL :
      return {...state, is_edit_faq_modal_open: {state: false, id: null}};

    default :
      return state;
  }
}

export default faqsReducer;
