import React from 'react';
import styled from "styled-components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {websiteName} from "../constants";
import {getWorkoutsData, openNewWorkoutModal} from "../actions/workoutsActions";
import NewWorkoutModal from "../components/Wokrouts/NewWorkoutModal";
import WorkoutContainer from "../components/Wokrouts/WorkoutContainer";
import EditWorkoutModal from "../components/Wokrouts/EditWorkoutModal";
import NewWorkoutExerciseModal from "../components/Wokrouts/NewWorkoutExerciseModal";
import EditWorkoutExerciseModal from "../components/Wokrouts/EditWorkoutExerciseModal";


const Workouts = () => {
  const workouts = useSelector(state => state.workouts);
  const dispatch = useDispatch();

  React.useEffect(() => {
    document.title = 'Workouts' + websiteName;
    dispatch(getWorkoutsData());
  }, [])

  return <StyledWorkouts>
    {/* New workout modal start */}
    {(workouts.is_new_workout_modal_open) ? <NewWorkoutModal/> : null}
    {/* New workout modal end   */}

    {/* Edit workout modal start */}
    {(workouts.is_edit_workout_modal_open.state) ? <EditWorkoutModal/> : null}
    {/* Edit workout modal end   */}


    {/* New workout exercise modal start */}
    {(workouts.is_new_workout_exercise_modal_open.state) ? <NewWorkoutExerciseModal/> : null}
    {/* New workout exercise modal end   */}

    {/* Edit workout exercise modal start */}
    {(workouts.is_edit_workout_exercise_modal_open.state) ? <EditWorkoutExerciseModal/> : null}
    {/* Edit workout exercise modal end   */}

    {/* New workout add button start */}
    <h4>Workouts{' '}
      <button className="btn btn-primary btn-sm" onClick={() => dispatch(openNewWorkoutModal())}>
        <FontAwesomeIcon icon={faPlus}/>
      </button>
    </h4>
    {/* New workout add button end */}

    <div className="container-fluid p-0">
      <div className="row">
        {
          (workouts.data.length)
            ? workouts.data.map(workout => <WorkoutContainer key={workout.id} data={workout}/>)
            : <div className="col-12"><h2 className="text-center">Workouts not found yet</h2></div>
        }
      </div>
    </div>

  </StyledWorkouts>;
};


const StyledWorkouts = styled.div`
  min-height: ${window.innerHeight - 155}px;
  
  .workout{
    border-radius: 5px;
    border: 1px solid #777;
    padding: 10px;
    margin-bottom: 30px;
    background: #404040;
    box-shadow: 0 0 10px #444;
    &.not-seen{
      background: #111;
    }
    
    &-title{}
    &-actions{
      margin: 10px 0 20px;
      padding-bottom: 10px;
      border-bottom: 1px dotted #777;
      text-align: right;
      button{
        padding: 0 3px;
        margin: 0 3px;
      }
    }
    
    &-exercises{
      .exercise{
        display: flex;
        justify-content: space-between;
        margin-bottom: 7px;
        padding-bottom: 7px;
        border-bottom: 1px dashed #888;
        font-size: 14px;
        
        &:last-child{ border: none; }
        
        &.not-seen{
          background: #1B1C1E;
        }
        
        &-actions{
          button{
            padding: 0 3px;
            margin: 0 3px;
          }
        }
      }
    }
  }  
`;

export default Workouts;
