import {
  CLIENTS_CLEAR_EDITING_STAT_ROW,
  CLIENTS_CLEAR_EDITING_TIMETABLE_ROW,
  CLIENTS_CLEAR_NEW_STAT_ROW,
  CLIENTS_CLEAR_NEW_TIMETABLE_ROW, CLIENTS_CURRENT_TIMETABLE_MODAL_CLOSE, CLIENTS_CURRENT_TIMETABLE_MODAL_OPEN,
  CLIENTS_DELETE_CLIENT_MODAL_CLOSE,
  CLIENTS_DELETE_CLIENT_MODAL_OPEN, CLIENTS_DELETE_IMAGE_MODAL_CLOSE,
  CLIENTS_DELETE_IMAGE_MODAL_OPEN,
  CLIENTS_DELETE_STAT_MODAL_CLOSE,
  CLIENTS_DELETE_STAT_MODAL_OPEN,
  CLIENTS_DELETE_TIMETABLE_MODAL_CLOSE,
  CLIENTS_DELETE_TIMETABLE_MODAL_OPEN,
  CLIENTS_GET_CLIENT, CLIENTS_GET_CLIENT_IMAGES,
  CLIENTS_GET_CLIENTS,
  CLIENTS_NEW_CLIENT_MODAL_CLOSE,
  CLIENTS_NEW_CLIENT_MODAL_OPEN,
  CLIENTS_PREPARE_EDITING_STAT_ROW,
  CLIENTS_PREPARE_EDITING_TIMETABLE_ROW,
  CLIENTS_RESET_CURRENT_CLIENT
} from "./types";
import {processLoadingEnd, processLoadingStart, processModalShow} from "./processActions";
import urls from "../connect/urls";
import {signOutAction} from "./authActions";
import {API_URL} from "../constants";


export const openNewClientModal = () => ({type: CLIENTS_NEW_CLIENT_MODAL_OPEN})
export const closeNewClientModal = () => ({type: CLIENTS_NEW_CLIENT_MODAL_CLOSE})


export const getClients = () => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    const response = await ibospirit.post(urls.getClients, {}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    if(response.data)
      dispatch({type: CLIENTS_GET_CLIENTS, payload: response.data});

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
            // alert(errorText);
          }
          break;

        // If token expired
        case 401:
          // alert(e.response.data);
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          // alert('Too many request try again later');
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
      // alert('Network error. Check internet connection');
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}

export const addNewClient = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.addClient, data,
      {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    dispatch(getClients());
    dispatch(resetCurrentClient());
    dispatch(closeNewClientModal());

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
            // alert(errorText);
          }
          break;

        // If token expired
        case 401:
          // alert(e.response.data);
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          // alert('Too many request try again later');
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
      // alert('Network error. Check internet connection');
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}

export const getClient = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    const response = await ibospirit.post(urls.getClient, {id: id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    if(response.data){
      dispatch({type: CLIENTS_GET_CLIENT, payload: response.data});
    }

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}

export const editClientMainData = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.editClientMainData, data, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    await dispatch(getClients());
    await dispatch(getClient(data.id));

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}

export const deActivateClientMainData = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deActivateClient, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    await dispatch(getClients());
    await dispatch(getClient(id));

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}
export const resetCurrentClient = () => ({type: CLIENTS_RESET_CURRENT_CLIENT});

export const openDeleteClientModal = () => ({type: CLIENTS_DELETE_CLIENT_MODAL_OPEN});
export const closeDeleteClientModal = () => ({type: CLIENTS_DELETE_CLIENT_MODAL_CLOSE});
export const deleteClientMainData = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deleteClient, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    await dispatch(getClients());
    await dispatch(resetCurrentClient());

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}

export const clearNewTimetableRow = () => ({type: CLIENTS_CLEAR_NEW_TIMETABLE_ROW});
export const clearEditingTimetable = () => ({type: CLIENTS_CLEAR_EDITING_TIMETABLE_ROW});
export const prepareToEditTimetable = (id) => ({type: CLIENTS_PREPARE_EDITING_TIMETABLE_ROW, payload: id});

export const addTimetableToClient = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());
    await ibospirit.post(urls.addTimetableToClient, data, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    await dispatch(getClient(data.id));
    dispatch(clearNewTimetableRow());

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}
export const editTimetableToClient = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());
    await ibospirit.post(urls.editTimetableToClient, data, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    await dispatch(getClient(getState().clients.current_client.id));
    await dispatch(clearEditingTimetable());


  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}

export const openDeleteTimetableModal = (id) => ({type: CLIENTS_DELETE_TIMETABLE_MODAL_OPEN, payload: id})
export const closeDeleteTimetableModal = () => ({type: CLIENTS_DELETE_TIMETABLE_MODAL_CLOSE})
export const deleteTimetableClient = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deleteTimetableClient, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    await dispatch(getClient(getState().clients.current_client.id));
    await dispatch(closeDeleteTimetableModal());

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}


export const openTodayClientTimetableModal = () => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());
    const response = await ibospirit.post(urls.getTodayTimetableClient, {}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    dispatch({type: CLIENTS_CURRENT_TIMETABLE_MODAL_OPEN, payload: response.data});
  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}
export const closeTodayClientTimetableModal = () => ({type: CLIENTS_CURRENT_TIMETABLE_MODAL_CLOSE})



export const clearNewStatRow = () => ({type: CLIENTS_CLEAR_NEW_STAT_ROW});
export const clearEditingStat = () => ({type: CLIENTS_CLEAR_EDITING_STAT_ROW});
export const prepareToEditStat = (id) => ({type: CLIENTS_PREPARE_EDITING_STAT_ROW, payload: id});

export const addStatToClient = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());
    await ibospirit.post(urls.addStatToClient, data, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    await dispatch(getClient(data.id));
    dispatch(clearNewStatRow());

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}
export const editStatToClient = (data) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());
    await ibospirit.post(urls.editStatToClient, data, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    await dispatch(getClient(getState().clients.current_client.id));
    await dispatch(clearEditingStat());

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}

export const openDeleteStatModal = (id) => ({type: CLIENTS_DELETE_STAT_MODAL_OPEN, payload: id})
export const closeDeleteStatModal = () => ({type: CLIENTS_DELETE_STAT_MODAL_CLOSE})
export const deleteStatClient = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deleteStatClient, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    await dispatch(getClient(getState().clients.current_client.id));
    await dispatch(closeDeleteStatModal());

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}


export const getClientImages = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());
    const response = await ibospirit.post(urls.getImagesClient, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    if (response.data){
      console.log(response.data);
      dispatch({type: CLIENTS_GET_CLIENT_IMAGES, payload: response.data});
    }

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}
export const addImageToClient = (data) => async (dispatch, getState, ibospirit) => {
  try{
    let newData = new FormData();

    const id = getState().clients.current_client.id;

    newData.append('id', id);
    newData.append('notes', data.notes);
    newData.append('date', data.date);
    if (data.image)
      newData.append('image', data.image, data.image.fileName);

    dispatch(processLoadingStart());
    await ibospirit.post(urls.addImageClient, newData, { headers: {'Content-Type': 'multipart/form-data', 'Authorization': 'Bearer ' + getState().auth.token }});
    await dispatch(getClientImages(id));

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}
export const editImageToClient = (data) => async (dispatch, getState, ibospirit) => {
  try{
    const id = getState().clients.current_client.id;

    dispatch(processLoadingStart());
    await ibospirit.post(urls.editImageClient, data, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });
    await dispatch(getClientImages(id));

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}
export const updateImageClient = (id) => async (dispatch, getState, ibospirit) => {
  try{
    const client_id = getState().clients.current_client.id;

    dispatch(processLoadingStart());
    await ibospirit.post(urls.updateImageClient, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });
    await dispatch(getClientImages(client_id));

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}
export const deleteClientImageClient = (id) => async (dispatch, getState, ibospirit) => {
  try{
    dispatch(processLoadingStart());

    await ibospirit.post(urls.deleteImageClient, {id}, {headers: { 'Authorization': 'Bearer ' + getState().auth.token } });

    await dispatch(closeDeleteClientImageModal());
    await dispatch(getClientImages(getState().clients.current_client.id));

  }catch (e) {
    try{
      const status = parseInt(e.response.status);
      switch (status) {
        // If input data has error
        case 400:
          if (typeof e.response.data == "object"){
            const data = e.response.data;
            let errorText = "";
            Object.keys(data).forEach(key => errorText += data[key]+"\n" );
            dispatch(processModalShow(errorText))
          }
          break;

        // If token expired
        case 401:
          dispatch(processModalShow(e.response.data));
          dispatch(signOutAction());
          break;

        // If made many requests
        case 419:
          dispatch(processModalShow('Too many request try again later'));
          break;

        // If none matches
        default:
          dispatch(processModalShow());
      }

    } catch (e) {
      dispatch(processModalShow('Network error. Check internet connection'));
    }
  }finally {
    dispatch(processLoadingEnd());
  }
}
export const openDeleteClientImageModal = (id) => ({type: CLIENTS_DELETE_IMAGE_MODAL_OPEN, payload: id})
export const closeDeleteClientImageModal = () => ({type: CLIENTS_DELETE_IMAGE_MODAL_CLOSE})

