import React, {useState} from "react";
import ReactDOM from "react-dom";
import {useDispatch, useSelector} from "react-redux";
import {closeEditNutritionCategoryAction, editNutritionCategoryAction} from "../../actions/nutritionActions";
import MediumModal from "../MediumModal";

const EditNutritionCategoryModal = () => {
  const dispatch = useDispatch();
  const edit_category = useSelector(state => state.nutrition.is_edit_category_modal_open);
  const [title, setTitle] = useState('');

  React.useEffect(() => {
    if (edit_category.data == null)
      dispatch(closeEditNutritionCategoryAction());

    setTitle(edit_category.data.title);
  }, []);

  return (
    ReactDOM.createPortal(
      <MediumModal onSuccess={() => dispatch(editNutritionCategoryAction({id: edit_category.data.id, title}))}
                   onClose={() => dispatch(closeEditNutritionCategoryAction())}
                   title={'Edit category'} onSuccessText={'Edit'}>

        {/* Title */}
        <div className="col-12">
          <div className="form-group">
            <label htmlFor="edit_category_title">Title <span className="text-danger">*</span> :</label>
            <input type="text" className="form-control" placeholder={'Category title'} id="edit_category_title"
                   value={title} onChange={e => setTitle(e.target.value)}/>
          </div>
        </div>
      </MediumModal>,
      document.getElementById('modal')
    )
  );
}

export default EditNutritionCategoryModal;
